$all-colors: (
  'accent': #00D1AF,
  'white': #FFFFFF,
  'carbon': #414042,
  'black': #000000,
  'lightest-gray': #FAFAFA,
  'very-light-gray': #F3F3F4,
  'light-gray': #E6E7E8,
  'gray': #DADADA,
  'medium-gray': #C1C1C1,
  'dark-gray': #A8A8A8,
  'darkest-gray': #545558,
  'teal': #00D1AF,
  'transparent': transparent
);

@function color($color: 'dark-teal') {
  @return map-get($all-colors, $color);
}

@function is-color($color: '') {
  @return map-has-key($all-colors, $color);
}

@each $name, $color in $all-colors {
  
  .#{$name}-text {
    color: $color;
  }
  
  .#{$name}-background {
    background-color: $color;
  }

  .#{$name}-border {
    border-color: $color!important;
  }
}