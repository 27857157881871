.rates-table.fees-table{
  .rate-group{
    .rate-item{
      background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168, 168, 168, 0) 0%);
      background-position: bottom;
      background-size: 6px 2px;
      background-repeat: repeat-x;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;

      @include breakpoint(xlarge){
        background-image: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .rate-item-info{
        .rate-title{
          width: 100%;
          display: block;
          margin-right: 0;
          margin-right: 1rem;
        }

        .rate-disclaimer{
          width: 100%;
          display: block;
          clear: both;
          font-size: 0.9rem;
          padding-top: 0.1rem;
        }
      }

      .rate-item-cta{
        background-image: none;
        margin-bottom: 0;
        padding-bottom: 0;

        .rate-value{
          font-size: 1.9rem;
          line-height: 1.4;
          margin-top: 0;
          margin-right: 0;
          margin-top: 1rem;

          @include breakpoint(medium){
            margin-top: 0;
          }

          @include breakpoint(xlarge){
            font-size: 1.33rem;
          }
        }
      }
    }
  }
}