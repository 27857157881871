// Page specific styling for interior article page
.story-container {
    position: relative;
    .story {
        margin-bottom: 0;
    }
	h1, h2, h3, h4, h5, h6 {
    	margin-bottom: 0.5rem;
	}
}
.related-stories {
    margin-top: 1rem;
    @include breakpoint(medium) {
        margin-top: 4rem;    
    }
}
.story-main{
    min-height: rem-calc(500);
    background-color: color('white');
    padding: 2rem 1.8rem 0;
    margin-top: 0;
    @include breakpoint(medium down) {
        min-height: 0;      
    }
    @include breakpoint(medium) {
        padding: 2rem 4rem 0;
    }
    @include breakpoint(large) {
        padding: 3rem 5rem 0;
    }
    ul.categories{
        li{
            float: left;
            &.secondary-category{
                a{
                    @-moz-document url-prefix() {
                        margin-left: 0.5rem;
                    }
                    @supports (-webkit-overflow-scrolling: touch) {
                    /* CSS specific to iOS devices */ 
                        margin-left: 0;
                    }
                }
            }
            a{
                @-moz-document url-prefix() {   
                    margin-left: 0;
                }
                @supports (-webkit-overflow-scrolling: touch) {
                /* CSS specific to iOS devices */ 
                    margin-left: -0.65rem;
                }
            }
            @supports (-webkit-overflow-scrolling: touch) {
            /* CSS specific to iOS devices */ 
                float: none;
            }
        }
    }
    .author{
        font-weight: 600;
        font-size: 0.8rem;
        margin-right: 1rem;
    }
    .author-container{
        float: right;
        width: auto;
        @include breakpoint(400px down) {
            float: left;
            width: 100%;
        }
        .author{
            margin-right:0;
        }
        a{
            color: color('black');
            font-size: 0.8rem;
            font-weight: 600;
        }
    }
    .story-content{
        margin: 1rem auto 2rem;
        p{
            margin-bottom: 2rem;
            color: #545558;
        }
        .story-quote{
            font-style: italic;
            margin: 2rem auto;
        }
    }
    .back-to-categories{
        line-height: 1.2;
        @include breakpoint(400px down) {
            width: 100%;
        }
    }
}

.video-block {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
