.tabbed-chart{
  .rate-full-width{
    .rate{
      margin-bottom: 5rem;

      &:last-child{
        margin-bottom: 0;
      }

      &:first-child{
        margin-top: 2.5rem;

        @include breakpoint(xlarge) {
          margin-top: 3rem;
        }
      }
      
      .cell{
        padding: 0;
  
        &::after{
          border-bottom: none;
        }
  
        &:last-child{
          margin-bottom: 0;
        }
  
        h3{
          border-bottom: none;
          margin-bottom: 1rem;
        }
      }

      .rate-data-left.cell{
        //border-right: 2px dotted #A8A8A8;
        background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168,168,168,0) 0%);
        background-position: bottom;
        background-size: 6px 2px;
        background-repeat: repeat-x;
        padding-bottom: 1rem;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 1.25rem;

        @include breakpoint(xlarge) {
          background-image: linear-gradient(#A8A8A8 33%, rgba(168,168,168,0) 0%);
          background-position: right;
          background-size: 2px 6px;
          background-repeat: repeat-y;
          padding-left: 4rem;
          padding-right: 4rem;
          padding-bottom: 0;
          margin-bottom: 0;
        }

        .primary-title{
          font-size: 1.9375rem;

          @include breakpoint(xlarge) {
            font-size: 1.9375rem;
          }
        }
      }
  
      .rate-data-right.cell{
        padding-left: 0;
        padding-right: 0;

        @include breakpoint(xlarge) {
          padding-left: 4rem;
          padding-right: 4rem;
        }
  
        h2.rate-value{
          line-height: 0.8;
          margin-bottom: 0.5rem;
          font-size: 5rem;

          @include breakpoint(xlarge) {
            font-size: 4.0625rem;
          }
        }
  
        .button{
          max-width: 15rem;
          display: block;
          clear: both;
        }

        .first-cta{
          margin-top: 1.33rem;
        }
      }

    }
  }
}