.legal {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
	.accordion {
		padding: 0;
		margin: 0rem;
		.accordion-item {
			margin: 0;
			.accordion-title {
				padding: 1.25rem 1rem 1.25rem 2.25rem;
				@include breakpoint(medium) {
					padding: 1.25rem 2rem;
				}
				&:before {
					right: auto;
					left: 0.5rem;
				}
			}
		}
	}

    p {
        font-size: 0.75rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.legal-text{
	margin-top: 1.5rem;
	
	p{
		font-size: 0.75rem;
		padding-bottom: 0;
	}
}