//Sofia Fonts
@font-face {
	font-family: 'Sofia';
	src: url('../fonts/SofiaProRegular.otf');
	src: local('☺'), url('../fonts/SofiaProRegular.woff') format('woff'), url('../fonts/SofiaProRegular.ttf') format('truetype'), url('../fonts/SofiaProRegular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Sofia';
	src: url('../fonts/SofiaPro-Bold.otf');
	src: local('☺'), url('../fonts/SofiaPro-Bold.woff') format('woff'), url('../fonts/SofiaPro-Bold.ttf') format('truetype'), url('../fonts/SofiaPro-Bold.woff2') format('woff2');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Sofia';
	src: url('../fonts/SofiaPro-Italic.otf');
	src: local('☺'), url('../fonts/SofiaPro-Italic.woff') format('woff'), url('../fonts/SofiaPro-Italic.ttf') format('truetype'), url('../fonts/SofiaPro-Italic.woff2') format('woff2');
	font-weight: normal;
	font-style: italic;
}
//End Sofia Fonts