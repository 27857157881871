.tabbed-chart {
	padding: 0;
	padding-top: 0.25rem;
	.intro-content {
		h3 {
			margin-bottom: 0.5rem;
		}
	}
	.grid-container {
		@include breakpoint(large down) {
			margin:0;
			padding: 0;
    	}
	}
	nav {
		margin: 0 auto;
		padding: 0;
		flex-wrap:wrap;
		@include breakpoint(large) {
			flex-wrap: nowrap;
    	}
		.tab-title {
			width: 100%;
			max-width: none;
			margin: 0.5% 0;
			@include breakpoint(large) {
				max-width: 19.5%;
				min-width: 19.5%;
				width: auto;
				margin: 0 0.1%;
			}
		}
	}
	.rate {
		.cell {
			position: relative;
			padding: 1rem 0 1.25rem;
			@include breakpoint(large) {
				padding: 1.5rem 0 1.75rem;
			}
			&:after {
				content: '';
				border-bottom: 1px dashed color('dark-gray');
				width: 100%;
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
			}
			&:last-child {
				padding-bottom: 0;
				&:after {
					border: none;
				}
			}
			p, h2, h3, span {
				margin: 0;
				padding: 0;
			}
			h2 {
				font-size: rem-calc(65);
				line-height: 1;
				@include breakpoint(medium) {
					font-size: rem-calc(40);
				}
				@include breakpoint(xlarge) {
					font-size: rem-calc(55);
				}
				@include breakpoint(xxlarge) {
					font-size: rem-calc(65);
				}
			}
			h3 {
				border-bottom: 6px solid #00D1AF;
				margin-bottom: 1rem;
				@include breakpoint(large) {
					margin-bottom: 2rem;
				}
			}
			a {
				&.button {
					width: 100%;
					margin: 0.5rem 0;
				}
			}
		}
	}
	
	.rate-carousel {
    overflow: visible;

    &.mint-arrow {
        .slick-next, .slick-prev {
            &::before {
                border-color: $primary-color;
            }
        }
    }
	
	.rate {
		padding: 0 1rem;
		margin: 0;
		@include breakpoint(large) {
            padding: 0 1.5rem;
        }
	}
		
	.slick-track {
		@include breakpoint(xlarge) {
			width: 100% !important;
		}
	}
	
    .slick-slide { 
        outline: 0;
		/*&:first-child {
			.rate {
				padding-left: 0;
			}	
		}
		&:last-child {
			.rate {
				padding-right: 0;
			}
		}*/
    }

    .slick-next, .slick-prev {
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 15%;
        cursor: pointer;
        text-indent: -99999999px;
        color: transparent;
        outline: 0;

        @include breakpoint(medium down) {
            //display: none !important;
        }

        &::before {
            position: absolute;
            width: 65%;
            height: 65%;
            top: 50%;
            left: 50%;
            border-width: 0;
            border-style: solid;
            border-color: color('white');
            content: "";
            transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(.8);
            transition: transform $testimonials-transition;
        }

        &:hover::before {
            transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1);
        }
    }

    .slick-next {
        left: 100%;
        //margin-left: 0.5rem;

        &::before {
            border-right-width: 3px;
            border-top-width: 3px;
        }
    }

    .slick-prev {
        right: 100%;
        //margin-right: 0.5rem;

        &::before {
            border-left-width: 3px;
            border-bottom-width: 3px;
        }
    }

    .slick-dots {
        width: 100%;
        margin: 2rem 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;

        a, button {
            position: relative;
            width: 2rem;
            height: rem-calc(3);
            margin: 0 .25rem;
            text-indent: -99999999px;
            cursor: pointer;
            outline: 0;
            transition: background-color $testimonials-transition;

            &:not(.accent-background) {
                background-color: color('light-gray');
            }

            &::after {
                position: absolute;
                width: 100%;
                height: 500%;
                top: -200%;
                display: block;
                content: "";
            }
        }
    }
}
}

