.concerns {
	align-items: center;
	border-top: 1px dashed;
	padding-top: 2rem;
	margin-top: 1rem;
	p {
		margin: 0;
	}
	.question {
		position: relative;
		@include breakpoint(medium down) {
			margin: 2rem auto 0;
		}
		&:after {
			content: '>';
			font: 24px "Consolas", monospace;
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
			right: 10px;
			top: 0;
			position: absolute;
			pointer-events: none;
		}
	}
}

[data-module="sections"] [data-module-section] {
	display: none;
}

[data-module="sections"] [data-module-section][data-module-default] {
	display: inline-flex;
	width: 100%;
}
.concerns.separateur
{
	padding-top: 0rem;
	margin-top: -3rem;
	
}
.separateur
{
	padding-top: 0rem;
    margin-top: -1rem;
}
.bg-check{
	background-color: #f3f3f4;
}
