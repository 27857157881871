body {
  color: color('carbon');
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
	margin-bottom: 2rem;
}
.mortgage-padding{
  padding: 0 !important;
}