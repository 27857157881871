.with-label {
	position: relative;
	&.promo, &.gic-application, &.side-by-side, &.content-switcher, &.abm-finder {
		padding-top: 4rem;
		.section-label {
			left: 1.8rem;
			z-index: 999;
			@include breakpoint(xlarge) {
				left: 4rem;
			}
		}
	}
	&.accessibility{
		padding-bottom: 0rem;
	}
	&.side-by-side {
		padding-top: 1rem;
		@include breakpoint(xlarge) {
			padding-top: 0;
		}
	}
	.section-label {
		position: absolute;
		top: rem-calc(14);
		left: 2.425rem;
		z-index: 999;
		@include breakpoint(xlarge) {
			left: 4.9375rem;
        }
		span {
			border-top: rem-calc(10) solid;
			padding-top: rem-calc(5);
			font-size: rem-calc(20);
			font-weight: 800;
		}
	}
}

.heading-label{
	border-top: 0.33rem solid;
	padding-top: 0.3125rem;
	font-size: 1.25rem;
	font-weight: 800;
}