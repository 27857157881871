hr {
    margin: 0 auto 1rem;
    border-bottom-width: 2px;
	
	&.dashed {
		border-bottom-style: dashed;
	}

    @each $name, $color in $all-colors {
        &.#{$name} {
            border-color: $color; 
        }
    }
}
