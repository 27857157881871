.news-list {
	padding: 3rem 1.8rem;
    @include breakpoint(xlarge) {
        padding: 4rem;
    }
	h3{
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 2rem;
        @include breakpoint(medium) {
            font-size: 2.5rem;
        }
    }
	.news-item {
		border-bottom: 2px dashed color('dark-gray');
		padding-bottom: 3rem;
		margin-top: 3rem;
		&:last-child {
			border: none;
			padding-bottom: 0;
		}
		.grid-x {
			display: flex;
			align-items: center;
			p, a {
				margin: 0;
			}
			p {
				@include breakpoint(medium down) {
					margin-bottom: 1rem;
				}
			}
		}
	}
}
.news-story-default-content {
	margin-top: 4rem;
	.about-motus {
		border-top: 2px dashed color('dark-gray');
		padding: 3rem 0;
		&:last-child {
			padding-bottom: 0;
		}
	}
	h6 {
		margin-bottom: 2rem;
	}
	p {
		margin: 0;
	}
}