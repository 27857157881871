// Landing page sort styling
// Return home linek styling for category and story page
a{
    &.back-home-bottom{
        color: color('black');
        font-weight: 600;
        font-size: 0.9rem;
        text-decoration: none;
        &:after{
            display: none;
        }
        &:before{
            display: inline-block;
            content: '';
            width:25px;
            height:10px;
            margin-right: 1rem;
            background: url('../img/story-arrow.png') no-repeat scroll 50% transparent;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
        &:hover{
            text-decoration: underline;
        }
    }
}

// General story styling for index and interior pages
.story{
    margin-bottom: 4rem;
	h1, h2, h3, h4, h5, h6 {
    	margin-bottom: 0.5rem;
	}
	&.featured {
		h2{
			font-size: 1.25rem;
			@include breakpoint(medium) {
				font-size: 2.5rem;
			}
		}
	}
	h2{
		font-size: 1.25rem;
		font-weight: normal;
		@include breakpoint(medium) {
			font-size: 1.875rem;
		}
		a {
			font-weight: normal;
			text-decoration: none;
			font-size: inherit;
			&:hover {
				text-decoration: underline;
			}
		}
	}
    ul.categories{
        margin-left: 0;
        min-height: 20px;
        @include breakpoint(400px down) {
            width: 100%;
        }
        li{
            font-size: 2rem; 
            line-height: 0.5;
            list-style-position: inside;
            float: left;
            width: auto;
			padding: 0;
			font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
            @include breakpoint(large only) {
                float: none;
                line-height: 0.8;
            }
            &.secondary-category{
                list-style-type: none;
                margin-left: 1.2rem;
                a{
                    margin-left: 0;
                    @-moz-document url-prefix() {
                        margin-left: 0.5rem;
                    }
                    @supports (-webkit-overflow-scrolling: touch) {
                    /* CSS specific to iOS devices */ 
                        margin-left: 0;
                    }
                }
            }
            a{
                vertical-align: middle;
                font-size: 0.8rem;
                margin-left: -1.5rem;
                @-moz-document url-prefix() {   
                    margin-left: 0;
                }
                @supports (-webkit-overflow-scrolling: touch) {
                /* CSS specific to iOS devices */ 
                    margin-left: -0.65rem;
                }
            }
            @supports (-webkit-overflow-scrolling: touch) {
            /* CSS specific to iOS devices */ 
                float: none;
            }
        }
    }
    .date {
        font-weight: 600;
        font-size: 0.8rem;
        margin-right: 1rem;
    }
    .date-container{
        @include breakpoint(400px down) {
            width: 100%;
        }
    }
    a{
        color: color('black');
        font-weight: 600;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
        &.back-home-top{
            color: color('black');
            font-size: 0.8rem;
            font-weight: 600;
        }
        &.read-more, &.back-home-bottom{
            text-decoration: none;
            font-size: 0.9rem;
            &:hover{
                text-decoration: underline;
            }
            &:after{
                display: inline-block;
                content: '';
                width:25px;
                height:10px;
                margin-left: 1rem;
                background: url('../img/story-arrow.png') no-repeat scroll 50% transparent;
            }
        }
        // Hide after arrow specifically for story page
        &.back-home-bottom{
            &:after{
                display: none;
            }
        }
    }
    // Image styles for story card
    .background-image{
        background-position: center center;
        background-size: cover;
        background-repeat: none;
        width: 100%;
        height: 300px;
        margin-bottom: 1.2rem;
        &.image-left{
            background-position-x: left;
        }
        &.image-right{
            background-position-x: right;
        }
    }
}


.category-breadcrumb{
    margin-bottom: 3rem;
}