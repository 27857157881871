@mixin skip-to-content {
  position: fixed !important;
  width: auto;
  height: auto;
  top: 0;
  left: 1rem;
  padding: .5em 1em;
  z-index: 999999;
  border-radius: 5px;
  background-color: color('very-light-gray');
  transform: translateY(-100%);
  transition: top 150ms ease, transform 150ms ease;
  
  &, &:hover, &:focus, &:visited {
    color: color('gray');
  }
  
  &:focus {
    position: fixed !important;
    top: 1rem;
    transform: translateY(0);
  }
}

.skip-to-content {
  @include skip-to-content;
}
