$tabs-panel-transition-duration: 250ms;
$tabs-panel-transition-ease: ease-out;
$tabs-panel-transition-delay: 100ms;
$tabs-panel-transition: $tabs-panel-transition-duration $tabs-panel-transition-ease $tabs-panel-transition-delay;

.tabs {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
  
  li {
    margin-right: 1rem;
	  padding: 0;
  }
}

.tabs-panel {
  opacity: 1;
  transition: opacity $tabs-panel-transition, transform $tabs-panel-transition;
  
  &[aria-hidden="true"] {
    position: fixed;
    height: 0;
    left: -500%;
    opacity: 0;
    overflow: hidden;
    transition: none;
    transform: translateY(.5rem);
  }
}