.modal {
  $modal-transition-duration: 250ms;
  $modal-transition-ease: ease-out;
  $modal-transition: $modal-transition-duration $modal-transition-ease;
  
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -500%;
  display: block;
  overflow: auto;
  opacity: 0;
  z-index: 999999;
  background-color: rgba(color('black'), .25);
  transition: opacity $modal-transition, left 0ms linear $modal-transition-duration;
  
  &.js-show {
    left: 0;
    opacity: 1;
    transition: opacity $modal-transition;
  }
  
  .modal-window {
    position: relative;
    width: rem-calc(750);
    max-width: 95%;
    top: 15%;
    margin: 0 auto rem-calc(50);
    padding: 1.5rem;
    background-color: color('white');
    box-shadow: 0 0 3rem rgba(color('black'), .25);
  }
  
  video {
    margin-bottom: 1.5rem; 
    max-width: 100%;
    display: block;
    
    &:last-child, &:nth-last-child(2) {
      margin-bottom: 0; 
    }
  }
  
  .close {
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: -1rem;
    right: -1rem;
    display: block;
    border: 3px solid color('white');
    border-radius: 50%;
    text-indent: -999999px;
    color: color('white');
    transform-origin: center center;
    transform: scale(.9);
    transition: transform 175ms ease-out;
    background-color: color('dark-gray');
    
    &:hover, &:focus {
      transform: scale(1);
    }
    
    &::before, &::after {
      position: absolute;
      width: 60%;
      height: 3px;
      top: 50%;
      left: 50%;
      margin-top: -2px;
      border-radius: 5px;
      display: block;
      content: "";
      transform-origin: center center;
      background-color: color('white');
    }
    
    &::before {
      transform: translateX(-50%) rotate(45deg); 
    }
    
    &::after {
      transform: translateX(-50%) rotate(-45deg); 
    }
  }
}