.page-footer {
    overflow: hidden;
    position: relative;
    p {
        color: color('dark-gray');
        font-size: 0.8rem;
        margin-bottom: 1rem;
    }
    .padding-container {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: color('light-gray');
    }
    .find-atm-module {
        padding-top: 2rem;
        padding-bottom: 2rem;
        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 0;
        }
    }
    .vertical_dotted_line
    {
        border-left: 1px dashed white;
        height: 40px;
        margin: 0 auto;
        width: 1px;
    } 
    .pre-footer {
        border-top: 1px solid color('light-gray');
        border-bottom: 1px solid color('light-gray');
        padding-top: 3rem;
        padding-bottom: 3rem;
        margin-bottom: 3rem;
        @include breakpoint(xlarge){
            padding-bottom: inherit;
        }
        h5 {
            text-transform: uppercase;
            font-size: 0.8rem;
            color: color('darkest-gray');
            font-weight: bold;
            margin-bottom: 1rem;
            display: block;
            width: 100%;
            @include breakpoint(medium){
                font-size: 0.9rem;
            }
            @media screen and (max-width: 340px) {
                font-size: 0.7rem;
            }
        }
        ul {
            list-style: none;
            margin: 0;
            li {
                margin-bottom: 1rem;
				padding: 0;
                &:last-child{
                    margin-bottom: 0;
                }
                &.margin-push{
                    margin-bottom: 3rem;
                }
                a {
                    color: color('dark-gray');
                    font-size: 0.8rem;
                    text-decoration: underline;
                    &:hover{
                        color: color('carbon');
                        text-decoration: none;
                    }
                }
            } 
        }
        /*.font-sizer {
            margin-bottom: 3rem;
            p {
                margin: 0;
            }
            .font-choice{
                margin-left: 0.5rem;
                @include breakpoint(xlarge){
                    margin-left: 0;
                    padding: 0.5rem 0;
                }
                @include breakpoint(xxxlarge){
                    margin-left: 0.5rem;
                }
                a {
                    background-color: #E6E7E8;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    text-align: center;
                    margin: 0 0.25rem;
                    color: #00D1AF;
                    line-height: 1.7;
                    &:hover, &:focus{
                        background-color: #00D1AF;
                        color: color('white');
                    }
                    &.small-text{
                        font-size: 0.8rem;
                        line-height: 2.3;
                    }
                    &.large-text{
                        font-size: 1.3rem;
                        line-height: 1.1;
                    }
                }
            }
        }*/
        .social-links-footer {
            a {
                margin-right: 0.75rem;
                text-decoration: none;
                @include breakpoint(xlarge only) {
                    margin-right: 0.10rem;
                }
                &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    svg {
                        fill: color('carbon');
                    }
                }
                img{
                    width:30px;
                    height: 30px;
                }
                svg{
                    fill: color('dark-gray');
                    max-width: 30px;
                    max-height: 30px;
                    transition: fill 175ms ease;
                }
            }
        }
    }
    .post-footer {
        // max-height: 450px;
        min-height: 150px;
        @include breakpoint(large){
            min-height:200px;
        }
        br {
            display: none;
            @include breakpoint(large down){
                display: block;
            } 
        }
        svg{
            min-width: 1000px;
            bottom: auto;
            min-height: 1050px;
            height: auto;
            margin-top: 2rem;
            left: -120%;
            position: absolute;
            @include breakpoint(medium){
                left: -45%;
            }
            @include breakpoint(large){
                left: -130%;
                min-width: 2300px;
                min-height: 2000px;
            }
            @include breakpoint(xlarge){
                left: -105%;
            }
            @include breakpoint(xxlarge){
                left: -80%;
            }
            @include breakpoint(xxxlarge){
                left: -55%;
            }
        }
        sup{
            top: -0.2em;
        }
    }
    .cdic {
        text-align: center;
        .cdic-svg-height { height: 3rem; }
    }
    #searchBtn {
        padding-left: 1em;
        &::placeholder {
            color: color('dark-gray');
        }
    }
    .location-search {
		// padding-bottom: 3rem;
		// margin-bottom: 3rem;
        // border-left: 1px dashed color('light-gray');
		a, button {
			margin: 1rem 0;
			@include breakpoint(medium) {
                margin: 0;
            }
			&.button {
				padding: .75em 1.25em;
			}
		}
    }
    .motus-svg.main {
        margin-top: 2rem;
        margin-bottom: 1rem;
        width: 4.6875rem;
    }
    
    .motus-svg {
        text-decoration: none;
        color: #00d1af;
        transition: color 250ms ease;
    }
}