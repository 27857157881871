.contributor-author-box {
    margin: 3rem auto; 
    h2 {
        margin-left: 0px;
        font-size: 1.64628rem;
        margin-bottom: 0;
        text-transform: uppercase; 
        @include breakpoint(medium) {
            font-size: 1.76388rem;
            margin-left: 20px;
        }
    }
    p {
        margin-left: 0;
        @include breakpoint(medium) {
            margin-left: 20px;
        }
    }
    hr {
        width: 75px;
        margin: 0.5rem auto;
        border-color: #00D1AF;
        border-width: 3px;
        @include breakpoint(medium) {
            margin-left: 20px;
        }
    }
    img {
        border-radius: 150px;
        width: 150px;
        height: 150px;
        margin-bottom: 1rem;
        @include breakpoint(medium) {
            margin: 0;
        }
    }
}