.grid-container {
    &.container-webpart {
        margin-bottom: 1.5rem;
        .grid-container {
            padding:0;
            margin: 0;
			.content-block, .testimonials .panel, .content-switcher {
				padding: 5rem 1.8rem 3rem;
				@include breakpoint(xlarge) {
					padding: 5rem 4rem 4rem;
				}
			}
			.promo {
				padding-top: 5rem;
			}
        }
    }
}

.grid-container {
	.grid-x {
		&.content-block {
			margin-bottom: 1.5rem;
			padding: 3rem 1.8rem;
			@include breakpoint(xlarge) {
				padding: 4rem;
			}
			p{
				line-height: 1.8;
				margin-bottom: 2rem;
			}
		}
	}
}
#carrers-padding-top {
	padding-top: 0rem;
	
}
h1 small, .h1 small, h2 small, .h2 small, h3 small, .h3 small, h4 small, .h4 small, h5 small, .h5 small, h6 small, .h6 small {
    line-height: 0;
    color: #1d1c1c;
    font-weight: 100;
}