@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal, .reveal.tiny, .reveal.small, .reveal.large {
    right: auto;
    left: auto;
    margin: 0 auto; } }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=48em&xlarge=64em&xxlarge=75em&xxxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: Sofia, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 80rem;
  margin: 0 auto; }
  @media print, screen and (min-width: 40em) {
    .grid-container {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }
  .grid-container.fluid {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 100%;
    margin: 0 auto; }
    @media print, screen and (min-width: 40em) {
      .grid-container.fluid {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem; } }
  .grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin: 0 auto; }

.grid-x {
  display: flex;
  flex-flow: row wrap; }

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; }
  .cell.auto {
    flex: 1 1 0px; }
  .cell.shrink {
    flex: 0 0 auto; }

.grid-x > .auto {
  width: auto; }

.grid-x > .shrink {
  width: auto; }

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; } }

@media print, screen and (min-width: 48em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; } }

@media screen and (min-width: 64em) {
  .grid-x > .xlarge-shrink, .grid-x > .xlarge-full, .grid-x > .xlarge-1, .grid-x > .xlarge-2, .grid-x > .xlarge-3, .grid-x > .xlarge-4, .grid-x > .xlarge-5, .grid-x > .xlarge-6, .grid-x > .xlarge-7, .grid-x > .xlarge-8, .grid-x > .xlarge-9, .grid-x > .xlarge-10, .grid-x > .xlarge-11, .grid-x > .xlarge-12 {
    flex-basis: auto; } }

@media screen and (min-width: 75em) {
  .grid-x > .xxlarge-shrink, .grid-x > .xxlarge-full, .grid-x > .xxlarge-1, .grid-x > .xxlarge-2, .grid-x > .xxlarge-3, .grid-x > .xxlarge-4, .grid-x > .xxlarge-5, .grid-x > .xxlarge-6, .grid-x > .xxlarge-7, .grid-x > .xxlarge-8, .grid-x > .xxlarge-9, .grid-x > .xxlarge-10, .grid-x > .xxlarge-11, .grid-x > .xxlarge-12 {
    flex-basis: auto; } }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

@media screen and (min-width: 64em) {
  .grid-x > .xlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xlarge-1 {
    width: 8.33333%; }
  .grid-x > .xlarge-2 {
    width: 16.66667%; }
  .grid-x > .xlarge-3 {
    width: 25%; }
  .grid-x > .xlarge-4 {
    width: 33.33333%; }
  .grid-x > .xlarge-5 {
    width: 41.66667%; }
  .grid-x > .xlarge-6 {
    width: 50%; }
  .grid-x > .xlarge-7 {
    width: 58.33333%; }
  .grid-x > .xlarge-8 {
    width: 66.66667%; }
  .grid-x > .xlarge-9 {
    width: 75%; }
  .grid-x > .xlarge-10 {
    width: 83.33333%; }
  .grid-x > .xlarge-11 {
    width: 91.66667%; }
  .grid-x > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .grid-x > .xxlarge-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .xxlarge-shrink {
    flex: 0 0 auto;
    width: auto; }
  .grid-x > .xxlarge-1 {
    width: 8.33333%; }
  .grid-x > .xxlarge-2 {
    width: 16.66667%; }
  .grid-x > .xxlarge-3 {
    width: 25%; }
  .grid-x > .xxlarge-4 {
    width: 33.33333%; }
  .grid-x > .xxlarge-5 {
    width: 41.66667%; }
  .grid-x > .xxlarge-6 {
    width: 50%; }
  .grid-x > .xxlarge-7 {
    width: 58.33333%; }
  .grid-x > .xxlarge-8 {
    width: 66.66667%; }
  .grid-x > .xxlarge-9 {
    width: 75%; }
  .grid-x > .xxlarge-10 {
    width: 83.33333%; }
  .grid-x > .xxlarge-11 {
    width: 91.66667%; }
  .grid-x > .xxlarge-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.875rem);
      margin-left: 0.9375rem;
      margin-right: 0.9375rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.25rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.25rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.25rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.25rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.25rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.25rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.25rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.25rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.25rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.875rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 48em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.875rem); } }
  @media screen and (min-width: 64em) {
    .grid-margin-x > .xlarge-auto {
      width: auto; }
    .grid-margin-x > .xlarge-shrink {
      width: auto; }
    .grid-margin-x > .xlarge-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .xlarge-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .xlarge-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .xlarge-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .xlarge-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .xlarge-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .xlarge-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .xlarge-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .xlarge-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .xlarge-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .xlarge-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .xlarge-12 {
      width: calc(100% - 1.875rem); } }
  @media screen and (min-width: 75em) {
    .grid-margin-x > .xxlarge-auto {
      width: auto; }
    .grid-margin-x > .xxlarge-shrink {
      width: auto; }
    .grid-margin-x > .xxlarge-1 {
      width: calc(8.33333% - 1.875rem); }
    .grid-margin-x > .xxlarge-2 {
      width: calc(16.66667% - 1.875rem); }
    .grid-margin-x > .xxlarge-3 {
      width: calc(25% - 1.875rem); }
    .grid-margin-x > .xxlarge-4 {
      width: calc(33.33333% - 1.875rem); }
    .grid-margin-x > .xxlarge-5 {
      width: calc(41.66667% - 1.875rem); }
    .grid-margin-x > .xxlarge-6 {
      width: calc(50% - 1.875rem); }
    .grid-margin-x > .xxlarge-7 {
      width: calc(58.33333% - 1.875rem); }
    .grid-margin-x > .xxlarge-8 {
      width: calc(66.66667% - 1.875rem); }
    .grid-margin-x > .xxlarge-9 {
      width: calc(75% - 1.875rem); }
    .grid-margin-x > .xxlarge-10 {
      width: calc(83.33333% - 1.875rem); }
    .grid-margin-x > .xxlarge-11 {
      width: calc(91.66667% - 1.875rem); }
    .grid-margin-x > .xxlarge-12 {
      width: calc(100% - 1.875rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.9375rem;
      margin-left: -0.9375rem; } }

.grid-padding-x > .cell {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.9375rem;
      padding-left: 0.9375rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 48em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 64em) {
  .xlarge-up-1 > .cell {
    width: 100%; }
  .xlarge-up-2 > .cell {
    width: 50%; }
  .xlarge-up-3 > .cell {
    width: 33.33333%; }
  .xlarge-up-4 > .cell {
    width: 25%; }
  .xlarge-up-5 > .cell {
    width: 20%; }
  .xlarge-up-6 > .cell {
    width: 16.66667%; }
  .xlarge-up-7 > .cell {
    width: 14.28571%; }
  .xlarge-up-8 > .cell {
    width: 12.5%; } }

@media screen and (min-width: 75em) {
  .xxlarge-up-1 > .cell {
    width: 100%; }
  .xxlarge-up-2 > .cell {
    width: 50%; }
  .xxlarge-up-3 > .cell {
    width: 33.33333%; }
  .xxlarge-up-4 > .cell {
    width: 25%; }
  .xxlarge-up-5 > .cell {
    width: 20%; }
  .xxlarge-up-6 > .cell {
    width: 16.66667%; }
  .xxlarge-up-7 > .cell {
    width: 14.28571%; }
  .xxlarge-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.25rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.25rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.25rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.25rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.25rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.25rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.25rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.25rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.25rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.25rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.25rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media print, screen and (min-width: 48em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media screen and (min-width: 64em) {
  .grid-margin-x.xlarge-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.xlarge-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.xlarge-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.xlarge-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.xlarge-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.xlarge-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.xlarge-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.xlarge-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

@media screen and (min-width: 75em) {
  .grid-margin-x.xxlarge-up-1 > .cell {
    width: calc(100% - 1.875rem); }
  .grid-margin-x.xxlarge-up-2 > .cell {
    width: calc(50% - 1.875rem); }
  .grid-margin-x.xxlarge-up-3 > .cell {
    width: calc(33.33333% - 1.875rem); }
  .grid-margin-x.xxlarge-up-4 > .cell {
    width: calc(25% - 1.875rem); }
  .grid-margin-x.xxlarge-up-5 > .cell {
    width: calc(20% - 1.875rem); }
  .grid-margin-x.xxlarge-up-6 > .cell {
    width: calc(16.66667% - 1.875rem); }
  .grid-margin-x.xxlarge-up-7 > .cell {
    width: calc(14.28571% - 1.875rem); }
  .grid-margin-x.xxlarge-up-8 > .cell {
    width: calc(12.5% - 1.875rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 48em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }
  @media screen and (min-width: 64em) {
    .small-margin-collapse > .xlarge-1 {
      width: 8.33333%; }
    .small-margin-collapse > .xlarge-2 {
      width: 16.66667%; }
    .small-margin-collapse > .xlarge-3 {
      width: 25%; }
    .small-margin-collapse > .xlarge-4 {
      width: 33.33333%; }
    .small-margin-collapse > .xlarge-5 {
      width: 41.66667%; }
    .small-margin-collapse > .xlarge-6 {
      width: 50%; }
    .small-margin-collapse > .xlarge-7 {
      width: 58.33333%; }
    .small-margin-collapse > .xlarge-8 {
      width: 66.66667%; }
    .small-margin-collapse > .xlarge-9 {
      width: 75%; }
    .small-margin-collapse > .xlarge-10 {
      width: 83.33333%; }
    .small-margin-collapse > .xlarge-11 {
      width: 91.66667%; }
    .small-margin-collapse > .xlarge-12 {
      width: 100%; } }
  @media screen and (min-width: 75em) {
    .small-margin-collapse > .xxlarge-1 {
      width: 8.33333%; }
    .small-margin-collapse > .xxlarge-2 {
      width: 16.66667%; }
    .small-margin-collapse > .xxlarge-3 {
      width: 25%; }
    .small-margin-collapse > .xxlarge-4 {
      width: 33.33333%; }
    .small-margin-collapse > .xxlarge-5 {
      width: 41.66667%; }
    .small-margin-collapse > .xxlarge-6 {
      width: 50%; }
    .small-margin-collapse > .xxlarge-7 {
      width: 58.33333%; }
    .small-margin-collapse > .xxlarge-8 {
      width: 66.66667%; }
    .small-margin-collapse > .xxlarge-9 {
      width: 75%; }
    .small-margin-collapse > .xxlarge-10 {
      width: 83.33333%; }
    .small-margin-collapse > .xxlarge-11 {
      width: 91.66667%; }
    .small-margin-collapse > .xxlarge-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 64em) {
  .medium-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .xlarge-3 {
    width: 25%; }
  .medium-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .xlarge-6 {
    width: 50%; }
  .medium-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .xlarge-9 {
    width: 75%; }
  .medium-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .medium-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .medium-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .medium-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .medium-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 48em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 48em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 64em) {
  .large-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .large-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .large-margin-collapse > .xlarge-3 {
    width: 25%; }
  .large-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .large-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .large-margin-collapse > .xlarge-6 {
    width: 50%; }
  .large-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .large-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .large-margin-collapse > .xlarge-9 {
    width: 75%; }
  .large-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .large-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .large-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .large-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .large-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .large-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .large-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .large-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .large-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .large-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .large-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .large-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .large-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .large-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .large-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media print, screen and (min-width: 48em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 64em) {
  .xlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 64em) {
  .xlarge-margin-collapse > .small-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .small-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .small-3 {
    width: 25%; }
  .xlarge-margin-collapse > .small-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .small-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .small-6 {
    width: 50%; }
  .xlarge-margin-collapse > .small-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .small-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .small-9 {
    width: 75%; }
  .xlarge-margin-collapse > .small-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .small-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .small-12 {
    width: 100%; } }

@media screen and (min-width: 64em) {
  .xlarge-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .medium-3 {
    width: 25%; }
  .xlarge-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .medium-6 {
    width: 50%; }
  .xlarge-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .medium-9 {
    width: 75%; }
  .xlarge-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .medium-12 {
    width: 100%; } }

@media screen and (min-width: 64em) {
  .xlarge-margin-collapse > .large-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .large-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .large-3 {
    width: 25%; }
  .xlarge-margin-collapse > .large-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .large-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .large-6 {
    width: 50%; }
  .xlarge-margin-collapse > .large-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .large-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .large-9 {
    width: 75%; }
  .xlarge-margin-collapse > .large-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .large-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 64em) {
  .xlarge-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xlarge-3 {
    width: 25%; }
  .xlarge-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xlarge-6 {
    width: 50%; }
  .xlarge-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xlarge-9 {
    width: 75%; }
  .xlarge-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xlarge-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .xlarge-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .xlarge-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .xlarge-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .xlarge-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .xlarge-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .xlarge-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .xlarge-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .xlarge-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .xlarge-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .xlarge-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .xlarge-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media screen and (min-width: 64em) {
  .xlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media screen and (min-width: 75em) {
  .xxlarge-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media screen and (min-width: 75em) {
  .xxlarge-margin-collapse > .small-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .small-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .small-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .small-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .small-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .small-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .small-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .small-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .small-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .small-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .small-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .small-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xxlarge-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .medium-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .medium-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .medium-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .medium-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xxlarge-margin-collapse > .large-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .large-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .large-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .large-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .large-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .large-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .large-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .large-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .large-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .large-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .large-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .large-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xxlarge-margin-collapse > .xlarge-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xlarge-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xlarge-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .xlarge-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xlarge-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xlarge-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .xlarge-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xlarge-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xlarge-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .xlarge-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xlarge-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xlarge-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xxlarge-margin-collapse > .xxlarge-1 {
    width: 8.33333%; }
  .xxlarge-margin-collapse > .xxlarge-2 {
    width: 16.66667%; }
  .xxlarge-margin-collapse > .xxlarge-3 {
    width: 25%; }
  .xxlarge-margin-collapse > .xxlarge-4 {
    width: 33.33333%; }
  .xxlarge-margin-collapse > .xxlarge-5 {
    width: 41.66667%; }
  .xxlarge-margin-collapse > .xxlarge-6 {
    width: 50%; }
  .xxlarge-margin-collapse > .xxlarge-7 {
    width: 58.33333%; }
  .xxlarge-margin-collapse > .xxlarge-8 {
    width: 66.66667%; }
  .xxlarge-margin-collapse > .xxlarge-9 {
    width: 75%; }
  .xxlarge-margin-collapse > .xxlarge-10 {
    width: 83.33333%; }
  .xxlarge-margin-collapse > .xxlarge-11 {
    width: 91.66667%; }
  .xxlarge-margin-collapse > .xxlarge-12 {
    width: 100%; } }

@media screen and (min-width: 75em) {
  .xxlarge-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .xxlarge-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.625rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.625rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.625rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.625rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.625rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.625rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.625rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.625rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.625rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.625rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.625rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.625rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media print, screen and (min-width: 48em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media screen and (min-width: 64em) {
  .xlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xlarge-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .xlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xlarge-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .xlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xlarge-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .xlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xlarge-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .xlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xlarge-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .xlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xlarge-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .xlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xlarge-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .xlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xlarge-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .xlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xlarge-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .xlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xlarge-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .xlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xlarge-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .xlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xlarge-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

@media screen and (min-width: 75em) {
  .xxlarge-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .xxlarge-offset-0 {
    margin-left: calc(0% + 0.9375rem); }
  .xxlarge-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .xxlarge-offset-1 {
    margin-left: calc(8.33333% + 0.9375rem); }
  .xxlarge-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .xxlarge-offset-2 {
    margin-left: calc(16.66667% + 0.9375rem); }
  .xxlarge-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .xxlarge-offset-3 {
    margin-left: calc(25% + 0.9375rem); }
  .xxlarge-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .xxlarge-offset-4 {
    margin-left: calc(33.33333% + 0.9375rem); }
  .xxlarge-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .xxlarge-offset-5 {
    margin-left: calc(41.66667% + 0.9375rem); }
  .xxlarge-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .xxlarge-offset-6 {
    margin-left: calc(50% + 0.9375rem); }
  .xxlarge-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .xxlarge-offset-7 {
    margin-left: calc(58.33333% + 0.9375rem); }
  .xxlarge-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .xxlarge-offset-8 {
    margin-left: calc(66.66667% + 0.9375rem); }
  .xxlarge-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .xxlarge-offset-9 {
    margin-left: calc(75% + 0.9375rem); }
  .xxlarge-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .xxlarge-offset-10 {
    margin-left: calc(83.33333% + 0.9375rem); }
  .xxlarge-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .xxlarge-offset-11 {
    margin-left: calc(91.66667% + 0.9375rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    width: auto; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 48em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  @media screen and (min-width: 64em) {
    .grid-y > .xlarge-shrink, .grid-y > .xlarge-full, .grid-y > .xlarge-1, .grid-y > .xlarge-2, .grid-y > .xlarge-3, .grid-y > .xlarge-4, .grid-y > .xlarge-5, .grid-y > .xlarge-6, .grid-y > .xlarge-7, .grid-y > .xlarge-8, .grid-y > .xlarge-9, .grid-y > .xlarge-10, .grid-y > .xlarge-11, .grid-y > .xlarge-12 {
      flex-basis: auto; } }
  @media screen and (min-width: 75em) {
    .grid-y > .xxlarge-shrink, .grid-y > .xxlarge-full, .grid-y > .xxlarge-1, .grid-y > .xxlarge-2, .grid-y > .xxlarge-3, .grid-y > .xxlarge-4, .grid-y > .xxlarge-5, .grid-y > .xxlarge-6, .grid-y > .xxlarge-7, .grid-y > .xxlarge-8, .grid-y > .xxlarge-9, .grid-y > .xxlarge-10, .grid-y > .xxlarge-11, .grid-y > .xxlarge-12 {
      flex-basis: auto; } }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 48em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }
  @media screen and (min-width: 64em) {
    .grid-y > .xlarge-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .xlarge-shrink {
      height: auto; }
    .grid-y > .xlarge-1 {
      height: 8.33333%; }
    .grid-y > .xlarge-2 {
      height: 16.66667%; }
    .grid-y > .xlarge-3 {
      height: 25%; }
    .grid-y > .xlarge-4 {
      height: 33.33333%; }
    .grid-y > .xlarge-5 {
      height: 41.66667%; }
    .grid-y > .xlarge-6 {
      height: 50%; }
    .grid-y > .xlarge-7 {
      height: 58.33333%; }
    .grid-y > .xlarge-8 {
      height: 66.66667%; }
    .grid-y > .xlarge-9 {
      height: 75%; }
    .grid-y > .xlarge-10 {
      height: 83.33333%; }
    .grid-y > .xlarge-11 {
      height: 91.66667%; }
    .grid-y > .xlarge-12 {
      height: 100%; } }
  @media screen and (min-width: 75em) {
    .grid-y > .xxlarge-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .xxlarge-shrink {
      height: auto; }
    .grid-y > .xxlarge-1 {
      height: 8.33333%; }
    .grid-y > .xxlarge-2 {
      height: 16.66667%; }
    .grid-y > .xxlarge-3 {
      height: 25%; }
    .grid-y > .xxlarge-4 {
      height: 33.33333%; }
    .grid-y > .xxlarge-5 {
      height: 41.66667%; }
    .grid-y > .xxlarge-6 {
      height: 50%; }
    .grid-y > .xxlarge-7 {
      height: 58.33333%; }
    .grid-y > .xxlarge-8 {
      height: 66.66667%; }
    .grid-y > .xxlarge-9 {
      height: 75%; }
    .grid-y > .xxlarge-10 {
      height: 83.33333%; }
    .grid-y > .xxlarge-11 {
      height: 91.66667%; }
    .grid-y > .xxlarge-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }

.grid-padding-y > .cell {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }
  @media screen and (min-width: 64em) {
    .grid-margin-y > .xlarge-auto {
      height: auto; }
    .grid-margin-y > .xlarge-shrink {
      height: auto; }
    .grid-margin-y > .xlarge-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .xlarge-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .xlarge-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .xlarge-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-12 {
      height: calc(100% - 1.875rem); } }
  @media screen and (min-width: 75em) {
    .grid-margin-y > .xxlarge-auto {
      height: auto; }
    .grid-margin-y > .xxlarge-shrink {
      height: auto; }
    .grid-margin-y > .xxlarge-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .xxlarge-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .xxlarge-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .xxlarge-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-stype: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 48em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 64em) {
  .xlarge-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .xlarge-grid-frame {
    width: 100%; }
  .xlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xlarge-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .xlarge-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .xlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

@media screen and (min-width: 75em) {
  .xxlarge-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .xxlarge-grid-frame {
    width: 100%; }
  .xxlarge-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; }
  .xxlarge-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .xxlarge-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .xxlarge-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-stype: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 48em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media screen and (min-width: 64em) {
  .grid-y.xlarge-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media screen and (min-width: 75em) {
  .grid-y.xxlarge-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 48em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

@media screen and (min-width: 64em) {
  .cell .grid-y.xlarge-grid-frame {
    height: 100%; } }

@media screen and (min-width: 75em) {
  .cell .grid-y.xxlarge-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.9375rem;
      margin-bottom: -0.9375rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.875rem);
      margin-top: 0.9375rem;
      margin-bottom: 0.9375rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.25rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.25rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.25rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.25rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.25rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.25rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.25rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.25rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.25rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.25rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.25rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.875rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.875rem); } }
  @media print, screen and (min-width: 48em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.875rem); } }
  @media screen and (min-width: 64em) {
    .grid-margin-y > .xlarge-auto {
      height: auto; }
    .grid-margin-y > .xlarge-shrink {
      height: auto; }
    .grid-margin-y > .xlarge-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .xlarge-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .xlarge-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .xlarge-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .xlarge-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .xlarge-12 {
      height: calc(100% - 1.875rem); } }
  @media screen and (min-width: 75em) {
    .grid-margin-y > .xxlarge-auto {
      height: auto; }
    .grid-margin-y > .xxlarge-shrink {
      height: auto; }
    .grid-margin-y > .xxlarge-1 {
      height: calc(8.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-2 {
      height: calc(16.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-3 {
      height: calc(25% - 1.875rem); }
    .grid-margin-y > .xxlarge-4 {
      height: calc(33.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-5 {
      height: calc(41.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-6 {
      height: calc(50% - 1.875rem); }
    .grid-margin-y > .xxlarge-7 {
      height: calc(58.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-8 {
      height: calc(66.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-9 {
      height: calc(75% - 1.875rem); }
    .grid-margin-y > .xxlarge-10 {
      height: calc(83.33333% - 1.875rem); }
    .grid-margin-y > .xxlarge-11 {
      height: calc(91.66667% - 1.875rem); }
    .grid-margin-y > .xxlarge-12 {
      height: calc(100% - 1.875rem); } }

.grid-frame.grid-margin-y {
  height: calc(100vh + 1.25rem); }
  @media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }
  @media print, screen and (min-width: 48em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }
  @media screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }
  @media screen and (min-width: 75em) {
    .grid-frame.grid-margin-y {
      height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 40em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media print, screen and (min-width: 48em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media screen and (min-width: 64em) {
  .grid-margin-y.xlarge-grid-frame {
    height: calc(100vh + 1.875rem); } }

@media screen and (min-width: 75em) {
  .grid-margin-y.xxlarge-grid-frame {
    height: calc(100vh + 1.875rem); } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: Sofia, "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small, .h1 small,
  h2 small, .h2 small,
  h3 small, .h3 small,
  h4 small, .h4 small,
  h5 small, .h5 small,
  h6 small, .h6 small {
    line-height: 0;
    color: #cacaca; }

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem; }
  h2, .h2 {
    font-size: 2.5rem; }
  h3, .h3 {
    font-size: 1.9375rem; }
  h4, .h4 {
    font-size: 1.5625rem; }
  h5, .h5 {
    font-size: 1.25rem; }
  h6, .h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: inherit;
  text-decoration: underline;
  cursor: pointer; }
  a:hover, a:focus {
    color: inherit;
    text-decoration: none; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 80rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0a0a0a; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 48em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

@media screen and (min-width: 64em) {
  .xlarge-text-left {
    text-align: left; }
  .xlarge-text-right {
    text-align: right; }
  .xlarge-text-center {
    text-align: center; }
  .xlarge-text-justify {
    text-align: justify; } }

@media screen and (min-width: 75em) {
  .xxlarge-text-left {
    text-align: left; }
  .xxlarge-text-right {
    text-align: right; }
  .xxlarge-text-center {
    text-align: center; }
  .xxlarge-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #00D1AF;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #00b295;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #00D1AF;
    color: #0a0a0a; }
    .button.primary:hover, .button.primary:focus {
      background-color: #00a78c;
      color: #0a0a0a; }
  .button.secondary {
    background-color: #767676;
    color: #fefefe; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #fefefe; }
  .button.success {
    background-color: #3adb76;
    color: #0a0a0a; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #0a0a0a; }
  .button.warning {
    background-color: #ffae00;
    color: #0a0a0a; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #0a0a0a; }
  .button.alert {
    background-color: #cc4b37;
    color: #fefefe; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #fefefe; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #00D1AF;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #00D1AF;
        color: #0a0a0a; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #fefefe; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #0a0a0a; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #0a0a0a; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #fefefe; }
  .button.hollow {
    border: 1px solid #00D1AF;
    color: #00D1AF; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #006958;
      color: #006958; }
      .button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
        border: 1px solid #00D1AF;
        color: #00D1AF; }
    .button.hollow.primary {
      border: 1px solid #00D1AF;
      color: #00D1AF; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #006958;
        color: #006958; }
        .button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
          border: 1px solid #00D1AF;
          color: #00D1AF; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
  .button.clear {
    border: 1px solid #00D1AF;
    color: #00D1AF; }
    .button.clear, .button.clear:hover, .button.clear:focus {
      background-color: transparent; }
    .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
      background-color: transparent; }
    .button.clear:hover, .button.clear:focus {
      border-color: #006958;
      color: #006958; }
      .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
        border: 1px solid #00D1AF;
        color: #00D1AF; }
    .button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
      border-color: transparent; }
    .button.clear.primary {
      border: 1px solid #00D1AF;
      color: #00D1AF; }
      .button.clear.primary:hover, .button.clear.primary:focus {
        border-color: #006958;
        color: #006958; }
        .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
          border: 1px solid #00D1AF;
          color: #00D1AF; }
      .button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
        border-color: transparent; }
    .button.clear.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.clear.secondary:hover, .button.clear.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
        .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
          border: 1px solid #767676;
          color: #767676; }
      .button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
        border-color: transparent; }
    .button.clear.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.clear.success:hover, .button.clear.success:focus {
        border-color: #157539;
        color: #157539; }
        .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
          border: 1px solid #3adb76;
          color: #3adb76; }
      .button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
        border-color: transparent; }
    .button.clear.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.clear.warning:hover, .button.clear.warning:focus {
        border-color: #805700;
        color: #805700; }
        .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
          border: 1px solid #ffae00;
          color: #ffae00; }
      .button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
        border-color: transparent; }
    .button.clear.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.clear.alert:hover, .button.clear.alert:focus {
        border-color: #67251a;
        color: #67251a; }
        .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
          border: 1px solid #cc4b37;
          color: #cc4b37; }
      .button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
        border-color: transparent; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #00D1AF; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #00D1AF; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #767676; }
  .button.dropdown.hollow.success::after {
    border-top-color: #3adb76; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

a.button:hover, a.button:focus {
  text-decoration: none; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #0a0a0a; }

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  align-items: stretch; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: auto;
    align-self: stretch;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }
  .accordion[disabled] .accordion-title {
    cursor: not-allowed; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: #00D1AF; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #e6e6e6; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #e6e6e6; }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: 0.7rem 1rem; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 48em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 64em) {
    .menu.xlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xlarge-simple li {
      flex: 1 1 0px; } }
  @media screen and (min-width: 75em) {
    .menu.xxlarge-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.xxlarge-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.xxlarge-expanded li {
      flex: 1 1 0px; }
    .menu.xxlarge-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #00D1AF;
    color: #fefefe; }
  .menu .active > a {
    background: #00D1AF;
    color: #fefefe; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; }
  .menu-centered > .menu li {
    display: flex;
    justify-content: center; }
    .menu-centered > .menu li .submenu li {
      justify-content: flex-start; }

.no-js [data-responsive-menu] ul {
  display: none; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: inherit transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px; }

.dropdown.menu a {
  padding: 0.7rem 1rem; }
  [data-whatinput='mouse'] .dropdown.menu a {
    outline: 0; }

.dropdown.menu .is-active > a {
  background: transparent;
  color: #00D1AF; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent inherit transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent inherit; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: inherit transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent inherit transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent inherit; } }

@media print, screen and (min-width: 48em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: inherit transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent inherit transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent inherit; } }

@media screen and (min-width: 64em) {
  .dropdown.menu.xlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: inherit transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.xlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent inherit transparent transparent; }
  .dropdown.menu.xlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent inherit; } }

@media screen and (min-width: 75em) {
  .dropdown.menu.xxlarge-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.xxlarge-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.xxlarge-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: inherit transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px; }
  .dropdown.menu.xxlarge-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.xxlarge-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.xxlarge-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent inherit transparent transparent; }
  .dropdown.menu.xxlarge-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent inherit; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    left: auto;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .dropdown .is-dropdown-submenu a {
    padding: 0.7rem 1rem; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent inherit transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent inherit; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' ';
    flex-basis: 0;
    order: 1; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 40em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #0a0a0a; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #00D1AF;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #0a0a0a; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 10000;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      min-height: 0; } }
  .reveal .column {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal {
      width: 600px;
      max-width: 80rem; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 40em) {
    .reveal.tiny {
      width: 30%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.small {
      width: 50%;
      max-width: 80rem; } }
  @media print, screen and (min-width: 40em) {
    .reveal.large {
      width: 90%;
      max-width: 80rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 39.9375em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 47.9375em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 48em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 48em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 47.9375em), screen and (min-width: 64em) {
  .show-for-large-only {
    display: none !important; } }

@media screen and (min-width: 64em) {
  .hide-for-xlarge {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  .show-for-xlarge {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-xlarge-only {
    display: none !important; } }

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-xlarge-only {
    display: none !important; } }

@media screen and (min-width: 75em) {
  .hide-for-xxlarge {
    display: none !important; } }

@media screen and (max-width: 74.9375em) {
  .show-for-xxlarge {
    display: none !important; } }

@media screen and (min-width: 75em) and (max-width: 89.9375em) {
  .hide-for-xxlarge-only {
    display: none !important; } }

@media screen and (max-width: 74.9375em), screen and (min-width: 90em) {
  .show-for-xxlarge-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1; }

.clearfix::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-right.vertical.menu > li > a {
  justify-content: flex-end; }

.align-center.vertical.menu > li > a {
  justify-content: center; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 48em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

@media screen and (min-width: 64em) {
  .xlarge-order-1 {
    order: 1; }
  .xlarge-order-2 {
    order: 2; }
  .xlarge-order-3 {
    order: 3; }
  .xlarge-order-4 {
    order: 4; }
  .xlarge-order-5 {
    order: 5; }
  .xlarge-order-6 {
    order: 6; } }

@media screen and (min-width: 75em) {
  .xxlarge-order-1 {
    order: 1; }
  .xxlarge-order-2 {
    order: 2; }
  .xxlarge-order-3 {
    order: 3; }
  .xxlarge-order-4 {
    order: 4; }
  .xxlarge-order-5 {
    order: 5; }
  .xxlarge-order-6 {
    order: 6; } }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; }
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media print, screen and (min-width: 48em) {
  .large-flex-container {
    display: flex; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; }
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 64em) {
  .xlarge-flex-container {
    display: flex; }
  .xlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xlarge-flex-dir-row {
    flex-direction: row; }
  .xlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xlarge-flex-dir-column {
    flex-direction: column; }
  .xlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

@media screen and (min-width: 75em) {
  .xxlarge-flex-container {
    display: flex; }
  .xxlarge-flex-child-auto {
    flex: 1 1 auto; }
  .xxlarge-flex-child-grow {
    flex: 1 0 auto; }
  .xxlarge-flex-child-shrink {
    flex: 0 1 auto; }
  .xxlarge-flex-dir-row {
    flex-direction: row; }
  .xxlarge-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .xxlarge-flex-dir-column {
    flex-direction: column; }
  .xxlarge-flex-dir-column-reverse {
    flex-direction: column-reverse; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.accent-text {
  color: #00D1AF; }

.accent-background {
  background-color: #00D1AF; }

.accent-border {
  border-color: #00D1AF !important; }

.white-text {
  color: #FFFFFF; }

.white-background {
  background-color: #FFFFFF; }

.white-border {
  border-color: #FFFFFF !important; }

.carbon-text {
  color: #414042; }

.carbon-background {
  background-color: #414042; }

.carbon-border {
  border-color: #414042 !important; }

.black-text {
  color: #000000; }

.black-background {
  background-color: #000000; }

.black-border {
  border-color: #000000 !important; }

.lightest-gray-text {
  color: #FAFAFA; }

.lightest-gray-background {
  background-color: #FAFAFA; }

.lightest-gray-border {
  border-color: #FAFAFA !important; }

.very-light-gray-text {
  color: #F3F3F4; }

.very-light-gray-background {
  background-color: #F3F3F4; }

.very-light-gray-border {
  border-color: #F3F3F4 !important; }

.light-gray-text {
  color: #E6E7E8; }

.light-gray-background {
  background-color: #E6E7E8; }

.light-gray-border {
  border-color: #E6E7E8 !important; }

.gray-text {
  color: #DADADA; }

.gray-background {
  background-color: #DADADA; }

.gray-border {
  border-color: #DADADA !important; }

.medium-gray-text {
  color: #C1C1C1; }

.medium-gray-background {
  background-color: #C1C1C1; }

.medium-gray-border {
  border-color: #C1C1C1 !important; }

.dark-gray-text {
  color: #A8A8A8; }

.dark-gray-background {
  background-color: #A8A8A8; }

.dark-gray-border {
  border-color: #A8A8A8 !important; }

.darkest-gray-text {
  color: #545558; }

.darkest-gray-background {
  background-color: #545558; }

.darkest-gray-border {
  border-color: #545558 !important; }

.teal-text {
  color: #00D1AF; }

.teal-background {
  background-color: #00D1AF; }

.teal-border {
  border-color: #00D1AF !important; }

.transparent-text {
  color: transparent; }

.transparent-background {
  background-color: transparent; }

.transparent-border {
  border-color: transparent !important; }

@font-face {
  font-family: 'Sofia';
  src: url("../fonts/SofiaProRegular.otf");
  src: local("☺"), url("../fonts/SofiaProRegular.woff") format("woff"), url("../fonts/SofiaProRegular.ttf") format("truetype"), url("../fonts/SofiaProRegular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Sofia';
  src: url("../fonts/SofiaPro-Bold.otf");
  src: local("☺"), url("../fonts/SofiaPro-Bold.woff") format("woff"), url("../fonts/SofiaPro-Bold.ttf") format("truetype"), url("../fonts/SofiaPro-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Sofia';
  src: url("../fonts/SofiaPro-Italic.otf");
  src: local("☺"), url("../fonts/SofiaPro-Italic.woff") format("woff"), url("../fonts/SofiaPro-Italic.ttf") format("truetype"), url("../fonts/SofiaPro-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

.bg-left {
  background-position-x: left; }

.bg-right {
  background-position-x: right; }

.bg-top {
  background-position-y: top; }

.bg-bottom {
  background-position-y: bottom; }

.bg-center-x {
  background-position-x: center; }

.bg-center-y {
  background-position-y: center; }

.bg-full-center {
  background-position-x: center;
  background-position-y: center; }

.bg-size-auto {
  background-size: auto; }

.bg-size-auto-with-height {
  background-size: auto 100%; }

.bg-size-contain {
  background-size: contain; }

.bg-size-cover {
  background-size: cover; }

hr {
  margin: 0 auto 1rem;
  border-bottom-width: 2px; }
  hr.dashed {
    border-bottom-style: dashed; }
  hr.accent {
    border-color: #00D1AF; }
  hr.white {
    border-color: #FFFFFF; }
  hr.carbon {
    border-color: #414042; }
  hr.black {
    border-color: #000000; }
  hr.lightest-gray {
    border-color: #FAFAFA; }
  hr.very-light-gray {
    border-color: #F3F3F4; }
  hr.light-gray {
    border-color: #E6E7E8; }
  hr.gray {
    border-color: #DADADA; }
  hr.medium-gray {
    border-color: #C1C1C1; }
  hr.dark-gray {
    border-color: #A8A8A8; }
  hr.darkest-gray {
    border-color: #545558; }
  hr.teal {
    border-color: #00D1AF; }
  hr.transparent {
    border-color: transparent; }

.full-bleed-container {
  padding: 0;
  background-color: #FFFFFF;
  overflow: hidden; }
  .full-bleed-container .bg-image {
    position: absolute;
    left: 50%;
    margin-left: -50%;
    overflow: hidden; }
    .full-bleed-container .bg-image img {
      height: 100%; }
  .full-bleed-container.header-correct {
    margin-top: 0;
    overflow: inherit;
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.2); }

.bleed-image-container {
  position: relative; }
  .bleed-image-container > img {
    position: absolute;
    top: 50%;
    margin-top: -50%;
    left: 50%;
    margin-left: -50%;
    max-width: none; }
    .bleed-image-container > img.right {
      right: 0;
      left: auto;
      margin-left: 0; }
    .bleed-image-container > img.bottom {
      margin-top: 0;
      top: auto; }
    .bleed-image-container > img.left {
      left: 0;
      margin-left: 0;
      right: auto; }
    .bleed-image-container > img.top {
      top: -4rem;
      margin-top: 0;
      bottom: auto; }

.navigation {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem; }
  @media screen and (max-width: 47.9375em) {
    .navigation {
      font-size: 1.375rem; } }
  .navigation ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    width: 100%; }
    @media screen and (max-width: 47.9375em) {
      .navigation ul {
        padding-bottom: 1rem; } }
  .navigation a {
    padding: 1em 0;
    font-size: inherit;
    display: inline-block;
    text-decoration: none;
    width: 100%;
    line-height: 1.3; }
    @media screen and (max-width: 63.9375em) {
      .navigation a {
        padding-left: .75em;
        padding-right: .75em; } }
    @media screen and (max-width: 47.9375em) {
      .navigation a {
        padding: 0;
        text-transform: none;
        font-size: 1rem; } }

.secondary.navigation {
  margin: 2rem auto; }
  @media screen and (max-width: 39.9375em) {
    .secondary.navigation {
      display: none; } }
  .secondary.navigation a {
    position: relative;
    transition: background-color 85ms cubic-bezier(0.55, 0.43, 0.41, 0.85), color 85ms cubic-bezier(0.55, 0.43, 0.41, 0.85); }
  .secondary.navigation .active-state {
    position: absolute;
    width: calc(100% - 0.25em);
    height: 0.25rem;
    top: 50%;
    left: 0;
    margin-top: 1em;
    display: block;
    transform-origin: left center;
    transform: scaleX(1);
    transition: transform 145ms cubic-bezier(0.55, 0.43, 0.41, 0.85); }
    @media screen and (max-width: 39.9375em) {
      .secondary.navigation .active-state {
        margin-top: 1.5em; } }
  .secondary.navigation .active.category .active-state,
  .secondary.navigation a:hover .active-state {
    transform: scaleX(0.25); }
  .secondary.navigation .category {
    position: relative;
    width: 20%;
    padding: 0; }
  .secondary.navigation ul li::before {
    content: "" !important; }

.topics {
  margin-bottom: 1.5rem; }
  .topics ul {
    padding: 0;
    list-style: none;
    margin: 0; }
    .topics ul.submenu {
      width: 100%;
      border: none;
      padding: 1rem 0;
      position: relative;
      top: 0 !important;
      box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.15); }
  .topics li {
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0; }
    .topics li:last-child {
      margin-bottom: 0; }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden; }
  .video-container iframe, .video-container object, .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-container #thevideo {
    display: none; }

.skip-to-content {
  position: fixed !important;
  width: auto;
  height: auto;
  top: 0;
  left: 1rem;
  padding: .5em 1em;
  z-index: 999999;
  border-radius: 5px;
  background-color: #F3F3F4;
  transform: translateY(-100%);
  transition: top 150ms ease, transform 150ms ease; }
  .skip-to-content, .skip-to-content:hover, .skip-to-content:focus, .skip-to-content:visited {
    color: #DADADA; }
  .skip-to-content:focus {
    position: fixed !important;
    top: 1rem;
    transform: translateY(0); }

body {
  background-color: #FAFAFA; }

#content {
  position: relative;
  outline: 0;
  padding-top: 6.25rem; }
  @media screen and (max-width: 63.9375em) {
    #content {
      padding-top: 0; } }
  #content ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) {
    list-style: none;
    margin-top: 0.5rem; }
    #content ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title)::before {
      content: "\2022";
      color: #00D1AF;
      font-weight: bold;
      display: inline-block;
      width: 0.8rem;
      margin-left: -0.5rem;
      vertical-align: 7%; }
  #content .accent-background ul:not(.accordion) li::before {
    color: #414042 !important; }

body {
  color: #414042; }

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin-bottom: 2rem; }

.mortgage-padding {
  padding: 0 !important; }

.hamburger {
  position: relative;
  display: inline-block;
  width: 1.875rem;
  height: 0.3125rem;
  padding: 0 !important;
  border-left: 0.65625rem solid transparent;
  border-top: 0.625rem solid transparent;
  border-bottom: 0.625rem solid transparent;
  outline: 0;
  background-clip: content-box;
  box-sizing: content-box;
  text-indent: -99999px;
  background-color: #FFFFFF;
  transition: background-color 150ms ease; }
  .hamburger:before, .hamburger:after {
    position: absolute;
    width: 2.53125rem;
    height: 0.3125rem;
    top: 0;
    right: 0;
    display: block;
    background-color: #FFFFFF;
    content: "";
    transition: margin-top 145ms ease 145ms, transform 145ms ease, background-color 145ms ease 145ms; }
  .hamburger:before {
    margin-top: -0.625rem; }
  .hamburger:after {
    margin-top: 0.625rem; }
  .hamburger.js-open, .js-navigation-open .hamburger {
    background-color: transparent; }
    .hamburger.js-open:before, .hamburger.js-open:after, .js-navigation-open .hamburger:before, .js-navigation-open .hamburger:after {
      margin-top: 0;
      background-color: #FFFFFF;
      transition: margin-top 145ms ease, transform 145ms ease 145ms, background-color 145ms ease; }
    .hamburger.js-open:before, .js-navigation-open .hamburger:before {
      transform: rotate(-45deg); }
    .hamburger.js-open:after, .js-navigation-open .hamburger:after {
      transform: rotate(45deg); }

@media screen and (max-width: 47.9375em) {
  .js-navigation-open {
    overflow: hidden; } }

.button {
  position: relative;
  padding: .75em 1.5em;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: .025em;
  transition: all 125ms ease-out;
  text-decoration: none;
  line-height: 1.4; }
  .button.accent {
    background-color: #00D1AF; }
  .button.white {
    background-color: #FFFFFF; }
  .button.carbon {
    background-color: #414042; }
  .button.black {
    background-color: #000000; }
  .button.lightest-gray {
    background-color: #FAFAFA; }
  .button.very-light-gray {
    background-color: #F3F3F4; }
  .button.light-gray {
    background-color: #E6E7E8; }
  .button.gray {
    background-color: #DADADA; }
  .button.medium-gray {
    background-color: #C1C1C1; }
  .button.dark-gray {
    background-color: #A8A8A8; }
  .button.darkest-gray {
    background-color: #545558; }
  .button.teal {
    background-color: #00D1AF; }
  .button.transparent {
    background-color: transparent; }
  .button + .button {
    margin-left: 0; }
    @media print, screen and (min-width: 40em) {
      .button + .button {
        margin-left: 1rem; } }
  .button.hollow {
    border-width: 0.25rem;
    font-weight: bold; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow.accent {
      border-color: #00D1AF;
      color: #00D1AF; }
    .button.hollow.white {
      border-color: #FFFFFF;
      color: #FFFFFF; }
    .button.hollow.carbon {
      border-color: #414042;
      color: #414042; }
    .button.hollow.black {
      border-color: #000000;
      color: #000000; }
    .button.hollow.lightest-gray {
      border-color: #FAFAFA;
      color: #FAFAFA; }
    .button.hollow.very-light-gray {
      border-color: #F3F3F4;
      color: #F3F3F4; }
    .button.hollow.light-gray {
      border-color: #E6E7E8;
      color: #E6E7E8; }
    .button.hollow.gray {
      border-color: #DADADA;
      color: #DADADA; }
    .button.hollow.medium-gray {
      border-color: #C1C1C1;
      color: #C1C1C1; }
    .button.hollow.dark-gray {
      border-color: #A8A8A8;
      color: #A8A8A8; }
    .button.hollow.darkest-gray {
      border-color: #545558;
      color: #545558; }
    .button.hollow.teal {
      border-color: #00D1AF;
      color: #00D1AF; }
    .button.hollow.transparent {
      border-color: transparent;
      color: transparent; }

.chevron {
  margin: 0 0 0 .35em;
  display: inline-block; }
  .chevron::before {
    width: .6em;
    height: .6em;
    display: inline-block;
    border-style: solid;
    border-width: .15em .15em 0 0;
    color: #DADADA;
    transform: rotate(45deg);
    transition: border-color 100ms ease;
    content: ""; }
  .chevron::before {
    border-color: #DADADA; }
  .chevron.accent::before {
    border-color: #00D1AF; }
  .chevron.white::before {
    border-color: #FFFFFF; }
  .chevron.carbon::before {
    border-color: #414042; }
  .chevron.black::before {
    border-color: #000000; }
  .chevron.lightest-gray::before {
    border-color: #FAFAFA; }
  .chevron.very-light-gray::before {
    border-color: #F3F3F4; }
  .chevron.light-gray::before {
    border-color: #E6E7E8; }
  .chevron.gray::before {
    border-color: #DADADA; }
  .chevron.medium-gray::before {
    border-color: #C1C1C1; }
  .chevron.dark-gray::before {
    border-color: #A8A8A8; }
  .chevron.darkest-gray::before {
    border-color: #545558; }
  .chevron.teal::before {
    border-color: #00D1AF; }
  .chevron.transparent::before {
    border-color: transparent; }

.inline-svgs {
  position: fixed;
  left: -99999999px; }

.panel {
  position: relative;
  padding: 2rem 4rem 4rem; }
  .panel > * {
    position: relative; }
  @media screen and (max-width: 47.9375em) {
    .panel {
      padding: 2rem; } }
  .panel .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain; }
    .panel .background-image.background-pos-left {
      background-position: left top; }
    .panel .background-image.background-pos-right {
      background-position: right top; }

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -500%;
  display: block;
  overflow: auto;
  opacity: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.25);
  transition: opacity 250ms ease-out, left 0ms linear 250ms; }
  .modal.js-show {
    left: 0;
    opacity: 1;
    transition: opacity 250ms ease-out; }
  .modal .modal-window {
    position: relative;
    width: 46.875rem;
    max-width: 95%;
    top: 15%;
    margin: 0 auto 3.125rem;
    padding: 1.5rem;
    background-color: #FFFFFF;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.25); }
  .modal video {
    margin-bottom: 1.5rem;
    max-width: 100%;
    display: block; }
    .modal video:last-child, .modal video:nth-last-child(2) {
      margin-bottom: 0; }
  .modal .close {
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: -1rem;
    right: -1rem;
    display: block;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    text-indent: -999999px;
    color: #FFFFFF;
    transform-origin: center center;
    transform: scale(0.9);
    transition: transform 175ms ease-out;
    background-color: #A8A8A8; }
    .modal .close:hover, .modal .close:focus {
      transform: scale(1); }
    .modal .close::before, .modal .close::after {
      position: absolute;
      width: 60%;
      height: 3px;
      top: 50%;
      left: 50%;
      margin-top: -2px;
      border-radius: 5px;
      display: block;
      content: "";
      transform-origin: center center;
      background-color: #FFFFFF; }
    .modal .close::before {
      transform: translateX(-50%) rotate(45deg); }
    .modal .close::after {
      transform: translateX(-50%) rotate(-45deg); }

.trainstops {
  position: relative;
  overflow: hidden; }
  .trainstops .trainstop {
    opacity: 0;
    transition: opacity 450ms ease-out; }
    .trainstops .trainstop .trainstop-content {
      transform-origin: left center;
      transition: transform 450ms ease-out; }
    .trainstops .trainstop ~ .trainstop .trainstop-content {
      margin-top: -17.5rem; }
    .trainstops .trainstop.js-active {
      opacity: 1; }
    .trainstops .trainstop.js-previous .trainstop-content {
      transform: translateY(-17.5rem) rotate(-25deg); }
    .trainstops .trainstop.js-upcoming .trainstop-content {
      transform: translateY(17.5rem) rotate(25deg); }
  .trainstops .trainstop-navigation {
    position: absolute;
    width: 0.1875rem;
    bottom: 100%;
    right: 50%;
    margin-right: calc(-40rem + 3rem);
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    transform: translateY(100%); }
    @media screen and (max-width: 80em) {
      .trainstops .trainstop-navigation {
        right: 3rem;
        margin-right: 0; } }
    .trainstops .trainstop-navigation.js-fixed {
      position: fixed; }
    .trainstops .trainstop-navigation.js-bottom, .trainstops .trainstop-navigation.js-fixed {
      bottom: 0;
      transform: translateY(0); }
    .trainstops .trainstop-navigation a {
      position: relative;
      width: 100%;
      height: 2rem;
      margin: .25rem 0;
      text-indent: -99999999px;
      outline: 0; }
      .trainstops .trainstop-navigation a:not(.accent-background) {
        background-color: #DADADA; }
      .trainstops .trainstop-navigation a::after {
        position: absolute;
        width: 500%;
        height: 100%;
        left: -200%;
        content: ""; }

.iframe-tabs nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem; }
  @media print, screen and (min-width: 48em) {
    .iframe-tabs nav {
      flex-wrap: nowrap; } }

.iframe-tabs .tab-title {
  position: relative;
  max-width: 32%;
  margin-right: .35em;
  margin-bottom: .5em;
  padding: 0.66em 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 175ms ease; }
  .iframe-tabs .tab-title:not(.carbon-background) {
    background-color: #00D1AF; }
  @media screen and (max-width: 47.9375em) {
    .iframe-tabs .tab-title {
      width: 100%;
      max-width: none;
      padding: .33em; } }
  .iframe-tabs .tab-title::after {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 100%;
    left: 50%;
    margin-left: -.5em;
    margin-top: -.5em;
    opacity: 0;
    content: "";
    transform: rotate(45deg);
    background-color: inherit;
    transition: opacity 150ms ease; }
    @media screen and (max-width: 47.9375em) {
      .iframe-tabs .tab-title::after {
        display: none; } }
  .iframe-tabs .tab-title:hover::after, .iframe-tabs .tab-title:focus::after, .iframe-tabs .tab-title.js-active::after {
    opacity: 1; }

.iframe-tabs .tab-content {
  height: 0;
  overflow: hidden; }
  .iframe-tabs .tab-content.js-active {
    height: auto;
    overflow: visible; }

.accordion {
  margin: 0;
  background: transparent; }
  .accordion.white-background {
    background: #fefefe; }
  .accordion .accordion-item {
    width: 100%;
    padding: 0;
    margin: 0.5rem auto; }
    .accordion .accordion-item.is-active .accordion-title {
      background-color: inherit;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }
    .accordion .accordion-item .accordion-title {
      border: none;
      font-size: inherit;
      color: inherit;
      text-decoration: none;
      padding: 1.25rem 2.25rem 1.25rem 1rem; }
      @media print, screen and (min-width: 40em) {
        .accordion .accordion-item .accordion-title {
          padding: 1.25rem 2rem; } }
      .accordion .accordion-item .accordion-title:hover, .accordion .accordion-item .accordion-title:focus {
        background-color: inherit;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }
      .accordion .accordion-item .accordion-title.white-background:hover, .accordion .accordion-item .accordion-title.white-background:focus, .accordion .accordion-item .accordion-title.white-background:visited, .accordion .accordion-item .accordion-title.white-background:active {
        background: #fefefe; }
      .accordion .accordion-item .accordion-title:before {
        margin-top: -1rem;
        font-size: 2rem;
        line-height: 0.75;
        color: #00D1AF;
        right: 1rem; }
        @media screen and (min-width: 64em) {
          .accordion .accordion-item .accordion-title:before {
            right: 2rem; } }
    .accordion .accordion-item .accordion-content {
      border: none;
      background-color: transparent;
      color: inherit;
      padding: 1rem; }
      @media print, screen and (min-width: 40em) {
        .accordion .accordion-item .accordion-content {
          padding: 1.8rem; } }
      .accordion .accordion-item .accordion-content h5 {
        margin-bottom: 2rem; }
      .accordion .accordion-item .accordion-content p {
        margin-bottom: 1.5rem; }
        .accordion .accordion-item .accordion-content p:last-child {
          margin-bottom: 0; }
    .accordion .accordion-item.ellipsis {
      background: transparent; }
      .accordion .accordion-item.ellipsis.is-active .accordion-title {
        background: transparent;
        text-decoration: underline; }
      .accordion .accordion-item.ellipsis .accordion-title {
        padding: 1.25rem 0 1.25rem 1.8rem;
        display: flex;
        color: #00D1AF; }
        .accordion .accordion-item.ellipsis .accordion-title:hover, .accordion .accordion-item.ellipsis .accordion-title:focus {
          background: transparent; }
        .accordion .accordion-item.ellipsis .accordion-title:hover {
          text-decoration: underline; }
        .accordion .accordion-item.ellipsis .accordion-title:before {
          right: auto;
          left: 0; }
        .accordion .accordion-item.ellipsis .accordion-title .dot {
          border-bottom: 1px dashed #A8A8A8;
          content: '';
          flex: 1;
          height: 0.6em;
          margin-left: 1rem; }
  .accordion.rate-accordion .accordion-item .accordion-title {
    padding-right: 3rem;
    padding-left: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold; }
    @media print, screen and (min-width: 40em) {
      .accordion.rate-accordion .accordion-item .accordion-title {
        font-size: 2rem;
        padding-left: 0.75rem; } }
    .accordion.rate-accordion .accordion-item .accordion-title::before {
      font-size: 3rem;
      margin-top: -1.5rem;
      transition: all 0.2s ease; }
  .accordion.rate-accordion .accordion-item .accordion-content {
    margin-top: 1rem; }
  .accordion.rate-accordion .accordion-item.is-active .accordion-title {
    background-image: none; }
    .accordion.rate-accordion .accordion-item.is-active .accordion-title::before {
      content: '+';
      transform: rotate(45deg);
      right: 0.75rem;
      top: 53.3%; }
    .accordion.rate-accordion .accordion-item.is-active .accordion-title:hover {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)); }

.article-social-container {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% + 6.25rem);
  padding-top: 6.25rem;
  margin-top: -6.25rem; }
  @media screen and (max-width: 39.9375em) {
    .article-social-container {
      position: relative;
      width: 100%;
      padding-top: 0;
      margin-top: 0; } }

.article-social-box {
  position: absolute;
  width: 4.375rem;
  top: 6.25rem;
  padding: 4rem 0 1rem; }
  .article-social-box.js-stuck {
    position: fixed; }
  .article-social-box.js-bottom {
    top: auto;
    bottom: 0; }
  @media print, screen and (min-width: 48em) {
    .article-social-box {
      margin-left: 2rem; } }
  @media screen and (max-width: 47.9375em) {
    .article-social-box {
      margin-left: 1.75rem; } }
  @media screen and (max-width: 39.9375em) {
    .article-social-box {
      position: relative;
      width: 100%;
      top: 0;
      margin: 0; } }
  .article-social-box .cell {
    margin-bottom: 0.5rem;
    max-height: 40px; }
    @media print, screen and (min-width: 40em) {
      .article-social-box .cell {
        margin-left: 0;
        margin-right: 0; }
        .article-social-box .cell:last-child {
          margin-bottom: 0; } }
  .article-social-box a svg {
    max-width: 40px;
    max-height: 40px; }
  .article-social-box a.heart:hover svg {
    fill: #ec4343; }
  .article-social-box a.heart.active svg {
    fill: #ec4343; }

[data-module="lazyLoad"] [data-module-role="loadMore"] {
  margin: -1rem auto 1rem;
  order: 2; }

[data-module="lazyLoad"] .js-hidden {
  display: none; }

.tabs {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none; }
  .tabs li {
    margin-right: 1rem;
    padding: 0; }

.tabs-panel {
  opacity: 1;
  transition: opacity 250ms ease-out 100ms, transform 250ms ease-out 100ms; }
  .tabs-panel[aria-hidden="true"] {
    position: fixed;
    height: 0;
    left: -500%;
    opacity: 0;
    overflow: hidden;
    transition: none;
    transform: translateY(0.5rem); }

.sorting {
  border-bottom: 1px solid #A8A8A8;
  margin: 0 0 2rem; }
  .sorting .tabs-title {
    border-right: 1px solid #A8A8A8; }
    .sorting .tabs-title:last-of-type {
      border-right: 0; }
    .sorting .tabs-title.is-active a::before {
      border: 0.4em solid #00D1AF; }
  .sorting a {
    position: relative;
    padding: 0 3em .5em 0;
    color: #000000;
    display: inline-block;
    outline: 0; }
    .sorting a::before {
      position: absolute;
      top: 50%;
      width: 1.2em;
      height: 1.2em;
      right: 1em;
      margin-top: -.25em;
      display: block;
      border: 0.6em solid #E6E7E8;
      border-radius: 50%;
      box-shadow: 0 0 1px #E6E7E8;
      transform: translateY(-50%);
      transition: border 200ms ease;
      content: ''; }

.motus-tooltip {
  margin-left: 0.25rem;
  margin-top: -3px;
  font-size: 0.85rem;
  line-height: 1;
  border-radius: 1rem;
  padding: 0 0.2rem;
  text-decoration: none;
  margin-bottom: 0; }

.prelaunch-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 6px solid;
  color: #414042;
  text-align: center; }
  .prelaunch-modal.reveal {
    overflow: unset; }
  .prelaunch-modal h1 {
    font-size: 3.125rem;
    margin-bottom: .5rem; }
  .prelaunch-modal p {
    margin: 0; }
  .prelaunch-modal.errorModal {
    border: 6px solid #C71F25;
    background-color: white; }
    .prelaunch-modal.errorModal .modal-button {
      background-color: #414042;
      color: white; }
  .prelaunch-modal .modal-button {
    margin-top: 1rem;
    padding: .75rem 4.5rem;
    background-color: white; }
  .prelaunch-modal .close {
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: -0.6rem;
    right: -0.6rem;
    display: block;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    text-indent: -999999px;
    color: #FFFFFF;
    transform-origin: center center;
    transform: scale(0.9);
    transition: transform 175ms ease-out;
    background-color: #A8A8A8; }
    @media print, screen and (min-width: 40em) {
      .prelaunch-modal .close {
        top: -1rem;
        right: -1rem; } }
    .prelaunch-modal .close:hover, .prelaunch-modal .close:focus {
      transform: scale(1); }
    .prelaunch-modal .close::before, .prelaunch-modal .close::after {
      position: absolute;
      width: 60%;
      height: 3px;
      top: 50%;
      left: 50%;
      margin-top: -2px;
      border-radius: 5px;
      display: block;
      content: "";
      transform-origin: center center;
      background-color: #FFFFFF; }
    .prelaunch-modal .close::before {
      transform: translateX(-50%) rotate(45deg); }
    .prelaunch-modal .close::after {
      transform: translateX(-50%) rotate(-45deg); }

@media screen and (max-width: 39.9375em) {
  .full-bleed-container .grid-container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; } }

.hero-banner {
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  overflow: hidden; }
  @media screen and (min-width: 64em) {
    .hero-banner {
      margin-top: 1.5rem; } }
  .hero-banner .panel {
    position: relative;
    padding: 4rem;
    align-items: center; }
    @media screen and (min-width: 64em) {
      .hero-banner .panel.short {
        min-height: 10rem; }
      .hero-banner .panel:not(.short) {
        min-height: 30rem; } }
    @media screen and (max-width: 63.9375em) {
      .hero-banner .panel {
        padding-top: 45vw;
        padding-left: 1.8rem;
        padding-right: 1.8rem; } }
    @media screen and (min-width: 64em) {
      .hero-banner .panel .background-image {
        background-size: cover; } }
    .hero-banner .panel .cell *:first-child {
      margin-top: 0; }
    .hero-banner .panel .cell *:last-child {
      margin-bottom: 0; }
    .hero-banner .panel .cell .button {
      margin-bottom: 1rem; }
  .hero-banner h1 {
    font-weight: 900;
    font-size: 5rem;
    line-height: 1.1;
    letter-spacing: -0.02em; }
    .hero-banner h1.accessibility {
      font-size: 4.38rem;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold; }
    @media screen and (max-width: 63.9375em) {
      .hero-banner h1 {
        font-size: 4.375rem; } }
    @media screen and (max-width: 39.9375em) {
      .hero-banner h1 {
        font-size: 3.125rem; } }
    @media screen and (max-width: 374px) {
      .hero-banner h1 {
        font-size: 2.5rem; } }
    .hero-banner h1.hero-wtb-title {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold; }
  .hero-banner p {
    width: 100%;
    margin: 1.5em 0;
    font-size: 1.5625rem;
    line-height: 1.4; }
    @media screen and (max-width: 63.9375em) {
      .hero-banner p {
        font-size: 1.375rem; } }
    @media screen and (max-width: 39.9375em) {
      .hero-banner p {
        font-size: 1.25rem; } }
    .hero-banner p.hero-wtb-description {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 1.58rem; }
  .hero-banner a.button {
    margin-right: 0.5rem; }

.hero-banner .panel:not(.short) {
  padding-left: 4rem;
  padding-right: 4rem; }

.global-header .navigation {
  max-width: 85.375rem; }

.motus-bank-container {
  max-width: 85.375rem;
  margin: auto; }

.motus-bank-row {
  text-align: left;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

#th-motusbank-mortgage-content {
  max-height: 44.625rem;
  min-height: 44.625rem; }

.promo p {
  font-size: 1.25rem; }

#header-search {
  max-width: 13.625rem;
  width: 13.625rem; }

.promo.comparison h3 {
  font-size: 2.8125rem; }

.bg-hero-content {
  margin-right: 2.9rem; }

/* Small only */
@media screen and (max-width: 39.9375em) {
  .bg-hero-content {
    margin-right: 0; }
  .hero-banner .panel:not(.short) {
    padding-left: 2rem;
    padding-right: 2rem; }
  .hero-banner h1 {
    font-size: 2.8125rem; }
  #th-motusbank-mortgage-content {
    max-height: 100%;
    min-height: 100%; }
  .grid-x.promo.with-label {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 18rem; }
  .promo p {
    line-height: 1.1;
    margin-bottom: 2rem; }
  .promo h3 {
    font-size: 1.875rem; }
  .promo h3 br {
    display: none; }
  .small-12.xlarge-7.xxlarge-8.cell.promo-content.padding-bottom-good-to-know h4.heading {
    font-size: 1.875rem;
    padding-left: 2rem; }
  .promo .promo-content > :last-child {
    margin-left: 2rem; }
  #bg-good-to-know .grid-x.promo.with-label {
    padding-bottom: 1rem; }
  .page-footer .pre-footer .small-12.large-9.xlarge-10.cell.motus-bank-row {
    padding-left: 0;
    padding-right: 0; }
  .page-footer .pre-footer {
    padding-left: 0;
    padding-right: 0; }
  #rates-content .promo.no-bg {
    padding-left: 0;
    padding-right: 0; }
  #rates-content .promo .promo-content > :last-child {
    margin-left: 0rem;
    margin-right: 0; }
  #rates-content .grid-container {
    margin-left: 2rem;
    margin-right: 2rem;
    padding-left: 0;
    padding-right: 0; }
  #borrowing-content .bg-mobile-banner-borrowing {
    background-position: bottom;
    margin-top: 19rem;
    padding-top: 27rem; }
  #borrowing-content .panel {
    margin-bottom: 24rem;
    padding-top: 0 !important; }
  #borrowing-content h1 {
    font-size: 1.5625rem;
    padding-top: 2rem; }
  #reasons-content img.right.bottom.img-reasons {
    width: 377px;
    left: -8%; }
  #reasons-content .grid-x.promo.with-label {
    padding-top: 0rem; }
  #reasons-content .small-12.medium-6.cell.promo-content.espace-text {
    margin-top: 13rem; }
  #reasons-content .promo .promo-content > :last-child {
    margin-right: 0; }
  #reasons-content {
    height: 42rem; }
  #borrowing-basics-content .grid-x.promo.with-label {
    padding-left: 2rem;
    padding-right: 2rem; }
  #borrowing-basics-content h3.title-borrowing-content {
    font-size: 1.875rem !important; }
  #borrowing-basics-content h3.title-borrowing-content br {
    display: none; }
  #borrowing-basics-content .promo .promo-content > :last-child {
    margin-left: 0rem; }
  #borrowing-basics-content .grid-x.promo.with-label {
    padding-bottom: 0; }
  #Secured-unsecured .promo .promo-content > :last-child {
    margin-left: 0; }
  #Secured-unsecured .title-Secured-unsecured-content {
    font-size: 1.875rem;
    padding-left: 2rem; }
  #Secured-unsecured .promo:last-child {
    padding-bottom: 0rem; }
  #Secured-unsecured .espace-text {
    height: auto; }
  #calculator-your-payments-content h3, #calculator-your-payments-content .promo p {
    padding-left: 2rem;
    padding-right: 2rem; }
  #iframe-wpu4a77sp {
    margin-left: 0; }
  #calculator-your-payments-content .grid-x.promo.with-label {
    padding-bottom: 2rem; }
  #protecting-content h3 {
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 1.875rem; }
  #protecting-content .promo p {
    padding-left: 2rem;
    padding-right: 2rem; }
  #protecting-content .promo p br {
    display: none; }
  #protecting-content .grid-x.promo.with-label {
    padding-bottom: 1rem; }
  #bg-good-to-know-borrowing-mobile {
    background-size: auto;
    background-position: bottom; }
  #good-to-know-borrowing .padding-bottom-good-to-know {
    padding-bottom: 1rem; }
  #borrowing-reasons .espace-text p {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 0 !important;
    padding-right: 0 !important; } }

/* Medium and up */
/* Medium only */
/* Large and up */
/* Large only */
#borrowing-basics-content h3, #Secured-unsecured h3 {
  font-size: 2.8125rem; }

#Secured-unsecured {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.br-calc {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  min-width: 97%; }

.bg-disktop-good-to-know-investing {
  left: 0 !important; }

/*20190227*/
/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  #reasons-content {
    height: auto; } }

#section-reasons .grid-x.promo.with-label {
  padding-bottom: 0;
  padding-top: 0; }

#section-reasons p {
  padding: 2rem;
  padding-left: 3rem;
  padding-right: 2rem; }

div#borrowing-reasons {
  padding-bottom: 0;
  padding-top: 0; }

div#reasons-content {
  padding-bottom: 0rem; }

#borrowing-reasons .espace-text p {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem; }

div.legal-content {
  padding-left: 2rem;
  padding-right: 2rem; }

#tiyb54-accordion {
  padding-left: 0;
  padding-right: 0; }

@media screen and (max-width: 39.9375em) {
  #first-section-contact-us .promo .promo-content > :last-child {
    margin-left: 0rem; }
  #how-to-reach-us-content .grid-x.promo.with-label {
    padding-left: 2rem;
    padding-right: 2rem; }
  #how-to-reach-us-content .grid-x.promo.with-label {
    padding-bottom: 2rem; }
  #question-module-content .grid-x.promo.with-label {
    padding-left: 2rem;
    padding-right: 2rem; }
  #question-module-content .grid-x.promo.with-label {
    padding-bottom: 2rem; }
  div.legal-content {
    padding-left: 1rem;
    padding-right: 1rem; }
  #section-tow-ourStory .promo-content.espace-text {
    padding-left: 2rem;
    padding-right: 2rem; }
  #section-tow-ourStory .promo .promo-content > :last-child {
    margin-left: 0rem; }
  #section-tow-ourStory .grid-x.promo.with-label {
    padding-bottom: 42rem; }
  #products-and-rates .grid-x.promo.with-label {
    padding-left: 2rem;
    padding-right: 2rem; }
  #products-and-rates .promo .promo-content > :last-child {
    margin-left: 0rem; }
  #in-conclusion-bg {
    padding-bottom: 0rem !important; }
  #in-conclusion-content .grid-x.promo.with-label {
    padding-bottom: 0rem;
    background-attachment: fixed; }
  #account-features-content .small-12.medium-7.cell.promo-content.espace-text {
    padding-left: 2rem;
    padding-right: 2rem; }
  #account-features-content .espace-text {
    height: auto !important; }
  #chequing-hero-content .motus-bank-row {
    padding-left: 0;
    padding-right: 0; }
  #chequing-hero-content .grid-x.white-background.panel.panel-hero {
    padding-left: 3.4rem;
    padding-right: 3.4rem; }
  #account-features-content .grid-x.promo.with-label {
    padding-bottom: 0; }
  #motusbank-debit-card .promo .promo-content > :last-child {
    margin-left: 0; }
  #Our-ATM-network .promo .promo-content > :last-child {
    margin-left: 0; }
  #Good-to-Know-chequing .grid-x.promo.blog.with-label {
    padding-bottom: 0; } }

#in-conclusion-bg {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: inherit;
  padding-bottom: 11rem; }

#bg-Conclusion h5 {
  font-size: 1.5625rem; }

#img-reasons-fafm {
  margin-left: -7rem;
  padding-top: 0.5rem; }

#account-features-content .grid-x.promo.with-label {
  padding-top: 0; }

.account-features-content {
  padding-bottom: 3rem !important; }

.legal-chequing-content {
  padding-left: 3.5rem;
  padding-right: 3.5rem; }

/*** Starts Category Blog Pages ***/
@media screen and (min-width: 64em) {
  #category_save {
    padding: 0 5rem; }
  #acce_left {
    padding: 0px; } }

.blog_description {
  padding: 4rem; }

.blog_category {
  padding-left: 3rem;
  padding-right: 3rem; }

/*** Ends Category Blog Pages ***/
/*** Starts Page 404 ***/
.mod_description h2,
.mod_description h5,
.mod_description h6,
.mod_description .cell {
  font-family: 'Segoe UI' !important;
  text-align: center;
  margin-bottom: 0; }

.mod_description h2 {
  color: #00d1af; }

.mod_description .margin_buttons {
  margin-top: 2rem; }

div#Modal1 {
  margin-top: 10rem;
  min-height: auto;
  height: auto;
  border: 0.5rem solid; }

.margin_buttons a {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  width: 138px; }

.hero a {
  font-family: 'Segoe UI';
  font-size: 1.1rem;
  color: #414042; }

/*** Ends Page 404 ***/
/**28/02/2019**/
.hero-banner h1 {
  font-size: 5rem; }

#privacy-content .hero-banner .panel:not(.short) {
  padding-top: 3rem;
  min-height: auto !important;
  padding-bottom: 4rem; }

#privacy-content .hero-banner {
  margin-bottom: 0; }

#privacy-content h1 {
  margin-top: 0.8rem; }

#page-404-content a {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem; }

#Modal1 .close-button, #Modal1.close-button.medium {
  right: 0.5rem;
  top: 0rem;
  font-size: 2em;
  line-height: 1; }

#Modal1 .close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }

#Modal1 .close-button span {
  font-size: 2.2rem;
  font-weight: bold;
  color: #00D1AF; }

/*** Starts CDIC Page ***/
#cdic_img {
  bottom: 12rem;
  left: -8rem; }

@media screen and (max-width: 39.9375em) {
  #cdic_img_mob {
    bottom: 8rem;
    left: -4rem;
    top: 4rem; }
  .cdic_title {
    padding-top: 6rem;
    font-size: 3rem; }
  .desc_bottom {
    padding-bottom: 114px; } }

.wtb_list ol {
  font-family: 'Segoe UI'; }

/*** Ends CDIC Page ***/
#iframe-tabs a.tab_title::after {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 100%;
  left: 50%;
  margin-left: -.5em;
  margin-top: -.5em;
  opacity: 1;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: inherit;
  transition: opacity 150ms ease; }

#member-services {
  padding-left: 0;
  padding-right: 0; }

#member-services .motus-bank-row.padding-right-none {
  padding-right: 0; }

@media screen and (max-width: 39.9375em) {
  .blog_description {
    padding: 0.5rem; }
  #l_padding {
    margin-left: 25px; } }

/*** Starts Page Corporate Governance ***/
@media screen and (min-width: 64em) {
  .board_of_directors .accordion .accordion-item .accordion-title:before {
    right: 35.5rem; } }

/*** Ends Page Corporate Governance ***/
#acce_bottom {
  margin-left: 0; }

#acce_padding {
  padding-bottom: 0rem; }

/*issus 01/03/2019*/
#savings-content .bg-hero-content {
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0; }

.hero-banner h1.size-60 {
  font-size: 3.75rem; }

#saving-rates .rate-carousel .rate .cell .heading {
  display: inline; }

#saving-rates .rate-carousel .rate .cell:nth-child(2):after {
  margin-bottom: 2rem;
  border-bottom: 2px dashed #A8A8A8; }

#saving-rates .rate-carousel .rate .cell a.button {
  width: auto !important; }

#Savings-account-features .promo.comparison h3 {
  font-size: 3.125rem !important; }

#Savings-account-features .promo.comparison li {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  letter-spacing: 0.4px; }

#Savings-account-features .small-12.xlarge-8.xlarge-offset-4.cell.promo-content {
  padding-left: 3rem; }

#Savings-account-features ul {
  margin-left: 0.8rem; }

#Savings-account-features ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title)::before {
  margin-right: 4rem;
  color: #414042; }

#registered-plans h3 {
  font-size: 2.5rem !important;
  padding-right: 12rem;
  padding-top: 1rem;
  padding-bottom: 1rem; }

#registered-plans h5 {
  font-size: 1.875rem; }

.motus-bank-row-footer {
  padding-left: 0.4rem;
  padding-right: 0.4rem; }

h4.white-text.find-an-atm {
  padding-right: 0.5rem; }

#savings-content .mortgage-banner {
  padding-top: 2rem;
  padding-bottom: 2rem; }

@media screen and (max-width: 39.9375em) {
  .motus-bank-row-footer {
    padding-left: 1.6rem;
    padding-right: 0rem; } }

/*Checkboxsss*/
.checkbox.inline:last-child,
.radio.inline:last-child {
  margin-right: 0; }

.checkbox label,
.radio label {
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1; }

.checkbox input[type="checkbox"],
.radio input[type="radio"] {
  /*position: absolute;*/
  top: 0;
  left: 0;
  visibility: hidden; }

.checkbox .custom-checkbox,
.radio .custom-radio {
  display: inline-block;
  vertical-align: top;
  margin: 0 6px 0 0;
  width: 18px;
  height: 18px;
  border: 1px solid #1583cc;
  position: relative; }

.checkbox .custom-checkbox {
  background: #1583cc;
  box-shadow: inset 0 0 0 12px #fff;
  top: -2px; }

.checkbox .icon-check {
  width: 20px;
  height: 20px;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0);
  transition: all 180ms ease; }

.icon-check:before {
  position: absolute;
  content: '\2713';
  width: 12px;
  font-size: 12.66667px;
  text-align: center;
  line-height: 16px;
  color: #fff;
  font-weight: bold; }

.checkbox input[type="checkbox"]:checked + .custom-checkbox {
  border-color: #2095F0;
  box-shadow: none; }

.checkbox input[type="checkbox"]:checked + .custom-checkbox .icon-check {
  transform: scale(1); }

.checkbox .success,
.radio .success {
  border: 3px solid #00D1AF;
  margin-top: 0.5rem; }

.checkbox input[type="checkbox"]:checked + .success,
.radio input[type="radio"]:checked + .success {
  background: #00D1AF;
  border: 2px solid #00D1AF; }

input#checkbox3 {
  margin-right: -0.9rem;
  margin-bottom: 2rem;
  margin-top: 0.5rem; }

.checkbox.bg-check {
  height: 35px; }

.small-12.medium-4.large-4.xlarge-4.cell.hero-banner.very-light-bachground.btn-not-robot {
  margin-top: -4.6rem;
  margin-left: 4.5rem; }

@media screen and (max-width: 39.9375em) {
  .promo-content.espace-txt {
    left: 0rem; } }

@media screen and (min-width: 40em) {
  .promo-content.espace-txt {
    left: 11rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .promo-content.espace-txt {
    left: 0rem; } }

@media screen and (min-width: 64em) {
  .advice-desk {
    margin-left: 25rem; } }

@media screen and (min-width: 64em) {
  .Advice-desk-2 {
    margin-left: -19rem !important; } }

@media screen and (max-width: 39.9375em) {
  div.advice-mobile {
    height: 370px !important; } }

@media screen and (max-width: 39.9375em) {
  .grid-x.panel.fa-an-fa {
    margin-bottom: 31rem;
    margin-top: -10rem; }
  .hide-for-xlarge.background-image.background-pos-right.fa-an-fam {
    margin-top: 24rem;
    padding-top: 29rem; } }

.concerns.separateur {
  padding-top: 0rem;
  margin-top: -3rem; }

.separateur {
  padding-top: 0rem;
  margin-top: -1rem; }

.bg-check {
  background-color: #f3f3f4; }

.btn-apply {
  margin-top: 1rem; }

.promo-content.accessibility ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title)::before,
.promo-content.wtb_list ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title)::before {
  color: #545558 !important; }

.promo-content.accessibility ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title),
.promo-content.wtb_list ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title) {
  font-family: 'Segoe UI' !important; }

.side-by-side {
  margin: 0;
  padding: 0;
  align-items: center; }
  .side-by-side > div {
    position: relative; }
  .side-by-side .promo {
    margin: 0; }
  .side-by-side img {
    width: 100%; }
  .side-by-side .background-image {
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 250px;
    z-index: 0; }
    @media print, screen and (min-width: 48em) {
      .side-by-side .background-image {
        min-height: 450px; } }
  .side-by-side .promo-image {
    align-self: stretch; }

#online-banking {
  left: -5rem; }

.promo {
  padding: 3rem 1.8rem 240px;
  margin: 0;
  background-repeat: no-repeat;
  position: relative; }
  @media print, screen and (min-width: 40em) {
    .promo {
      padding: 3rem 1.8rem 290px; } }
  @media print, screen and (min-width: 48em) {
    .promo {
      padding: 3rem 1.8rem 340px; } }
  @media screen and (min-width: 64em) {
    .promo {
      padding: 4rem; } }
  .promo h3 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .promo h3 {
        font-size: 2.5rem; } }
  .promo h5 {
    margin-bottom: 0.5rem; }
  .promo p {
    line-height: 1.8;
    margin-bottom: 2rem; }
  .promo.no-bg {
    background-color: transparent; }
    @media screen and (max-width: 63.9375em) {
      .promo.no-bg {
        padding: 3rem 1.8rem; } }
  .promo.no-bg-image {
    background-image: none; }
    @media screen and (max-width: 63.9375em) {
      .promo.no-bg-image {
        padding: 3rem 1.8rem; } }
  .promo .background-image {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    height: 220px;
    z-index: 0; }
    @media print, screen and (min-width: 40em) {
      .promo .background-image {
        height: 270px; } }
    @media print, screen and (min-width: 48em) {
      .promo .background-image {
        height: 320px; } }
    @media screen and (min-width: 64em) {
      .promo .background-image {
        height: 100%; } }
  .promo .promo-content {
    z-index: 1;
    position: relative; }
    .promo .promo-content > :last-child {
      margin-bottom: 1rem; }
    .promo .promo-content ~ .promo-content {
      margin-top: 2rem; }
    .promo .promo-content.accessibility h2 {
      font-size: 2.21rem;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      padding-top: 1rem; }
    .promo .promo-content.accessibility h4 {
      font-size: 1.5rem;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      margin-top: 2rem; }
    .promo .promo-content.accessibility p {
      font-size: 0.96rem;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      margin-top: -2rem;
      margin-bottom: 2rem; }
    .promo .promo-content.accessibility.li {
      color: #414042 !important;
      font-size: 0.96rem;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
    .promo .promo-content.accessibility h6 {
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
    .promo .promo-content.accessibility p.complaint-step2 {
      width: 20rem; }
  .promo.blog h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .promo.blog h4 {
        font-size: 2rem; } }
  .promo.comparison li {
    padding: 0; }
  .promo.comparison h3 {
    font-size: 2.25rem; }
    @media screen and (max-width: 63.9375em) {
      .promo.comparison h3 {
        font-size: 1.5rem; } }
  .promo.comparison h5 {
    margin-bottom: 1rem; }
  .promo.comparison h6 {
    margin-bottom: 1rem; }
  .promo.mobile-cover {
    padding: 3rem 1.8rem; }
    @media screen and (min-width: 64em) {
      .promo.mobile-cover {
        padding: 4rem; } }
    .promo.mobile-cover .background-image {
      height: 100%; }

.hide-for-small-only.medium-1.reach_us {
  border-left: 1px dashed white;
  height: 10rem;
  margin: 0 auto;
  width: 1px; }

h3.wtb_title {
  font-size: 2.25rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold; }

p.wtb_description {
  font-size: 0.96rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }

span.white-text.wtb_title_section,
span.carbon-text.wtb_title_section {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold !important; }

a#p_lt_ctl03_pageplaceholder_p_lt_ctl00_Container3_Container3_Layout1_Layout1_Left_Button3_link {
  background-color: aliceblue !important;
  color: black !important; }

a#Code-of-ethics {
  background-color: #414042 !important;
  color: floralwhite !important; }

h4.carbon-text.wtb_h4 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }

@media screen and (max-width: 39.9375em) {
  .promo.mobile-cover.wtb_bottom {
    padding: 4rem 1.8rem 18rem; }
  .hero-banner h1.hero-wtb-title {
    font-size: 3rem !important; }
  #Ways-to-bank.panel {
    padding-top: 115vw; } }

@media screen and (max-width: 39.9375em) {
  .promo-content.espace-txt {
    left: 0rem; } }

@media screen and (min-width: 40em) {
  .promo-content.espace-txt {
    left: 11rem; } }

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .promo-content.espace-txt {
    left: 0rem; } }

@media screen and (min-width: 64em) {
  .advice-desk {
    margin-left: 25rem; } }

@media screen and (min-width: 64em) {
  .Advice-desk-2 {
    margin-left: -25rem; } }

@media screen and (max-width: 39.9375em) {
  div.advice-mobile {
    height: 370px !important; } }

@media screen and (max-width: 39.9375em) {
  .grid-x.panel.fa-an-fa {
    margin-bottom: 31rem;
    margin-top: -10rem; }
  .hide-for-xlarge.background-image.background-pos-right.fa-an-fam {
    margin-top: 24rem;
    padding-top: 29rem; } }

.legal {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  .legal .accordion {
    padding: 0;
    margin: 0rem; }
    .legal .accordion .accordion-item {
      margin: 0; }
      .legal .accordion .accordion-item .accordion-title {
        padding: 1.25rem 1rem 1.25rem 2.25rem; }
        @media print, screen and (min-width: 40em) {
          .legal .accordion .accordion-item .accordion-title {
            padding: 1.25rem 2rem; } }
        .legal .accordion .accordion-item .accordion-title:before {
          right: auto;
          left: 0.5rem; }
  .legal p {
    font-size: 0.75rem; }
    .legal p:last-child {
      margin-bottom: 0; }

.legal-text {
  margin-top: 1.5rem; }
  .legal-text p {
    font-size: 0.75rem;
    padding-bottom: 0; }

.page-footer {
  overflow: hidden;
  position: relative; }
  .page-footer p {
    color: #A8A8A8;
    font-size: 0.8rem;
    margin-bottom: 1rem; }
  .page-footer .padding-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #E6E7E8; }
  .page-footer .find-atm-module {
    padding-top: 2rem;
    padding-bottom: 2rem; }
    .page-footer .find-atm-module h1, .page-footer .find-atm-module h2, .page-footer .find-atm-module h3, .page-footer .find-atm-module h4, .page-footer .find-atm-module h5, .page-footer .find-atm-module h6 {
      margin-bottom: 0; }
  .page-footer .vertical_dotted_line {
    border-left: 1px dashed white;
    height: 40px;
    margin: 0 auto;
    width: 1px; }
  .page-footer .pre-footer {
    border-top: 1px solid #E6E7E8;
    border-bottom: 1px solid #E6E7E8;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    /*.font-sizer {
            margin-bottom: 3rem;
            p {
                margin: 0;
            }
            .font-choice{
                margin-left: 0.5rem;
                @include breakpoint(xlarge){
                    margin-left: 0;
                    padding: 0.5rem 0;
                }
                @include breakpoint(xxxlarge){
                    margin-left: 0.5rem;
                }
                a {
                    background-color: #E6E7E8;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    text-align: center;
                    margin: 0 0.25rem;
                    color: #00D1AF;
                    line-height: 1.7;
                    &:hover, &:focus{
                        background-color: #00D1AF;
                        color: color('white');
                    }
                    &.small-text{
                        font-size: 0.8rem;
                        line-height: 2.3;
                    }
                    &.large-text{
                        font-size: 1.3rem;
                        line-height: 1.1;
                    }
                }
            }
        }*/ }
    @media screen and (min-width: 64em) {
      .page-footer .pre-footer {
        padding-bottom: inherit; } }
    .page-footer .pre-footer h5 {
      text-transform: uppercase;
      font-size: 0.8rem;
      color: #545558;
      font-weight: bold;
      margin-bottom: 1rem;
      display: block;
      width: 100%; }
      @media print, screen and (min-width: 40em) {
        .page-footer .pre-footer h5 {
          font-size: 0.9rem; } }
      @media screen and (max-width: 340px) {
        .page-footer .pre-footer h5 {
          font-size: 0.7rem; } }
    .page-footer .pre-footer ul {
      list-style: none;
      margin: 0; }
      .page-footer .pre-footer ul li {
        margin-bottom: 1rem;
        padding: 0; }
        .page-footer .pre-footer ul li:last-child {
          margin-bottom: 0; }
        .page-footer .pre-footer ul li.margin-push {
          margin-bottom: 3rem; }
        .page-footer .pre-footer ul li a {
          color: #A8A8A8;
          font-size: 0.8rem;
          text-decoration: underline; }
          .page-footer .pre-footer ul li a:hover {
            color: #414042;
            text-decoration: none; }
    .page-footer .pre-footer .social-links-footer a {
      margin-right: 0.75rem;
      text-decoration: none; }
      @media screen and (min-width: 64em) and (max-width: 74.9375em) {
        .page-footer .pre-footer .social-links-footer a {
          margin-right: 0.10rem; } }
      .page-footer .pre-footer .social-links-footer a:last-child {
        margin-right: 0; }
      .page-footer .pre-footer .social-links-footer a:hover svg {
        fill: #414042; }
      .page-footer .pre-footer .social-links-footer a img {
        width: 30px;
        height: 30px; }
      .page-footer .pre-footer .social-links-footer a svg {
        fill: #A8A8A8;
        max-width: 30px;
        max-height: 30px;
        transition: fill 175ms ease; }
  .page-footer .post-footer {
    min-height: 150px; }
    @media print, screen and (min-width: 48em) {
      .page-footer .post-footer {
        min-height: 200px; } }
    .page-footer .post-footer br {
      display: none; }
      @media screen and (max-width: 63.9375em) {
        .page-footer .post-footer br {
          display: block; } }
    .page-footer .post-footer svg {
      min-width: 1000px;
      bottom: auto;
      min-height: 1050px;
      height: auto;
      margin-top: 2rem;
      left: -120%;
      position: absolute; }
      @media print, screen and (min-width: 40em) {
        .page-footer .post-footer svg {
          left: -45%; } }
      @media print, screen and (min-width: 48em) {
        .page-footer .post-footer svg {
          left: -130%;
          min-width: 2300px;
          min-height: 2000px; } }
      @media screen and (min-width: 64em) {
        .page-footer .post-footer svg {
          left: -105%; } }
      @media screen and (min-width: 75em) {
        .page-footer .post-footer svg {
          left: -80%; } }
      @media screen and (min-width: 90em) {
        .page-footer .post-footer svg {
          left: -55%; } }
    .page-footer .post-footer sup {
      top: -0.2em; }
  .page-footer .cdic {
    text-align: center; }
    .page-footer .cdic .cdic-svg-height {
      height: 3rem; }
  .page-footer #searchBtn {
    padding-left: 1em; }
    .page-footer #searchBtn::placeholder {
      color: #A8A8A8; }
  .page-footer .location-search a, .page-footer .location-search button {
    margin: 1rem 0; }
    @media print, screen and (min-width: 40em) {
      .page-footer .location-search a, .page-footer .location-search button {
        margin: 0; } }
    .page-footer .location-search a.button, .page-footer .location-search button.button {
      padding: .75em 1.25em; }
  .page-footer .motus-svg.main {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 4.6875rem; }
  .page-footer .motus-svg {
    text-decoration: none;
    color: #00d1af;
    transition: color 250ms ease; }

.mobile-header {
  margin-top: 1rem; }
  .mobile-header .logo svg {
    width: 11.25rem;
    max-height: 3.4375rem; }

.global-header {
  position: fixed;
  width: 100%;
  top: auto;
  bottom: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 3.75rem;
  border-bottom: 0; }
  @media screen and (min-width: 64em) {
    .global-header {
      height: 6.25rem;
      top: 0;
      bottom: auto; } }
  .global-header a {
    color: inherit;
    text-decoration: none; }
  .global-header .navigation {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    font-size: 0.875rem;
    flex: 1 1 auto;
    z-index: 3;
    padding: 0; }
    .global-header .navigation > nav {
      width: 100%; }
      @media screen and (min-width: 64em) {
        .global-header .navigation > nav {
          width: 80%; } }
    .global-header .navigation ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      width: 100%; }
      .global-header .navigation ul li {
        width: 25%;
        text-align: center;
        height: 3.75rem;
        display: flex;
        align-items: stretch;
        position: relative;
        padding: 0; }
        .global-header .navigation ul li.search-form {
          width: 32%; }
          @media screen and (min-width: 75em) {
            .global-header .navigation ul li.search-form {
              width: 25%; } }
        @media screen and (min-width: 64em) {
          .global-header .navigation ul li {
            width: 16.667%;
            height: 6.25rem; } }
        .global-header .navigation ul li .tertiary {
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;
          transform: translateY(150%);
          width: 100%; }
          @media screen and (min-width: 64em) {
            .global-header .navigation ul li .tertiary {
              top: 0;
              bottom: auto;
              transform: translateY(-150%); } }
          .global-header .navigation ul li .tertiary.signin {
            right: 0;
            left: auto;
            width: 401%;
            padding: 2rem;
            text-align: left;
            font-size: 1rem;
            color: #414042;
            border: 1px solid #FFFFFF;
            border-right: none;
            /*@media screen and (max-width: 319px) {
                            min-width: 301px;
                        }
                        @media screen and (min-width: 320px) and  (max-width: 359px) {
                            min-width: 321px;
                        }
                        @media screen and (min-width: 360px) and  (max-width: 374px) {
                            min-width: 361px;
                        }
                        @media screen and (min-width: 375px) {
                            min-width: 376px;
                        }
                        @media screen and (min-width: 414px) {
                            min-width: 415px;
                        }*/ }
            @media screen and (max-width: 63.9375em) {
              .global-header .navigation ul li .tertiary.signin {
                border-bottom: none; } }
            @media screen and (min-width: 64em) {
              .global-header .navigation ul li .tertiary.signin {
                border-top: none; } }
            @media print, screen and (min-width: 40em) {
              .global-header .navigation ul li .tertiary.signin {
                min-width: 305px;
                width: 200%; } }
            .global-header .navigation ul li .tertiary.signin p {
              font-weight: bold;
              margin: 0; }
            .global-header .navigation ul li .tertiary.signin a {
              border: none;
              text-align: left;
              display: block;
              margin-top: 0.5rem;
              text-transform: none;
              font-size: 0.75rem; }
              .global-header .navigation ul li .tertiary.signin a:hover {
                text-decoration: underline; }
              .global-header .navigation ul li .tertiary.signin a:before {
                display: none; }
            .global-header .navigation ul li .tertiary.signin div.form-div {
              display: block;
              /* Customize the label (the container) */
              /* Hide the browser's default checkbox */
              /* Create a custom checkbox */
              /* On mouse-over, add a grey background color */
              /* When the checkbox is checked, add a blue background */
              /* Create the checkmark/indicator (hidden when not checked) */
              /* Show the checkmark when checked */
              /* Style the checkmark/indicator */ }
              .global-header .navigation ul li .tertiary.signin div.form-div input {
                color: #545558;
                border-bottom: 2px solid;
                margin-top: 1.5rem;
                padding-left: 1rem;
                width: 100%;
                padding-top: 0; }
                .global-header .navigation ul li .tertiary.signin div.form-div input::-webkit-input-placeholder {
                  /* Chrome/Opera/Safari */
                  color: #FFFFFF;
                  letter-spacing: 1px; }
                .global-header .navigation ul li .tertiary.signin div.form-div input::-moz-placeholder {
                  /* Firefox 19+ */
                  color: #FFFFFF;
                  letter-spacing: 1px; }
                .global-header .navigation ul li .tertiary.signin div.form-div input:-ms-input-placeholder {
                  /* IE 10+ */
                  color: #FFFFFF;
                  letter-spacing: 1px; }
                .global-header .navigation ul li .tertiary.signin div.form-div input:-moz-placeholder {
                  /* Firefox 18- */
                  color: #FFFFFF;
                  letter-spacing: 1px; }
                .global-header .navigation ul li .tertiary.signin div.form-div input::placeholder {
                  /* Firefox 18- */
                  color: #FFFFFF;
                  letter-spacing: 1px; }
              .global-header .navigation ul li .tertiary.signin div.form-div label {
                color: #414042; }
              .global-header .navigation ul li .tertiary.signin div.form-div [type="text"], .global-header .navigation ul li .tertiary.signin div.form-div [type="password"] {
                padding: 0;
                font-size: 0.75rem; }
              .global-header .navigation ul li .tertiary.signin div.form-div [type="submit"] {
                text-indent: inherit;
                background-image: none;
                width: auto;
                height: auto;
                position: relative;
                top: 0;
                right: 0;
                margin: 1rem 0;
                text-transform: uppercase; }
              .global-header .navigation ul li .tertiary.signin div.form-div .container {
                display: block;
                position: relative;
                padding-left: 20px;
                margin: 0.5rem 0 0;
                cursor: pointer;
                font-size: 0.8rem;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none; }
              .global-header .navigation ul li .tertiary.signin div.form-div .container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                left: 0; }
              .global-header .navigation ul li .tertiary.signin div.form-div .checkmark {
                position: absolute;
                top: 5px;
                left: 0;
                height: 12px;
                width: 12px;
                background-color: #FFFFFF; }
              .global-header .navigation ul li .tertiary.signin div.form-div .container:hover input ~ .checkmark {
                background-color: #E6E7E8; }
              .global-header .navigation ul li .tertiary.signin div.form-div .container input:checked ~ .checkmark {
                background-color: #545558; }
              .global-header .navigation ul li .tertiary.signin div.form-div .checkmark:after {
                content: "";
                position: absolute;
                display: none; }
              .global-header .navigation ul li .tertiary.signin div.form-div .container input:checked ~ .checkmark:after {
                display: block; }
              .global-header .navigation ul li .tertiary.signin div.form-div .container .checkmark:after {
                left: 4px;
                top: 1px;
                width: 4px;
                height: 8px;
                border: solid white;
                border-width: 0 2px 2px 0px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg); }
            .global-header .navigation ul li .tertiary.signin #forgot-pword svg {
              width: 10px;
              height: 9px; }
            .global-header .navigation ul li .tertiary.signin .signin-submit {
              padding: 0.5rem 1rem;
              margin: 1rem 0;
              width: auto;
              max-width: 120px;
              min-width: 120px;
              text-align: center;
              font-size: 1rem;
              letter-spacing: inherit;
              font-size: 0.75rem; }
          .global-header .navigation ul li .tertiary .subnav {
            text-align: center;
            border: 1px solid #FFFFFF; }
            @media screen and (max-width: 63.9375em) {
              .global-header .navigation ul li .tertiary .subnav {
                border-bottom: none; } }
            @media screen and (min-width: 64em) {
              .global-header .navigation ul li .tertiary .subnav {
                border-top: none; } }
            .global-header .navigation ul li .tertiary .subnav a {
              text-align: center;
              padding: 1.5rem 0;
              border-top: none;
              border-bottom: 2px dashed #fefefe;
              font-size: 0.8rem;
              max-width: 75%;
              margin: 0 auto; }
              .global-header .navigation ul li .tertiary .subnav a:last-child {
                border-bottom: none; }
              .global-header .navigation ul li .tertiary .subnav a:hover {
                color: #FFFFFF; }
              .global-header .navigation ul li .tertiary .subnav a:before {
                display: none; }
              @media print, screen and (min-width: 40em) {
                .global-header .navigation ul li .tertiary .subnav a {
                  font-size: 0.9rem;
                  max-width: 60%; } }
              @media screen and (min-width: 64em) {
                .global-header .navigation ul li .tertiary .subnav a {
                  max-width: 80%;
                  text-align: left;
                  justify-content: flex-start; } }
              @media screen and (min-width: 75em) {
                .global-header .navigation ul li .tertiary .subnav a {
                  max-width: 70%; } }
        .global-header .navigation ul li:first-child a:before {
          display: none; }
    .global-header .navigation a {
      border-bottom: 6px solid transparent;
      text-align: center;
      padding: 0;
      font-size: 0.8rem;
      position: relative;
      margin: 0;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      border-top: none;
      transition: border-color 175ms ease; }
      .global-header .navigation a:before {
        content: '';
        border-left: 1px dashed #A8A8A8;
        height: 100%;
        width: 1px;
        display: block;
        position: absolute;
        top: 4px;
        left: 0; }
      .global-header .navigation a:hover {
        border-bottom: 6px solid #00D1AF;
        border-top: none; }
      @media screen and (max-width: 374px) {
        .global-header .navigation a {
          padding: 0 0.5rem; } }
      @media print, screen and (min-width: 40em) {
        .global-header .navigation a {
          font-size: 0.9rem; } }
      @media screen and (min-width: 64em) {
        .global-header .navigation a {
          font-size: 1rem;
          border-top: 6px solid transparent;
          border-bottom: none; }
          .global-header .navigation a:before {
            display: none; }
          .global-header .navigation a:hover {
            border-top: 6px solid #00D1AF;
            border-bottom: none; } }
      .global-header .navigation a.logo {
        padding: 0.5rem 0 0;
        margin: 0 auto;
        display: inline-block;
        border: none;
        width: auto; }
        .global-header .navigation a.logo:hover, .global-header .navigation a.logo:focus {
          border: none; }
        .global-header .navigation a.logo svg {
          width: 11.25rem;
          max-height: 3.4375rem; }
      @media screen and (min-width: 64em) {
        .global-header .navigation a.cutout-nav:before {
          content: '';
          border-left: 1px dashed #A8A8A8;
          height: 100%;
          width: 1px;
          display: block;
          position: absolute;
          top: -3px;
          left: -1px; } }
    .global-header .navigation div.form-div {
      display: flex;
      position: relative;
      margin: 0;
      align-items: center;
      justify-content: center; }
      .global-header .navigation div.form-div label {
        text-indent: -99999px;
        height: 0; }
        .global-header .navigation div.form-div label.show-label {
          text-indent: inherit;
          height: auto; }
      .global-header .navigation div.form-div :-ms-input-placeholder {
        color: #C1C1C1; }
        @media screen and (max-width: 47.9375em) {
          .global-header .navigation div.form-div :-ms-input-placeholder {
            color: rgba(255, 255, 255, 0.4); } }
      .global-header .navigation div.form-div ::webkit-input-placeholder {
        color: #C1C1C1; }
        @media screen and (max-width: 47.9375em) {
          .global-header .navigation div.form-div ::webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.4); } }
      .global-header .navigation div.form-div ::placeholder {
        color: #C1C1C1; }
        @media screen and (max-width: 47.9375em) {
          .global-header .navigation div.form-div ::placeholder {
            color: rgba(255, 255, 255, 0.4); } }
      .global-header .navigation div.form-div input {
        padding: 0.5rem 1rem 0 1rem;
        margin: 0;
        width: auto; }
        .global-header .navigation div.form-div input::placeholder {
          color: #A8A8A8; }
        @media screen and (max-width: 47.9375em) {
          .global-header .navigation div.form-div input {
            width: 100%;
            display: block;
            padding: .75em 0;
            box-shadow: unset;
            background-color: transparent;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            color: #FFFFFF;
            outline: 0; } }
      .global-header .navigation div.form-div [type="submit"] {
        text-indent: -999999px;
        width: 2.25em;
        height: 2em;
        background-position: center 35%;
        background-size: auto 65%;
        background-repeat: no-repeat;
        background-image: url("../media/motusbank/assets/img/icon-search.svg");
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -14px;
        cursor: pointer; }
    .global-header .navigation .js-open > a {
      border-bottom: 6px solid #00D1AF;
      border-top: none; }
      @media screen and (min-width: 64em) {
        .global-header .navigation .js-open > a {
          border-top: 6px solid #00D1AF;
          border-bottom: none; } }
    .global-header .navigation .js-open .tertiary {
      transform: translateY(-60px); }
      @media screen and (min-width: 64em) {
        .global-header .navigation .js-open .tertiary {
          transform: translateY(100px); } }

@media screen and (min-width: 1366px) {
  #text-hero-banner {
    margin-left: 16.8vw; } }

@media screen and (max-width: 1366px) {
  #text-hero-banner {
    margin-left: 3.3vw; } }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 3.4vw;
  padding-right: 3.4vw; }

#a0qr9s-accordion-label {
  margin-left: -0.5vw; }

#section-tow {
  height: auto;
  padding-bottom: 11.8rem; }

.find-an-atm {
  text-align: right; }

a.button-use-my-location {
  width: 100%; }

span.find-atm-or {
  font-size: 1.3rem; }

#searchBtn {
  max-height: 35px; }

.click-search {
  padding: 0.7em 2.25em !important;
  margin-right: 0.3rem; }

#bg-gic {
  background-color: #e6e7e9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom; }

.hero-banner {
  margin-bottom: 1.5rem; }

#bg-search {
  background-image: url("../../assets/img/basic1-015_search_zoom_find.png");
  width: 2.5rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #00D1AF;
  margin-left: 0.75rem; }

.hero-banner-margin-bottom {
  margin-bottom: 0; }

.padding-right {
  padding-right: 4.69rem; }

a.btn-watch-video {
  padding: 0.7rem 2rem 0.7rem 2rem;
  font-size: 1.1rem;
  color: #414042 !important; }

.promo-content .espace-text {
  padding-right: 10rem;
  padding-left: 4rem; }

/* Small only */
@media screen and (max-width: 39.9375em) {
  .padding-right {
    padding-right: 0rem; }
  #good-to-know .panel {
    padding-top: 30vw;
    padding-bottom: 85vw; }
  .find-an-atm {
    text-align: center; }
  a.button-use-my-location, #searchBtn {
    width: 200px;
    margin: auto;
    margin-top: 0.5rem; }
  button.click-search {
    margin-top: 2rem !important; }
  .with-label.promo, .with-label.gic-application, .with-label.content-switcher, .with-label.abm-finder {
    padding-bottom: 0rem; }
  #bg-mobile {
    padding-bottom: 42rem;
    padding-top: 26em; }
  .espace-text {
    height: 64em; }
  .promo-content .espace-text {
    padding-right: 0;
    padding-left: 0; }
  #section-tow {
    height: auto;
    padding-bottom: 0rem; }
  .padding-bottom-good-to-know {
    padding-bottom: 14rem; }
  .bg-mobile-good-to-know {
    padding: 8rem; } }

/* Medium and up */
@media screen and (min-width: 40em) {
  #section-tow {
    height: auto;
    padding-bottom: 0rem; }
  #section-tow .promo {
    padding: 3rem 1.8rem 0rem; } }

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #section-tow {
    height: auto;
    padding-bottom: 0rem; } }

/* Large and up */
@media screen and (min-width: 64em) {
  .hero-banner .panel:not(.short) {
    min-height: 24rem; } }

/* Large only */
@media screen and (max-width: 85.3125rem) {
  .padding-right {
    padding-right: 0rem !important; } }

.btn-green-dark {
  padding: 0.7rem 2rem 0.7rem 2rem;
  font-size: 1.1rem;
  color: #414042 !important; }

#section-tow-investing {
  height: 37.8rem; }

#section-tow-investing .investing-img {
  margin-top: -4rem; }

.btn-inesting-green-dark {
  background: #00D1AF !important;
  color: #414042 !important; }

#Good-to-Know h4, #Good-to-Know .carbon-text {
  color: #fff !important; }

#Good-to-Know .carbon-border {
  border-color: #fff !important; }

#section-reasons p {
  font-size: 1.2rem; }

#Secured-unsecured .promo:last-child {
  padding-top: 0; }

#Secured-unsecured h4 {
  font-size: 1.875rem; }

#good-to-know-borrowing .carbon-text-dark-gray {
  color: #414042 !important; }

#Secured-unsecured .carbon-text {
  color: #fff !important;
  border-color: #fff !important; }

#Secured-unsecured {
  margin-top: 1rem; }

#Secured-unsecured .promo:first-child {
  margin-bottom: 0;
  padding-bottom: 0.5rem; }

.protecting-background {
  background-color: #E6E7E8; }

.testimonials .panel {
  padding: 5rem 1.8rem 3rem; }
  @media screen and (min-width: 64em) {
    .testimonials .panel {
      padding: 5rem 4rem 4rem; } }
  .testimonials .panel h3 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .testimonials .panel h3 {
        font-size: 2.5rem; } }
  .testimonials .panel h4 {
    margin-bottom: 0.5rem; }

.testimonial {
  position: relative;
  padding: 0 3rem; }
  @media screen and (max-width: 47.9375em) {
    .testimonial {
      padding-right: 1.5rem; } }
  .testimonial svg {
    position: absolute;
    width: 1.75em;
    height: 1.75em;
    top: 0;
    left: 0;
    fill: #FFFFFF;
    display: inline-block; }

.testimonial-carousel {
  margin-top: 3rem;
  overflow: visible; }
  .testimonial-carousel.mint-arrow .slick-next::before, .testimonial-carousel.mint-arrow .slick-prev::before {
    border-color: #00D1AF; }
  .testimonial-carousel .slick-slide {
    outline: 0; }
    .testimonial-carousel .slick-slide p {
      line-height: 1.8; }
      .testimonial-carousel .slick-slide p:first-of-type {
        margin-bottom: 1.25em; }
      .testimonial-carousel .slick-slide p:last-of-type {
        margin-bottom: 0; }
  .testimonial-carousel .slick-next, .testimonial-carousel .slick-prev {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 15%;
    cursor: pointer;
    text-indent: -99999999px;
    color: transparent;
    outline: 0; }
    @media screen and (max-width: 47.9375em) {
      .testimonial-carousel .slick-next, .testimonial-carousel .slick-prev {
        display: none !important; } }
    .testimonial-carousel .slick-next::before, .testimonial-carousel .slick-prev::before {
      position: absolute;
      width: 65%;
      height: 65%;
      top: 50%;
      left: 50%;
      border-width: 0;
      border-style: solid;
      border-color: #FFFFFF;
      content: "";
      transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(0.8);
      transition: transform 175ms cubic-bezier(0.35, 0.28, 0.54, 0.96); }
    .testimonial-carousel .slick-next:hover::before, .testimonial-carousel .slick-prev:hover::before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1); }
  .testimonial-carousel .slick-next {
    left: 100%;
    margin-left: 1rem; }
    .testimonial-carousel .slick-next::before {
      border-right-width: 3px;
      border-top-width: 3px; }
  .testimonial-carousel .slick-prev {
    right: 100%;
    margin-right: 1rem; }
    .testimonial-carousel .slick-prev::before {
      border-left-width: 3px;
      border-bottom-width: 3px; }
  .testimonial-carousel .slick-dots {
    width: 100%;
    margin: 2.5rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    /*
    a, button {
      width: .75rem;
      height: .75rem;
      margin: 0 .75rem;
      border-radius: 50%;
      text-indent: -999999px;
      color: transparent;
      background-color: color('white');
    }
    */ }
    .testimonial-carousel .slick-dots a, .testimonial-carousel .slick-dots button {
      position: relative;
      width: 2rem;
      height: 0.1875rem;
      margin: 0 .25rem;
      text-indent: -99999999px;
      cursor: pointer;
      outline: 0;
      transition: background-color 175ms cubic-bezier(0.35, 0.28, 0.54, 0.96); }
      .testimonial-carousel .slick-dots a:not(.accent-background), .testimonial-carousel .slick-dots button:not(.accent-background) {
        background-color: #E6E7E8; }
      .testimonial-carousel .slick-dots a::after, .testimonial-carousel .slick-dots button::after {
        position: absolute;
        width: 100%;
        height: 500%;
        top: -200%;
        display: block;
        content: ""; }

iframe {
  width: 100%;
  border: 0;
  overflow: hidden; }

.with-btn {
  position: relative;
  padding-bottom: 3rem; }
  .with-btn.comparison {
    padding-bottom: 240px; }
    @media print, screen and (min-width: 40em) {
      .with-btn.comparison {
        padding-bottom: 290px; } }
    @media print, screen and (min-width: 48em) {
      .with-btn.comparison {
        padding-bottom: 340px; } }
    @media screen and (min-width: 64em) {
      .with-btn.comparison {
        padding-bottom: 4rem; } }
  @media screen and (max-width: 63.9375em) {
    .with-btn .background-image {
      margin-bottom: 2rem; } }
  .with-btn .long-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: block; }
    .with-btn .long-btn a.button {
      margin: 0;
      width: 100%;
      font-weight: normal;
      letter-spacing: 1px; }
      .with-btn .long-btn a.button:hover {
        padding: 1em 1.5em; }

.content-switcher {
  padding: 3rem 1.8rem;
  align-items: center; }
  @media screen and (min-width: 64em) {
    .content-switcher {
      padding: 4rem; } }
  .content-switcher .switches {
    position: relative;
    margin-bottom: 4rem; }
    @media print, screen and (min-width: 48em) {
      .content-switcher .switches {
        margin-bottom: 0; } }
    .content-switcher .switches:after {
      content: '';
      border-bottom: 4px dashed;
      width: 100%;
      height: 4px;
      display: block;
      position: absolute;
      top: auto;
      bottom: -2rem;
      right: 0;
      border-color: inherit; }
      @media print, screen and (min-width: 48em) {
        .content-switcher .switches:after {
          border-bottom: none;
          border-right: 4px dashed;
          height: 100%;
          width: 4px;
          top: 0;
          bottom: auto;
          right: 0;
          border-color: inherit; } }
  .content-switcher ul {
    list-style: none;
    margin: 0; }
    .content-switcher ul li {
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
      padding: 0; }
      @media print, screen and (min-width: 48em) {
        .content-switcher ul li {
          margin-bottom: 1rem;
          padding: 0 1rem 0 0;
          font-size: 1.25rem; } }
      @media screen and (min-width: 64em) {
        .content-switcher ul li {
          font-size: 1.5rem; } }
      .content-switcher ul li::before {
        content: "" !important; }
      .content-switcher ul li:last-child {
        margin-bottom: 0; }
      .content-switcher ul li a {
        text-decoration: none; }
        .content-switcher ul li a:hover {
          text-decoration: underline; }
  .content-switcher .tab-title:focus {
    text-decoration: underline; }
  .content-switcher .tab-title.js-active {
    text-decoration: underline; }
    .content-switcher .tab-title.js-active:hover {
      text-decoration: underline; }
  .content-switcher .tab-content {
    display: none; }
    .content-switcher .tab-content.js-active {
      display: block; }

.gic-application {
  padding: 3rem 1.8rem;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 64em) {
    .gic-application {
      padding: 4rem; } }
  .gic-application h3 {
    margin: 0;
    line-height: 0.8;
    font-size: 2.5rem; }
  .gic-application .hidden {
    display: none; }
  .gic-application .error {
    margin-bottom: 1.875rem;
    border: 1px solid;
    font-size: 0.8rem; }
    @media screen and (max-width: 63.9375em) {
      .gic-application .error {
        margin-top: 2rem;
        margin-bottom: 0.75rem;
        padding: 0.5rem; } }
  .gic-application .title {
    position: relative;
    margin-bottom: 6rem; }
    @media screen and (min-width: 64em) {
      .gic-application .title {
        margin-bottom: 0;
        padding-top: 1rem;
        padding-bottom: 1rem; } }
    .gic-application .title:after {
      content: '';
      border-bottom: 4px dashed #FFFFFF;
      width: 100%;
      display: block;
      position: absolute;
      top: auto;
      bottom: -3rem;
      right: 0; }
      @media screen and (min-width: 64em) {
        .gic-application .title:after {
          height: 100%;
          border-bottom: none;
          border-right: 4px dashed #FFFFFF;
          top: 0;
          bottom: auto;
          right: 18px; } }
    .gic-application .title h3 {
      font-size: 1.875rem;
      padding-right: 2.5rem;
      line-height: 1.2; }
  .gic-application .rate-selection .grid-x {
    align-items: flex-end; }
  @media screen and (max-width: 63.9375em) {
    .gic-application .rate-selection h6 {
      margin-bottom: 1rem; } }
  .gic-application .rate-selection .select:after {
    right: 20px;
    bottom: 2px; }
  .gic-application .rate-selection .select option:first-child {
    color: inherit; }
  .gic-application .rate-selection .button {
    margin: 1rem 0 0; }
    @media screen and (min-width: 64em) {
      .gic-application .rate-selection .button {
        margin: 0; } }
  .gic-application .rate-selection input {
    margin: 2rem 0 0; }
    @media screen and (min-width: 64em) {
      .gic-application .rate-selection input {
        margin: 0; } }

.abm-finder {
  padding: 3rem 1.8rem 0; }
  @media screen and (min-width: 64em) {
    .abm-finder {
      padding: 4rem 4rem 0; } }
  .abm-finder h3 {
    margin-bottom: 2rem; }
  .abm-finder h6 {
    margin-bottom: 0; }
  .abm-finder label {
    font-size: 0.75rem; }
  .abm-finder .location-search {
    margin: 0 auto 3rem;
    position: relative;
    padding: 0 0 2rem; }
    @media print, screen and (min-width: 40em) {
      .abm-finder .location-search {
        padding: 2rem 0 3rem; } }
    .abm-finder .location-search:after {
      content: '';
      border-bottom: 1px dashed #A8A8A8;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0; }
    .abm-finder .location-search > div {
      display: flex;
      align-items: center; }
    .abm-finder .location-search .button {
      margin: 1rem 0;
      padding: .75em; }
      @media print, screen and (min-width: 40em) {
        .abm-finder .location-search .button {
          margin: 0; } }
      @media screen and (min-width: 64em) {
        .abm-finder .location-search .button {
          padding: .75em 1.5em; } }
  .abm-finder .language-selection {
    position: relative; }
    @media screen and (max-width: 63.9375em) {
      .abm-finder .language-selection {
        margin: 2rem auto 0; } }
    .abm-finder .language-selection:after {
      content: '>';
      font: 24px "Consolas", monospace;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      right: 10px;
      top: 0;
      position: absolute;
      pointer-events: none; }
  .abm-finder #mapDiv {
    width: 100%;
    height: 400px;
    margin: 3rem auto;
    position: relative;
    -webkit-transition: visibility 1s, opacity 1s ease-in-out;
    -moz-transition: visibility 1s, opacity 1s ease-in-out;
    -ms-transition: visibility 1s, opacity 1s ease-in-out;
    -o-transition: visibility 1s, opacity 1s ease-in-out;
    opacity: 1;
    overflow: hidden;
    visibility: visible; }
  .abm-finder .features {
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 3rem; }
    .abm-finder .features:after {
      content: '';
      border-bottom: 1px dashed #A8A8A8;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0; }
    .abm-finder .features .feature-sample {
      display: flex;
      align-items: center;
      margin: 0.8rem 0; }
      @media screen and (min-width: 64em) {
        .abm-finder .features .feature-sample {
          margin: 1.5rem 0; } }
      .abm-finder .features .feature-sample svg {
        max-width: 22px;
        max-height: 22px; }
      .abm-finder .features .feature-sample p {
        margin: 0 0 0 0.5rem;
        font-size: 0.75rem; }
  .abm-finder .locations .location {
    margin-bottom: 4rem; }
    .abm-finder .locations .location .location-type {
      display: flex;
      align-items: center;
      margin-bottom: 1rem; }
      .abm-finder .locations .location .location-type svg {
        max-width: 22px;
        max-height: 32px; }
      .abm-finder .locations .location .location-type h6 {
        margin: 0 0 0 0.5rem; }
    .abm-finder .locations .location .address {
      margin-bottom: 2rem;
      max-width: 250px; }
    .abm-finder .locations .location .location-features .feature-icon {
      display: inline-flex;
      max-width: 30px; }
      .abm-finder .locations .location .location-features .feature-icon svg {
        max-width: 22px;
        max-height: 22px;
        margin: 1.5rem 0.25rem 0; }
        .abm-finder .locations .location .location-features .feature-icon svg:first-child {
          margin-left: 0; }

.fifty-grid.with-borders {
  padding-top: 2rem; }
  .fifty-grid.with-borders .separator {
    padding: 0 1.8rem; }
    @media screen and (min-width: 64em) {
      .fifty-grid.with-borders .separator {
        padding: 0 4rem; } }
    .fifty-grid.with-borders .separator hr {
      margin: 0; }
  .fifty-grid.with-borders .fifty-grid-item {
    margin-bottom: 0;
    position: relative; }
    .fifty-grid.with-borders .fifty-grid-item:nth-child(3n - 1):before {
      content: '';
      border-bottom: 2px dashed;
      border-color: #A8A8A8;
      width: auto;
      height: 2px;
      display: block;
      margin: -3rem auto 3rem; }
      @media print, screen and (min-width: 48em) {
        .fifty-grid.with-borders .fifty-grid-item:nth-child(3n - 1):before {
          border-bottom: none;
          border-left: 2px dashed;
          border-color: #A8A8A8;
          height: 70%;
          width: 2px;
          top: 50%;
          transform: translateY(-50%);
          bottom: auto;
          left: -1.05rem;
          margin: 0;
          position: absolute; } }

.fifty-grid .fifty-grid-item {
  padding: 3rem 1.8rem;
  margin-bottom: 1.875rem;
  position: relative; }
  @media screen and (min-width: 64em) {
    .fifty-grid .fifty-grid-item {
      padding: 4rem; } }
  .fifty-grid .fifty-grid-item.with-title {
    padding: 0; }
    .fifty-grid .fifty-grid-item.with-title .with-label {
      padding: 5rem 1.8rem 3rem; }
      @media screen and (min-width: 64em) {
        .fifty-grid .fifty-grid-item.with-title .with-label {
          padding: 5rem 4rem 4rem; } }
      .fifty-grid .fifty-grid-item.with-title .with-label .section-label {
        left: 1.8rem; }
        @media screen and (min-width: 64em) {
          .fifty-grid .fifty-grid-item.with-title .with-label .section-label {
            left: 4rem; } }
  .fifty-grid .fifty-grid-item *:last-child {
    margin-bottom: 0; }
  .fifty-grid .fifty-grid-item h4, .fifty-grid .fifty-grid-item p {
    margin-bottom: 2rem; }

.grid-container.container-webpart {
  margin-bottom: 1.5rem; }
  .grid-container.container-webpart .grid-container {
    padding: 0;
    margin: 0; }
    .grid-container.container-webpart .grid-container .content-block, .grid-container.container-webpart .grid-container .testimonials .panel, .grid-container.container-webpart .grid-container .content-switcher {
      padding: 5rem 1.8rem 3rem; }
      @media screen and (min-width: 64em) {
        .grid-container.container-webpart .grid-container .content-block, .grid-container.container-webpart .grid-container .testimonials .panel, .grid-container.container-webpart .grid-container .content-switcher {
          padding: 5rem 4rem 4rem; } }
    .grid-container.container-webpart .grid-container .promo {
      padding-top: 5rem; }

.grid-container .grid-x.content-block {
  margin-bottom: 1.5rem;
  padding: 3rem 1.8rem; }
  @media screen and (min-width: 64em) {
    .grid-container .grid-x.content-block {
      padding: 4rem; } }
  .grid-container .grid-x.content-block p {
    line-height: 1.8;
    margin-bottom: 2rem; }

#carrers-padding-top {
  padding-top: 0rem; }

h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #1d1c1c;
  font-weight: 100; }

.rates-table {
  padding: 3rem 1.8rem; }
  @media screen and (min-width: 64em) {
    .rates-table {
      padding: 4rem; } }
  .rates-table h2 {
    margin-bottom: 2rem; }
  .rates-table p {
    margin: 0;
    padding: 1rem 0; }
    .rates-table p.legal {
      font-size: 0.75rem;
      font-weight: normal; }
  .rates-table .legal-text {
    margin-top: 1.5rem; }
    .rates-table .legal-text p {
      font-size: 0.75rem;
      padding-bottom: 0; }
  .rates-table .service {
    margin-bottom: 2rem; }
    .rates-table .service .service-title {
      margin-bottom: 1.5rem; }
    .rates-table .service .service-item {
      padding: 0;
      margin: 0; }
      .rates-table .service .service-item .accordion-item {
        margin: 0; }
      .rates-table .service .service-item .accordion-title {
        padding: 1rem;
        line-height: 1.6; }
      .rates-table .service .service-item .accordion-content {
        padding: 1rem;
        background-color: inherit; }
        .rates-table .service .service-item .accordion-content p {
          padding: 0.5rem 0;
          font-size: 0.875rem; }
      .rates-table .service .service-item.multi p {
        font-size: 0.875rem; }
        @media print, screen and (min-width: 40em) {
          .rates-table .service .service-item.multi p {
            font-size: 0.75rem; } }
        @media screen and (min-width: 64em) {
          .rates-table .service .service-item.multi p {
            font-size: 0.875rem; } }
      .rates-table .service .service-item.multi > .cell {
        padding: 0; }
        .rates-table .service .service-item.multi > .cell:first-child {
          padding-left: 0.625rem; }
          @media screen and (min-width: 64em) {
            .rates-table .service .service-item.multi > .cell:first-child {
              padding-left: 0.9375rem; } }
  @media screen and (min-width: 64em) {
    .rates-table .rate-group {
      margin-top: 1rem; } }
  .rates-table .rate-group .rate-column {
    margin-bottom: 1rem; }
    .rates-table .rate-group .rate-column:last-child {
      margin-bottom: 0; }
    .rates-table .rate-group .rate-column .rate-yearly-title {
      font-size: 1.33rem;
      margin-left: -0.8rem; }
      @media screen and (min-width: 64em) {
        .rates-table .rate-group .rate-column .rate-yearly-title {
          margin-left: 0; } }
  @media screen and (min-width: 64em) {
    .rates-table .rate-group .rate-item .rate-item-info {
      background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168, 168, 168, 0) 0%);
      background-position: bottom;
      background-size: 6px 2px;
      background-repeat: repeat-x;
      padding-bottom: 1rem;
      margin-bottom: 1rem; } }
  .rates-table .rate-group .rate-item .rate-item-info {
    padding-left: 0;
    padding-right: 0; }
  .rates-table .rate-group .rate-item .rate-item-info h5 {
    display: inline-block;
    float: left;
    margin-bottom: 0; }
  .rates-table .rate-group .rate-item .rate-item-info .rate-title {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
    font-size: 1.33rem; }
    @media screen and (min-width: 64em) {
      .rates-table .rate-group .rate-item .rate-item-info .rate-title {
        width: 75%;
        margin-right: 5%; } }
  .rates-table .rate-group .rate-item .rate-item-info .rate-value {
    width: 20%;
    text-align: right;
    margin-bottom: 1.5rem;
    margin-bottom: 0;
    margin-bottom: 0; }
  .rates-table .rate-group .rate-item .rate-item-cta {
    background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168, 168, 168, 0) 0%);
    background-position: bottom;
    background-size: 6px 2px;
    background-repeat: repeat-x;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem; }
    @media screen and (min-width: 64em) {
      .rates-table .rate-group .rate-item .rate-item-cta {
        padding: 0 2rem;
        background: unset; } }
    .rates-table .rate-group .rate-item .rate-item-cta .button {
      float: left;
      margin-bottom: 0;
      margin-top: 1.5rem; }
      @media screen and (min-width: 64em) {
        .rates-table .rate-group .rate-item .rate-item-cta .button {
          margin-top: 0; } }
    .rates-table .rate-group .rate-item .rate-item-cta h5 {
      display: inline-block;
      float: left;
      margin-bottom: 0;
      margin-top: 1.5rem;
      margin-right: 2rem;
      font-size: 2rem;
      line-height: 1; }
  @media screen and (min-width: 64em) {
    .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info {
      background: none; } }
  .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-title {
    width: 100%;
    margin-bottom: 2rem;
    font-size: 0.95rem; }
    @media screen and (min-width: 64em) {
      .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-title {
        background: none;
        font-size: 1.33rem; } }
  .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yearly-years .rate-yearly {
    background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168, 168, 168, 0) 0%);
    background-position: top;
    background-size: 6px 2px;
    background-repeat: repeat-x;
    padding-top: 1rem;
    margin-top: 1rem;
    margin-right: 0;
    margin-bottom: 1rem;
    clear: both; }
    @media screen and (min-width: 64em) {
      .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yearly-years .rate-yearly {
        margin-left: 6rem; } }
    .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yearly-years .rate-yearly:first-child {
      background: none;
      margin-top: 0;
      padding-top: 0; }
    .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yearly-years .rate-yearly .cell:first-child {
      padding-left: 0; }
    .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yearly-years .rate-yearly .cell:last-child {
      padding-right: 0; }
    .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yearly-years .rate-yearly .rate-title {
      width: 100%;
      display: block;
      margin-bottom: 0; }
  .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yield {
    background: none;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    clear: both;
    border-top: 5px solid; }
    @media screen and (min-width: 64em) {
      .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yield {
        margin-left: 6rem;
        margin-top: 1.5rem; } }
    .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yield .rate-item-info {
      padding-bottom: 0;
      margin-bottom: 0;
      background-image: none; }
      .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-info .rate-yield .rate-item-info .rate-title {
        width: 100%;
        display: block;
        margin-bottom: 0; }
  .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-cta {
    background: none; }
    .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-cta .button {
      float: left;
      margin-top: 0;
      margin-left: 1.5rem; }
      @media print, screen and (min-width: 40em) {
        .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-cta .button {
          margin-left: 2rem; } }
      @media screen and (min-width: 64em) {
        .rates-table .rate-group .rate-item.rate-yearly-container .rate-item-cta .button {
          margin-left: 0; } }
  .rates-table .rate-group .rate-item.rate-item-full-width .rate-value {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1.9;
    font-size: 0.75rem;
    display: inline-block;
    float: left;
    margin-right: 2rem; }
    .rates-table .rate-group .rate-item.rate-item-full-width .rate-value strong {
      font-size: 1.25rem; }

.rate-carousel {
  overflow: visible;
  margin-left: 0;
  margin-right: 0; }
  @media print, screen and (min-width: 48em) {
    .rate-carousel {
      margin-left: -1.5rem;
      margin-right: -1.5rem; } }
  .rate-carousel.mint-arrow .slick-next::before, .rate-carousel.mint-arrow .slick-prev::before {
    border-color: #00D1AF; }
  .rate-carousel.no-carousel .rate {
    /*&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}*/
    /*.cell {
				a {
					&.button {
						width: auto;
						margin: 0.5rem 0;
						
						@include breakpoint(small only) {
							width: 100%;
						}
					}
				}
			}*/ }
  .rate-carousel.slick-slider .rate {
    margin: 0; }
  .rate-carousel .rate {
    padding: 0;
    margin: 0 0 2rem;
    /*&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}*/ }
    @media print, screen and (min-width: 48em) {
      .rate-carousel .rate {
        padding: 0 1.5rem;
        margin: 0; } }
    .rate-carousel .rate.no-intro .cell:first-child {
      padding-bottom: 0; }
      .rate-carousel .rate.no-intro .cell:first-child:after {
        display: none; }
    .rate-carousel .rate.no-intro .cell .heading {
      margin: 0; }
    .rate-carousel .rate .cell {
      position: relative;
      padding: 1rem 0 1.25rem; }
      @media print, screen and (min-width: 48em) {
        .rate-carousel .rate .cell {
          padding: 1.5rem 0 1.75rem; } }
      .rate-carousel .rate .cell.no-border {
        padding: 1rem 0 0; }
        @media print, screen and (min-width: 48em) {
          .rate-carousel .rate .cell.no-border {
            padding: 1.5rem 0 0.5rem; } }
        .rate-carousel .rate .cell.no-border:after {
          display: none; }
      .rate-carousel .rate .cell:after {
        content: '';
        border-bottom: 1px dashed #A8A8A8;
        width: 100%;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0; }
      .rate-carousel .rate .cell:last-child {
        padding-bottom: 0; }
        .rate-carousel .rate .cell:last-child:after {
          border: none; }
      .rate-carousel .rate .cell p, .rate-carousel .rate .cell h2, .rate-carousel .rate .cell h3, .rate-carousel .rate .cell span {
        margin: 0;
        padding: 0; }
      .rate-carousel .rate .cell h2 {
        font-size: 4.0625rem;
        line-height: 1; }
        @media print, screen and (min-width: 40em) {
          .rate-carousel .rate .cell h2 {
            font-size: 2.5rem; } }
        @media screen and (min-width: 64em) {
          .rate-carousel .rate .cell h2 {
            font-size: 3.4375rem; } }
        @media screen and (min-width: 75em) {
          .rate-carousel .rate .cell h2 {
            font-size: 4.0625rem; } }
      .rate-carousel .rate .cell .heading {
        border-bottom: 6px solid #00D1AF;
        margin-bottom: 1rem; }
        @media print, screen and (min-width: 48em) {
          .rate-carousel .rate .cell .heading {
            margin-bottom: 0; } }
      .rate-carousel .rate .cell a.button {
        width: 100%;
        margin: 0.5rem 0; }
  .rate-carousel .slick-slide {
    outline: 0; }
  .rate-carousel .slick-next, .rate-carousel .slick-prev {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 15%;
    cursor: pointer;
    text-indent: -99999999px;
    color: transparent;
    outline: 0; }
    .rate-carousel .slick-next::before, .rate-carousel .slick-prev::before {
      position: absolute;
      width: 65%;
      height: 65%;
      top: 50%;
      left: 50%;
      border-width: 0;
      border-style: solid;
      border-color: #FFFFFF;
      content: "";
      transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(0.8);
      transition: transform 175ms cubic-bezier(0.35, 0.28, 0.54, 0.96); }
    .rate-carousel .slick-next:hover::before, .rate-carousel .slick-prev:hover::before {
      transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1); }
  .rate-carousel .slick-next {
    left: 100%; }
    .rate-carousel .slick-next::before {
      border-right-width: 3px;
      border-top-width: 3px; }
  .rate-carousel .slick-prev {
    right: 100%; }
    .rate-carousel .slick-prev::before {
      border-left-width: 3px;
      border-bottom-width: 3px; }
  .rate-carousel .slick-dots {
    width: 100%;
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    text-align: center; }
    .rate-carousel .slick-dots li {
      padding: 0.33rem; }
    .rate-carousel .slick-dots a, .rate-carousel .slick-dots button {
      position: relative;
      width: 2rem;
      height: 0.1875rem;
      margin: 0 .25rem;
      text-indent: -99999999px;
      cursor: pointer;
      outline: 0;
      transition: background-color 175ms cubic-bezier(0.35, 0.28, 0.54, 0.96); }
      .rate-carousel .slick-dots a:not(.accent-background), .rate-carousel .slick-dots button:not(.accent-background) {
        background-color: #E6E7E8; }
      .rate-carousel .slick-dots a::after, .rate-carousel .slick-dots button::after {
        position: absolute;
        width: 100%;
        height: 500%;
        top: -200%;
        display: block;
        content: ""; }

.all-rates {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px dashed #A8A8A8;
  display: flex;
  align-items: center; }
  .all-rates a.button {
    margin-bottom: 0;
    margin-left: 1rem; }

.rates-table.fees-table .rate-group .rate-item {
  background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168, 168, 168, 0) 0%);
  background-position: bottom;
  background-size: 6px 2px;
  background-repeat: repeat-x;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem; }
  @media screen and (min-width: 64em) {
    .rates-table.fees-table .rate-group .rate-item {
      background-image: none;
      padding-bottom: 0;
      margin-bottom: 0; } }
  .rates-table.fees-table .rate-group .rate-item .rate-item-info .rate-title {
    width: 100%;
    display: block;
    margin-right: 0;
    margin-right: 1rem; }
  .rates-table.fees-table .rate-group .rate-item .rate-item-info .rate-disclaimer {
    width: 100%;
    display: block;
    clear: both;
    font-size: 0.9rem;
    padding-top: 0.1rem; }
  .rates-table.fees-table .rate-group .rate-item .rate-item-cta {
    background-image: none;
    margin-bottom: 0;
    padding-bottom: 0; }
    .rates-table.fees-table .rate-group .rate-item .rate-item-cta .rate-value {
      font-size: 1.9rem;
      line-height: 1.4;
      margin-top: 0;
      margin-right: 0;
      margin-top: 1rem; }
      @media print, screen and (min-width: 40em) {
        .rates-table.fees-table .rate-group .rate-item .rate-item-cta .rate-value {
          margin-top: 0; } }
      @media screen and (min-width: 64em) {
        .rates-table.fees-table .rate-group .rate-item .rate-item-cta .rate-value {
          font-size: 1.33rem; } }

.tabbed-chart .rate-full-width .rate {
  margin-bottom: 5rem; }
  .tabbed-chart .rate-full-width .rate:last-child {
    margin-bottom: 0; }
  .tabbed-chart .rate-full-width .rate:first-child {
    margin-top: 2.5rem; }
    @media screen and (min-width: 64em) {
      .tabbed-chart .rate-full-width .rate:first-child {
        margin-top: 3rem; } }
  .tabbed-chart .rate-full-width .rate .cell {
    padding: 0; }
    .tabbed-chart .rate-full-width .rate .cell::after {
      border-bottom: none; }
    .tabbed-chart .rate-full-width .rate .cell:last-child {
      margin-bottom: 0; }
    .tabbed-chart .rate-full-width .rate .cell h3 {
      border-bottom: none;
      margin-bottom: 1rem; }
  .tabbed-chart .rate-full-width .rate .rate-data-left.cell {
    background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168, 168, 168, 0) 0%);
    background-position: bottom;
    background-size: 6px 2px;
    background-repeat: repeat-x;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 1.25rem; }
    @media screen and (min-width: 64em) {
      .tabbed-chart .rate-full-width .rate .rate-data-left.cell {
        background-image: linear-gradient(#A8A8A8 33%, rgba(168, 168, 168, 0) 0%);
        background-position: right;
        background-size: 2px 6px;
        background-repeat: repeat-y;
        padding-left: 4rem;
        padding-right: 4rem;
        padding-bottom: 0;
        margin-bottom: 0; } }
    .tabbed-chart .rate-full-width .rate .rate-data-left.cell .primary-title {
      font-size: 1.9375rem; }
      @media screen and (min-width: 64em) {
        .tabbed-chart .rate-full-width .rate .rate-data-left.cell .primary-title {
          font-size: 1.9375rem; } }
  .tabbed-chart .rate-full-width .rate .rate-data-right.cell {
    padding-left: 0;
    padding-right: 0; }
    @media screen and (min-width: 64em) {
      .tabbed-chart .rate-full-width .rate .rate-data-right.cell {
        padding-left: 4rem;
        padding-right: 4rem; } }
    .tabbed-chart .rate-full-width .rate .rate-data-right.cell h2.rate-value {
      line-height: 0.8;
      margin-bottom: 0.5rem;
      font-size: 5rem; }
      @media screen and (min-width: 64em) {
        .tabbed-chart .rate-full-width .rate .rate-data-right.cell h2.rate-value {
          font-size: 4.0625rem; } }
    .tabbed-chart .rate-full-width .rate .rate-data-right.cell .button {
      max-width: 15rem;
      display: block;
      clear: both; }
    .tabbed-chart .rate-full-width .rate .rate-data-right.cell .first-cta {
      margin-top: 1.33rem; }

.content-combo {
  align-items: center; }
  .content-combo p, .content-combo a {
    margin-bottom: 2rem; }
  @media screen and (max-width: 39.9375em) {
    .content-combo p {
      margin-bottom: 1rem; } }

.tabbed-chart {
  padding: 0;
  padding-top: 0.25rem; }
  .tabbed-chart .intro-content h3 {
    margin-bottom: 0.5rem; }
  @media screen and (max-width: 63.9375em) {
    .tabbed-chart .grid-container {
      margin: 0;
      padding: 0; } }
  .tabbed-chart nav {
    margin: 0 auto;
    padding: 0;
    flex-wrap: wrap; }
    @media print, screen and (min-width: 48em) {
      .tabbed-chart nav {
        flex-wrap: nowrap; } }
    .tabbed-chart nav .tab-title {
      width: 100%;
      max-width: none;
      margin: 0.5% 0; }
      @media print, screen and (min-width: 48em) {
        .tabbed-chart nav .tab-title {
          max-width: 19.5%;
          min-width: 19.5%;
          width: auto;
          margin: 0 0.1%; } }
  .tabbed-chart .rate .cell {
    position: relative;
    padding: 1rem 0 1.25rem; }
    @media print, screen and (min-width: 48em) {
      .tabbed-chart .rate .cell {
        padding: 1.5rem 0 1.75rem; } }
    .tabbed-chart .rate .cell:after {
      content: '';
      border-bottom: 1px dashed #A8A8A8;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0; }
    .tabbed-chart .rate .cell:last-child {
      padding-bottom: 0; }
      .tabbed-chart .rate .cell:last-child:after {
        border: none; }
    .tabbed-chart .rate .cell p, .tabbed-chart .rate .cell h2, .tabbed-chart .rate .cell h3, .tabbed-chart .rate .cell span {
      margin: 0;
      padding: 0; }
    .tabbed-chart .rate .cell h2 {
      font-size: 4.0625rem;
      line-height: 1; }
      @media print, screen and (min-width: 40em) {
        .tabbed-chart .rate .cell h2 {
          font-size: 2.5rem; } }
      @media screen and (min-width: 64em) {
        .tabbed-chart .rate .cell h2 {
          font-size: 3.4375rem; } }
      @media screen and (min-width: 75em) {
        .tabbed-chart .rate .cell h2 {
          font-size: 4.0625rem; } }
    .tabbed-chart .rate .cell h3 {
      border-bottom: 6px solid #00D1AF;
      margin-bottom: 1rem; }
      @media print, screen and (min-width: 48em) {
        .tabbed-chart .rate .cell h3 {
          margin-bottom: 2rem; } }
    .tabbed-chart .rate .cell a.button {
      width: 100%;
      margin: 0.5rem 0; }
  .tabbed-chart .rate-carousel {
    overflow: visible; }
    .tabbed-chart .rate-carousel.mint-arrow .slick-next::before, .tabbed-chart .rate-carousel.mint-arrow .slick-prev::before {
      border-color: #00D1AF; }
    .tabbed-chart .rate-carousel .rate {
      padding: 0 1rem;
      margin: 0; }
      @media print, screen and (min-width: 48em) {
        .tabbed-chart .rate-carousel .rate {
          padding: 0 1.5rem; } }
    @media screen and (min-width: 64em) {
      .tabbed-chart .rate-carousel .slick-track {
        width: 100% !important; } }
    .tabbed-chart .rate-carousel .slick-slide {
      outline: 0;
      /*&:first-child {
			.rate {
				padding-left: 0;
			}	
		}
		&:last-child {
			.rate {
				padding-right: 0;
			}
		}*/ }
    .tabbed-chart .rate-carousel .slick-next, .tabbed-chart .rate-carousel .slick-prev {
      position: absolute;
      width: 2rem;
      height: 2rem;
      top: 15%;
      cursor: pointer;
      text-indent: -99999999px;
      color: transparent;
      outline: 0; }
      .tabbed-chart .rate-carousel .slick-next::before, .tabbed-chart .rate-carousel .slick-prev::before {
        position: absolute;
        width: 65%;
        height: 65%;
        top: 50%;
        left: 50%;
        border-width: 0;
        border-style: solid;
        border-color: #FFFFFF;
        content: "";
        transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(0.8);
        transition: transform 175ms cubic-bezier(0.35, 0.28, 0.54, 0.96); }
      .tabbed-chart .rate-carousel .slick-next:hover::before, .tabbed-chart .rate-carousel .slick-prev:hover::before {
        transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1); }
    .tabbed-chart .rate-carousel .slick-next {
      left: 100%; }
      .tabbed-chart .rate-carousel .slick-next::before {
        border-right-width: 3px;
        border-top-width: 3px; }
    .tabbed-chart .rate-carousel .slick-prev {
      right: 100%; }
      .tabbed-chart .rate-carousel .slick-prev::before {
        border-left-width: 3px;
        border-bottom-width: 3px; }
    .tabbed-chart .rate-carousel .slick-dots {
      width: 100%;
      margin: 2rem 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      list-style-type: none; }
      .tabbed-chart .rate-carousel .slick-dots a, .tabbed-chart .rate-carousel .slick-dots button {
        position: relative;
        width: 2rem;
        height: 0.1875rem;
        margin: 0 .25rem;
        text-indent: -99999999px;
        cursor: pointer;
        outline: 0;
        transition: background-color 175ms cubic-bezier(0.35, 0.28, 0.54, 0.96); }
        .tabbed-chart .rate-carousel .slick-dots a:not(.accent-background), .tabbed-chart .rate-carousel .slick-dots button:not(.accent-background) {
          background-color: #E6E7E8; }
        .tabbed-chart .rate-carousel .slick-dots a::after, .tabbed-chart .rate-carousel .slick-dots button::after {
          position: absolute;
          width: 100%;
          height: 500%;
          top: -200%;
          display: block;
          content: ""; }

.jump-link-accordion {
  padding: 3rem 1.8rem; }
  @media screen and (min-width: 64em) {
    .jump-link-accordion {
      padding: 4rem; } }
  .jump-link-accordion .jump-links {
    position: relative;
    padding-bottom: 2rem;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .jump-link-accordion .jump-links {
        margin-bottom: 4rem; } }
    .jump-link-accordion .jump-links:after {
      content: '';
      border-bottom: 2px dashed;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0; }
    .jump-link-accordion .jump-links h4 {
      margin-bottom: 0.5rem; }
    .jump-link-accordion .jump-links .jump-link a {
      margin: 1rem 0;
      display: block;
      font-size: 1.25rem; }
  .jump-link-accordion .jumped-link-section {
    margin-bottom: 4rem; }
    .jump-link-accordion .jumped-link-section:last-child {
      margin-bottom: 0; }
    .jump-link-accordion .jumped-link-section .accordion {
      padding: 0;
      margin: 0; }
    .jump-link-accordion .jumped-link-section h4 {
      margin-bottom: 2rem; }

.management {
  padding: 0; }
  .management h3 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .management h3 {
        font-size: 2.5rem; } }
  .management .members {
    margin-top: 2rem; }
    .management .members .member {
      margin-bottom: 3rem; }
      .management .members .member img {
        margin-bottom: 1rem;
        width: 100%; }
        @media print, screen and (min-width: 48em) {
          .management .members .member img {
            max-width: 200px; } }
      .management .members .member p {
        margin: 0; }

.board-of-directors {
  padding: 0; }
  .board-of-directors h3 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .board-of-directors h3 {
        font-size: 2.5rem; } }
  .board-of-directors .member {
    margin-top: 2rem;
    margin-bottom: 3rem; }
    .board-of-directors .member:last-child {
      margin-bottom: 0; }
    .board-of-directors .member h5 {
      margin-bottom: 0.5rem; }
    .board-of-directors .member img {
      margin-bottom: 1rem; }
    .board-of-directors .member p {
      margin: 0; }

.news-list {
  padding: 3rem 1.8rem; }
  @media screen and (min-width: 64em) {
    .news-list {
      padding: 4rem; } }
  .news-list h3 {
    font-weight: bold;
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 40em) {
      .news-list h3 {
        font-size: 2.5rem; } }
  .news-list .news-item {
    border-bottom: 2px dashed #A8A8A8;
    padding-bottom: 3rem;
    margin-top: 3rem; }
    .news-list .news-item:last-child {
      border: none;
      padding-bottom: 0; }
    .news-list .news-item .grid-x {
      display: flex;
      align-items: center; }
      .news-list .news-item .grid-x p, .news-list .news-item .grid-x a {
        margin: 0; }
      @media screen and (max-width: 47.9375em) {
        .news-list .news-item .grid-x p {
          margin-bottom: 1rem; } }

.news-story-default-content {
  margin-top: 4rem; }
  .news-story-default-content .about-motus {
    border-top: 2px dashed #A8A8A8;
    padding: 3rem 0; }
    .news-story-default-content .about-motus:last-child {
      padding-bottom: 0; }
  .news-story-default-content h6 {
    margin-bottom: 2rem; }
  .news-story-default-content p {
    margin: 0; }

.quad-container {
  padding: 3rem 1.8rem; }
  @media screen and (min-width: 64em) {
    .quad-container {
      padding: 4rem; } }
  .quad-container .quad-grid .cell {
    margin-bottom: 2rem; }
    .quad-container .quad-grid .cell p {
      margin: 2rem 0; }

.concerns {
  align-items: center;
  border-top: 1px dashed;
  padding-top: 2rem;
  margin-top: 1rem; }
  .concerns p {
    margin: 0; }
  .concerns .question {
    position: relative; }
    @media screen and (max-width: 47.9375em) {
      .concerns .question {
        margin: 2rem auto 0; } }
    .concerns .question:after {
      content: '>';
      font: 24px "Consolas", monospace;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      right: 10px;
      top: 0;
      position: absolute;
      pointer-events: none; }

[data-module="sections"] [data-module-section] {
  display: none; }

[data-module="sections"] [data-module-section][data-module-default] {
  display: inline-flex;
  width: 100%; }

.concerns.separateur {
  padding-top: 0rem;
  margin-top: -3rem; }

.separateur {
  padding-top: 0rem;
  margin-top: -1rem; }

.bg-check {
  background-color: #f3f3f4; }

#contactform input[type="text"], #contactform select {
  margin: 0 0 1rem;
  max-height: none; }

#contactform .g-recaptcha {
  margin-bottom: 2rem; }

input[type="text"], select {
  padding: 0;
  box-shadow: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-width: 2px;
  background-color: transparent;
  margin: 0;
  max-height: 32px; }

label {
  font-size: 1rem; }

select {
  color: #C1C1C1;
  padding-right: 2rem;
  background-image: none;
  /* IE11 hide native button */ }
  select option {
    color: #414042;
    padding-right: 2rem;
    overflow: hidden; }
    select option:first-child {
      color: #C1C1C1; }
  select:focus {
    color: #414042; }
  select::-ms-expand {
    display: none; }

.select {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .select {
      margin: 2rem auto 0; } }
  .select:after {
    content: '>';
    font: 24px "Consolas", monospace;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 10px;
    bottom: 20px;
    position: absolute;
    pointer-events: none;
    color: #00D1AF; }

fieldset .check-container {
  display: block; }
  @media print, screen and (min-width: 40em) {
    fieldset .check-container {
      display: flex; } }

fieldset .check-field {
  margin: 1rem 0;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Hide the browser's default checkbox */
  /* Create a custom checkbox */
  /* On mouse-over, add a grey background color */ }
  @media screen and (min-width: 64em) {
    fieldset .check-field {
      margin: 0.8rem 0; } }
  fieldset .check-field:last-child:not(:nth-child(1)) {
    margin: 0; }
    @media print, screen and (min-width: 48em) {
      fieldset .check-field:last-child:not(:nth-child(1)) {
        margin: 0.8rem 0; } }
  fieldset .check-field input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin: 0;
    width: 20px;
    height: 20px;
    z-index: 999;
    /* When the checkbox is checked, add a mint background */ }
    fieldset .check-field input:checked ~ .checkmark, fieldset .check-field input:checked ~ .checkmark-radio {
      background-color: #00D1AF;
      /* Show the checkmark when checked */ }
      fieldset .check-field input:checked ~ .checkmark:after, fieldset .check-field input:checked ~ .checkmark-radio:after {
        display: block; }
  fieldset .check-field .checkmark {
    height: 20px;
    width: 20px;
    background-color: #A8A8A8;
    position: relative;
    /* Create the checkmark/indicator (hidden when not checked) */ }
    fieldset .check-field .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 7px;
      top: 3px;
      width: 6px;
      height: 11px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg); }
  fieldset .check-field .checkmark-radio {
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    position: relative; }
    fieldset .check-field .checkmark-radio:after {
      content: "";
      position: absolute;
      display: none;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #FFFFFF; }
  fieldset .check-field:hover input ~ .checkmark, fieldset .check-field:hover input ~ .checkmark-radio {
    background-color: #C1C1C1; }
  fieldset .check-field label {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 30px; }

.with-label {
  position: relative; }
  .with-label.promo, .with-label.gic-application, .with-label.side-by-side, .with-label.content-switcher, .with-label.abm-finder {
    padding-top: 4rem; }
    .with-label.promo .section-label, .with-label.gic-application .section-label, .with-label.side-by-side .section-label, .with-label.content-switcher .section-label, .with-label.abm-finder .section-label {
      left: 1.8rem;
      z-index: 999; }
      @media screen and (min-width: 64em) {
        .with-label.promo .section-label, .with-label.gic-application .section-label, .with-label.side-by-side .section-label, .with-label.content-switcher .section-label, .with-label.abm-finder .section-label {
          left: 4rem; } }
  .with-label.accessibility {
    padding-bottom: 0rem; }
  .with-label.side-by-side {
    padding-top: 1rem; }
    @media screen and (min-width: 64em) {
      .with-label.side-by-side {
        padding-top: 0; } }
  .with-label .section-label {
    position: absolute;
    top: 0.875rem;
    left: 2.425rem;
    z-index: 999; }
    @media screen and (min-width: 64em) {
      .with-label .section-label {
        left: 4.9375rem; } }
    .with-label .section-label span {
      border-top: 0.625rem solid;
      padding-top: 0.3125rem;
      font-size: 1.25rem;
      font-weight: 800; }

.heading-label {
  border-top: 0.33rem solid;
  padding-top: 0.3125rem;
  font-size: 1.25rem;
  font-weight: 800; }

.chart-wrapper {
  padding-top: 4rem; }

#barChart-static {
  display: flex;
  height: 12rem;
  margin-bottom: 2rem;
  justify-content: space-around;
  align-items: flex-end;
  border-bottom: 2px solid #dadada; }
  @media screen and (max-width: 680px) {
    #barChart-static {
      flex-direction: column;
      border-left: 2px solid #dadada;
      border-bottom: none;
      justify-content: space-evenly;
      align-items: flex-start;
      height: auto;
      margin: 0 1rem 0 20%; } }
  @media screen and (max-width: 550px) {
    #barChart-static {
      margin: 0 1rem 0 30%;
      transition: margin 2s; } }
  #barChart-static .bar {
    position: relative;
    background-color: #dadada;
    text-align: center;
    margin: 0 0.5%;
    animation: barsUp 0.75s ease-in 1;
    transform-origin: center bottom; }
    @media screen and (min-width: 680px) {
      #barChart-static .bar {
        width: 9% !important;
        min-height: 35px; } }
    @media screen and (max-width: 680px) {
      #barChart-static .bar {
        height: 4rem !important;
        text-align: left;
        margin: 1rem 0;
        animation: barsRight 0.75s ease-in 1;
        transform-origin: center left;
        min-width: 43px; } }
    #barChart-static .bar:first-child {
      background-color: #00d1af;
      margin: 0 auto;
      color: #00d1af; }
      @media screen and (max-width: 680px) {
        #barChart-static .bar:first-child {
          margin: 1rem 0; } }
    #barChart-static .bar:last-child {
      margin-right: auto; }
    #barChart-static .bar .rate-value {
      color: black;
      position: absolute;
      bottom: 0;
      margin-bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      transition: font-size 1s;
      animation: fadeIn 2s .75s forwards 1;
      opacity: 0; }
      @media screen and (max-width: 720px) {
        #barChart-static .bar .rate-value {
          font-size: 2vw; } }
      @media screen and (max-width: 680px) {
        #barChart-static .bar .rate-value {
          left: auto;
          margin: 0;
          transform: none;
          bottom: 30%;
          margin-left: 1vw;
          font-size: 16px; } }
      @media screen and (max-width: 420px) {
        #barChart-static .bar .rate-value {
          font-size: 13px; } }
    #barChart-static .bar .rate-provider {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-top: 1rem;
      text-align: center;
      transform: translateX(-50%);
      animation: fadeIn 2s .75s forwards 1;
      opacity: 0; }
      @media screen and (max-width: 840px) {
        #barChart-static .bar .rate-provider {
          margin-top: 1.5rem;
          font-size: 14px;
          transform: translateX(-50%) rotate(-40deg);
          line-height: 1rem; } }
      @media screen and (max-width: 680px) {
        #barChart-static .bar .rate-provider {
          top: 0%;
          left: auto;
          right: 100%;
          margin-right: 1rem;
          text-align: right;
          transform: none;
          animation: fadeIn 2s 1s forwards 1; }
          #barChart-static .bar .rate-provider .break-line {
            display: block; } }

@keyframes barsUp {
  0% {
    transform: scaleY(0); }
  100% {
    transform: scaleY(1); } }

@keyframes barsRight {
  0% {
    transform: scaleX(0); }
  100% {
    transform: scaleX(1); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media screen and (max-width: 63.9375em) {
  .grid-container {
    margin-left: 1.55rem;
    margin-right: 1.55rem; } }

@media screen and (max-width: 320px) {
  .grid-container {
    margin-left: inherit;
    margin-right: inherit; } }

a.back-home-bottom {
  color: #000000;
  font-weight: 600;
  font-size: 0.9rem;
  text-decoration: none; }
  a.back-home-bottom:after {
    display: none; }
  a.back-home-bottom:before {
    display: inline-block;
    content: '';
    width: 25px;
    height: 10px;
    margin-right: 1rem;
    background: url("../img/story-arrow.png") no-repeat scroll 50% transparent;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  a.back-home-bottom:hover {
    text-decoration: underline; }

.story {
  margin-bottom: 4rem; }
  .story h1, .story h2, .story h3, .story h4, .story h5, .story h6 {
    margin-bottom: 0.5rem; }
  .story.featured h2 {
    font-size: 1.25rem; }
    @media print, screen and (min-width: 40em) {
      .story.featured h2 {
        font-size: 2.5rem; } }
  .story h2 {
    font-size: 1.25rem;
    font-weight: normal; }
    @media print, screen and (min-width: 40em) {
      .story h2 {
        font-size: 1.875rem; } }
    .story h2 a {
      font-weight: normal;
      text-decoration: none;
      font-size: inherit; }
      .story h2 a:hover {
        text-decoration: underline; }
  .story ul.categories {
    margin-left: 0;
    min-height: 20px; }
    @media screen and (max-width: 25em) {
      .story ul.categories {
        width: 100%; } }
    .story ul.categories li {
      font-size: 2rem;
      line-height: 0.5;
      list-style-position: inside;
      float: left;
      width: auto;
      padding: 0;
      font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif; }
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .story ul.categories li {
          float: none;
          line-height: 0.8; } }
      .story ul.categories li.secondary-category {
        list-style-type: none;
        margin-left: 1.2rem; }
        .story ul.categories li.secondary-category a {
          margin-left: 0; }

@-moz-document url-prefix() {
  .story ul.categories li.secondary-category a {
    margin-left: 0.5rem; } }
          @supports (-webkit-overflow-scrolling: touch) {
            .story ul.categories li.secondary-category a {
              /* CSS specific to iOS devices */
              margin-left: 0; } }
      .story ul.categories li a {
        vertical-align: middle;
        font-size: 0.8rem;
        margin-left: -1.5rem; }

@-moz-document url-prefix() {
  .story ul.categories li a {
    margin-left: 0; } }
        @supports (-webkit-overflow-scrolling: touch) {
          .story ul.categories li a {
            /* CSS specific to iOS devices */
            margin-left: -0.65rem; } }
      @supports (-webkit-overflow-scrolling: touch) {
        .story ul.categories li {
          /* CSS specific to iOS devices */
          float: none; } }
  .story .date {
    font-weight: 600;
    font-size: 0.8rem;
    margin-right: 1rem; }
  @media screen and (max-width: 25em) {
    .story .date-container {
      width: 100%; } }
  .story a {
    color: #000000;
    font-weight: 600;
    text-decoration: underline; }
    .story a:hover {
      text-decoration: none; }
    .story a.back-home-top {
      color: #000000;
      font-size: 0.8rem;
      font-weight: 600; }
    .story a.read-more, .story a.back-home-bottom {
      text-decoration: none;
      font-size: 0.9rem; }
      .story a.read-more:hover, .story a.back-home-bottom:hover {
        text-decoration: underline; }
      .story a.read-more:after, .story a.back-home-bottom:after {
        display: inline-block;
        content: '';
        width: 25px;
        height: 10px;
        margin-left: 1rem;
        background: url("../img/story-arrow.png") no-repeat scroll 50% transparent; }
    .story a.back-home-bottom:after {
      display: none; }
  .story .background-image {
    background-position: center center;
    background-size: cover;
    background-repeat: none;
    width: 100%;
    height: 300px;
    margin-bottom: 1.2rem; }
    .story .background-image.image-left {
      background-position-x: left; }
    .story .background-image.image-right {
      background-position-x: right; }

.category-breadcrumb {
  margin-bottom: 3rem; }

.story-container {
  position: relative; }
  .story-container .story {
    margin-bottom: 0; }
  .story-container h1, .story-container h2, .story-container h3, .story-container h4, .story-container h5, .story-container h6 {
    margin-bottom: 0.5rem; }

.related-stories {
  margin-top: 1rem; }
  @media print, screen and (min-width: 40em) {
    .related-stories {
      margin-top: 4rem; } }

.story-main {
  min-height: 31.25rem;
  background-color: #FFFFFF;
  padding: 2rem 1.8rem 0;
  margin-top: 0; }
  @media screen and (max-width: 47.9375em) {
    .story-main {
      min-height: 0; } }
  @media print, screen and (min-width: 40em) {
    .story-main {
      padding: 2rem 4rem 0; } }
  @media print, screen and (min-width: 48em) {
    .story-main {
      padding: 3rem 5rem 0; } }
  .story-main ul.categories li {
    float: left; }

@-moz-document url-prefix() {
  .story-main ul.categories li.secondary-category a {
    margin-left: 0.5rem; } }
    @supports (-webkit-overflow-scrolling: touch) {
      .story-main ul.categories li.secondary-category a {
        /* CSS specific to iOS devices */
        margin-left: 0; } }

@-moz-document url-prefix() {
  .story-main ul.categories li a {
    margin-left: 0; } }
    @supports (-webkit-overflow-scrolling: touch) {
      .story-main ul.categories li a {
        /* CSS specific to iOS devices */
        margin-left: -0.65rem; } }
    @supports (-webkit-overflow-scrolling: touch) {
      .story-main ul.categories li {
        /* CSS specific to iOS devices */
        float: none; } }
  .story-main .author {
    font-weight: 600;
    font-size: 0.8rem;
    margin-right: 1rem; }
  .story-main .author-container {
    float: right;
    width: auto; }
    @media screen and (max-width: 25em) {
      .story-main .author-container {
        float: left;
        width: 100%; } }
    .story-main .author-container .author {
      margin-right: 0; }
    .story-main .author-container a {
      color: #000000;
      font-size: 0.8rem;
      font-weight: 600; }
  .story-main .story-content {
    margin: 1rem auto 2rem; }
    .story-main .story-content p {
      margin-bottom: 2rem;
      color: #545558; }
    .story-main .story-content .story-quote {
      font-style: italic;
      margin: 2rem auto; }
  .story-main .back-to-categories {
    line-height: 1.2; }
    @media screen and (max-width: 25em) {
      .story-main .back-to-categories {
        width: 100%; } }

.video-block {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px; }
  .video-block iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.contributor-author-box {
  margin: 3rem auto; }
  .contributor-author-box h2 {
    margin-left: 0px;
    font-size: 1.64628rem;
    margin-bottom: 0;
    text-transform: uppercase; }
    @media print, screen and (min-width: 40em) {
      .contributor-author-box h2 {
        font-size: 1.76388rem;
        margin-left: 20px; } }
  .contributor-author-box p {
    margin-left: 0; }
    @media print, screen and (min-width: 40em) {
      .contributor-author-box p {
        margin-left: 20px; } }
  .contributor-author-box hr {
    width: 75px;
    margin: 0.5rem auto;
    border-color: #00D1AF;
    border-width: 3px; }
    @media print, screen and (min-width: 40em) {
      .contributor-author-box hr {
        margin-left: 20px; } }
  .contributor-author-box img {
    border-radius: 150px;
    width: 150px;
    height: 150px;
    margin-bottom: 1rem; }
    @media print, screen and (min-width: 40em) {
      .contributor-author-box img {
        margin: 0; } }

.search-result-field {
  margin-top: 40px; }

.search-result-box {
  display: block;
  margin: 45px 0; }
  .search-result-box h3 {
    margin-bottom: 10px; }
  .search-result-box span {
    text-transform: uppercase;
    font-size: 14px;
    display: block;
    font-weight: bold;
    margin-bottom: 10px; }
  .search-result-box a:hover {
    text-decoration: underline; }

.button.search-goback {
  margin: 0 auto;
  display: block;
  max-width: 200px; }

.add-padding {
  padding: 3rem 1.8rem; }
  @media screen and (min-width: 64em) {
    .add-padding {
      padding: 4rem; } }

.no-margin {
  margin-top: 0;
  margin-bottom: 0; }
