$header-mobile-transition-duration: 145ms;
$header-mobile-transition-ease: cubic-bezier(.55, .43, .41, .85);
$header-mobile-transition: $header-mobile-transition-duration $header-mobile-transition-ease;
$header-nav-transition-duration: 85ms;
$header-nav-transition-ease: cubic-bezier(.55, .43, .41, .85);
$header-nav-transition: $header-nav-transition-duration $header-nav-transition-ease;
$medium-gutter-width: rem-calc(map-get($grid-column-gutter, medium)) / 2;
$small-gutter-width: rem-calc(map-get($grid-column-gutter, small)) / 2;

.mobile-header {
    margin-top: 1rem;
    .logo {
        svg {
            width: rem-calc(180);
            max-height: rem-calc(55);
        }
    }
}

.global-header {
    position: fixed;
    width: 100%; 
    top: auto;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: $header-height-mobile;
    border-bottom: 0;
    @include breakpoint(xlarge) {
        height: $header-height;
        top:0;
        bottom: auto;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    .navigation {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        // max-width: 1250px;
        margin: 0 auto;
        font-size: rem-calc(14);
        flex: 1 1 auto;
        z-index: 3;
        padding: 0;
        // background-color: color('white');
        // box-shadow: 0 0 rem-calc(4) rgba(color('black'), .2);
        &> nav {
            width: 100%;
            @include breakpoint(xlarge) {
                width: 80%;
            }
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            width: 100%;
            li {
                width: 25%;
                text-align: center;
                height: $header-height-mobile;
                display: flex;
                align-items: stretch;
                position: relative;
				padding: 0;
                &.search-form {
                    width: 32%;
                    @include breakpoint(xxlarge) {
                        width: 25%;
                    }
                }
                @include breakpoint(xlarge) {
                    width: 16.667%;
                    height: $header-height;
                }
                .tertiary {
                    position: absolute;
                    top: auto;
                    bottom: 0;
                    left: 0;
                    transform: translateY(150%);
                    width: 100%;
                    @include breakpoint(xlarge) {
                        top: 0;
                        bottom: auto;
                        transform: translateY(-150%);
                    }
                    &.signin{
                        right: 0;
                        left: auto;
                        width: 401%;
                        padding: 2rem;
                        text-align: left;
                        font-size: 1rem;
                        color: color('carbon');
                        border: 1px solid color('white');
						border-right: none;
						@include breakpoint(large down) {
							border-bottom: none;
						}
						@include breakpoint(xlarge) {
							border-top: none;
						}
                        /*@media screen and (max-width: 319px) {
                            min-width: 301px;
                        }
                        @media screen and (min-width: 320px) and  (max-width: 359px) {
                            min-width: 321px;
                        }
                        @media screen and (min-width: 360px) and  (max-width: 374px) {
                            min-width: 361px;
                        }
                        @media screen and (min-width: 375px) {
                            min-width: 376px;
                        }
                        @media screen and (min-width: 414px) {
                            min-width: 415px;
                        }*/
                        @include breakpoint(medium) {
                            min-width: 305px;
							width: 200%;
                        }
                        p{
                            font-weight: bold;
                            margin: 0;
                        }
                        a {
                            border: none;
                            text-align: left;
                            display: block;
                            margin-top: 0.5rem;
							text-transform:none;
							font-size: rem-calc(12);
                            &:hover{
                                text-decoration: underline;
                            }
                            &:before{
                                display: none;
                            }
                        }
                        div.form-div {
                            display: block;
                            input {
                                // background: color('light-gray');
                                color: color('darkest-gray');
                                border-bottom: 2px solid color('dark-grey');
                                margin-top: 1.5rem;
                                padding-left: 1rem;
                                width: 100%;
                                padding-top: 0;
                                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                    color: color('white');
                                    letter-spacing: 1px;
                                }
                                &::-moz-placeholder { /* Firefox 19+ */
                                    color: color('white');
                                    letter-spacing: 1px;
                                }
                                &:-ms-input-placeholder { /* IE 10+ */
                                    color: color('white');
                                    letter-spacing: 1px;
                                }
                                &:-moz-placeholder { /* Firefox 18- */
                                    color: color('white');
                                    letter-spacing: 1px;
                                }
                                &::placeholder { /* Firefox 18- */
                                    color: color('white');
                                    letter-spacing: 1px;
                                }
                            }
							label{
								color: color('carbon');
							}
							[type="text"], [type="password"] {
								padding: 0;
								font-size: rem-calc(12);
							}
                            [type="submit"] {
                                text-indent:inherit;
                                background-image: none;
                                width: auto;
                                height: auto;
                                position: relative;
                                top: 0;
                                right: 0;
								margin: 1rem 0;
                                text-transform: uppercase;
                            }
                            /* Customize the label (the container) */
                            .container {
                              display: block;
                              position: relative;
                              padding-left: 20px;
                              margin: 0.5rem 0 0;
                              cursor: pointer;
                              font-size: 0.8rem;
                              -webkit-user-select: none;
                              -moz-user-select: none;
                              -ms-user-select: none;
                              user-select: none;
                            }

                            /* Hide the browser's default checkbox */
                            .container input {
                              position: absolute;
                              opacity: 0;
                              cursor: pointer;
							  left: 0;
                            }

                            /* Create a custom checkbox */
                            .checkmark {
                              position: absolute;
                              top: 5px;
                              left: 0;
                              height: 12px;
                              width: 12px;
                              background-color: color('white');
                            }

                            /* On mouse-over, add a grey background color */
                            .container:hover input ~ .checkmark {
                              background-color: color('light-gray');
                            }

                            /* When the checkbox is checked, add a blue background */
                            .container input:checked ~ .checkmark {
                              background-color: color('darkest-gray');
                            }

                            /* Create the checkmark/indicator (hidden when not checked) */
                            .checkmark:after {
                              content: "";
                              position: absolute;
                              display: none;
                            }

                            /* Show the checkmark when checked */
                            .container input:checked ~ .checkmark:after {
                              display: block;
                            }

                            /* Style the checkmark/indicator */
                            .container .checkmark:after {
                                left: 4px;
                                top: 1px;
                                width: 4px;
                                height: 8px;
                                border: solid white;
                                border-width: 0 2px 2px 0px;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                        }
                        #forgot-pword {
                            svg {
                                width: 10px;
                                height: 9px;
                            }
                        }
						.signin-submit {
							padding: 0.5rem 1rem;
							margin: 1rem 0;
							width: auto;
							max-width: 120px;
							min-width: 120px;
							text-align: center;
							font-size: 1rem;
							letter-spacing: inherit;
							font-size: rem-calc(12);
						}
                    }
                    .subnav {
                        text-align: center;
                        border: 1px solid color('white');
						@include breakpoint(large down) {
							border-bottom: none;
						}
						@include breakpoint(xlarge) {
							border-top: none;
						}
                        a {
                            text-align: center;
                            padding: 1.5rem 0;
                            border-top: none;
                            border-bottom: 2px dashed #fefefe;
                            font-size: 0.8rem;
                            max-width: 75%;
                            margin: 0 auto;
                            &:last-child {
                                border-bottom: none;
                            }
                            &:hover{
                                color: color('white');
                            }
                            &:before {
                                display: none;
                            }
                            @include breakpoint(medium) {
                                font-size: 0.9rem;
								max-width: 60%;
                            }
                            @include breakpoint(xlarge) {
                                max-width: 80%;
                                text-align: left;
                                justify-content: flex-start;
                            }
							@include breakpoint(xxlarge) {
                                max-width: 70%;
                            }
                        }
                    }
                }
                &:first-child {
                    a {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
        a {
            border-bottom: 6px solid transparent;
            text-align: center;
            padding: 0;
            font-size: 0.8rem;
            position: relative;
            margin: 0;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: center;
            border-top: none;
            transition: border-color 175ms ease;
            &:before{
                content: '';
                //background: url('/assets/img/nav-line.png') repeat-y scroll 0 0 transparent;
				border-left: 1px dashed color('dark-gray');
                height: 100%;
                width: 1px;
                display: block;
                position: absolute;
                top:4px;
                left: 0;
            }
            &:hover {
                border-bottom: 6px solid #00D1AF;
                border-top: none;
            }
            @media screen and (max-width: 374px) {
                padding: 0 0.5rem;
            }
            @include breakpoint(medium) {
                font-size: 0.9rem;
            }
            @include breakpoint(xlarge) {
                font-size: 1rem;
                border-top: 6px solid transparent;
                border-bottom: none;
                &:before{
                    display: none;
                }
                &:hover {
                    border-top: 6px solid #00D1AF;
                    border-bottom: none;
                }
            }
            &.logo {
                padding: 0.5rem 0 0;
                margin: 0 auto;
                display: inline-block;
                border: none;
                width: auto;
                &:hover, &:focus {
                    border: none;
                }
                svg {
                    width: rem-calc(180);
                    max-height: rem-calc(55);
                }
            }
            &.cutout-nav {
                &:before{
                    @include breakpoint(xlarge) {
                        content: '';
                        //background: url('/assets/img/nav-line.png') repeat-y scroll 0 0 transparent;
						border-left: 1px dashed color('dark-gray');
                        height: 100%;
                        width: 1px;
                        display: block;
                        position: absolute;
                        top:-3px;
                        left: -1px;
                    }
                }
            }
        }
        div.form-div {
            display: flex;
            position: relative;
            margin: 0;
            align-items: center;
            justify-content: center;
            label {
                text-indent: -99999px;
                height: 0;
                &.show-label{
                    text-indent: inherit;
                    height: auto;
                }
            }
            :-ms-input-placeholder {
                color: color('medium-gray');
                @include breakpoint(medium down) {
                    color: rgba(color('white'), .4);
                }
            }
            ::webkit-input-placeholder {
                color: color('medium-gray');
                @include breakpoint(medium down) {
                    color: rgba(color('white'), .4);
                }
            }
            ::placeholder {
                color: color('medium-gray');
                @include breakpoint(medium down) {
                    color: rgba(color('white'), .4);
                }
            }
            input {
                padding: 0.5rem 1rem 0 1rem;
                margin: 0;
                width: auto;
                &::placeholder {
                    color: color('dark-gray');
                }
                
                @include breakpoint(medium down) {
                    width: 100%;
                    display: block;
                    padding: .75em 0;
                    box-shadow: unset;
                    background-color: transparent;
                    border-bottom: 1px solid rgba(color('white'), .2);
                    color: color('white');
                    outline: 0;
                }
            }
            [type="submit"] {
                text-indent: -999999px;
                width: 2.25em;
                height: 2em;
                background-position: center 35%;
                background-size: auto 65%;
                background-repeat: no-repeat;
                background-image: url('../media/motusbank/assets/img/icon-search.svg');
                position: absolute;
                top: 50%;
                right: 0;
                margin-top: -14px;
                cursor: pointer;
            }
        }
        .js-open {
            &> a{
                border-bottom: 6px solid #00D1AF;
                border-top: none;
                @include breakpoint(xlarge) {
                    border-top: 6px solid #00D1AF;
                    border-bottom: none; 
                }
            }
            .tertiary {
                transform: translateY(-60px);
                @include breakpoint(xlarge) {
                    transform: translateY(100px);
                }
            }
        }
    }
}


@media screen and (min-width: 1366px) {
    #text-hero-banner{
        margin-left: 16.8vw
    }
}
@media screen and (max-width: 1366px) {
    #text-hero-banner{
        margin-left: 3.3vw
    }
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 3.4vw;
    padding-right: 3.4vw;
}

#a0qr9s-accordion-label{
    margin-left: -0.5vw;
}
#section-tow{
    height: auto;
    padding-bottom: 11.8rem;
}
.find-an-atm{
    text-align: right;
}
a.button-use-my-location{
    width: 100%;
}
span.find-atm-or{
    font-size: 1.3rem
}
#searchBtn{
    max-height: 35px;
}
.click-search{
    padding: 0.7em 2.25em !important;
    margin-right: 0.3rem
}
#bg-gic{
    background-color: #e6e7e9;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
}
.hero-banner {
    margin-bottom: 1.5rem;
}
#bg-search{
    background-image: url('../../assets/img/basic1-015_search_zoom_find.png');
    width: 2.5rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #00D1AF;
    margin-left: 0.75rem
}
.hero-banner-margin-bottom{
    margin-bottom: 0
}
.padding-right{
    padding-right: 4.69rem
}
a.btn-watch-video{
    padding: 0.7rem 2rem 0.7rem 2rem;
    font-size: 1.1rem;
    color: #414042 !important;
}
.promo-content .espace-text{
    padding-right: 10rem;
    padding-left: 4rem;
}

/* Small only */
@media screen and (max-width: 39.9375em) {
    .padding-right{
        padding-right: 0rem
    }
    #good-to-know .panel{
        padding-top: 30vw;
        padding-bottom: 85vw;
    }
    .find-an-atm{
        text-align: center
    }
    a.button-use-my-location, #searchBtn{
        width: 200px;
        margin: auto;
        margin-top: 0.5rem
    }
    button.click-search{
        margin-top: 2rem !important
    }
    .with-label.promo, .with-label.gic-application,  .with-label.content-switcher, .with-label.abm-finder{
        padding-bottom: 0rem
    }
    // .with-label.side-by-side,
    #bg-mobile{
        padding-bottom: 42rem;
        padding-top: 26em
    }
    .espace-text{
        height: 64em;
    }
    .promo-content .espace-text{
        padding-right: 0;
        padding-left: 0;
    }
    #section-tow {
        height: auto;
        padding-bottom: 0rem;
    }
    .padding-bottom-good-to-know{
        padding-bottom: 14rem;
    }
    .bg-mobile-good-to-know{
        padding: 8rem;
    }

}

/* Medium and up */
@media screen and (min-width: 40em) {
    #section-tow {
        height: auto;
        padding-bottom: 0rem;
    }
    #section-tow .promo{
        padding: 3rem 1.8rem 0rem;
    }
}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    #section-tow {
        height: auto;
        padding-bottom: 0rem;
    }
}

/* Large and up */
@media screen and (min-width: 64em) {
    .hero-banner .panel:not(.short) {
        min-height: 24rem;
    }
}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {

}
@media screen and (max-width: 85.3125rem) {
    .padding-right {
        padding-right: 0rem !important;
    }
}

.btn-green-dark{
    padding: 0.7rem 2rem 0.7rem 2rem;
    font-size: 1.1rem;
    color: #414042 !important;
}
#section-tow-investing{
    height: 37.8rem;
}
#section-tow-investing .investing-img{
    margin-top: -4rem;
}
.btn-inesting-green-dark{
    background: #00D1AF !important;
    color: #414042 !important;
}

@media screen and (min-width: 1367px) {
    // .bg-disktop-good-to-know-investing{
    //     left: 26% !important;
    // }
    // #section-reasons img.img-reasons{
    //     top: -14rem;
    //     right: 52% !important;
    // }
}
@media screen and (max-width: 1367px) {
    // .bg-disktop-good-to-know-investing{
    //     left: 5% !important;
    // }
    // #section-reasons img.img-reasons{
    //     right: 11% !important;
    //     top: -14rem;
    // }
}
#Good-to-Know h4, #Good-to-Know .carbon-text{
    color: #fff !important;
}
#Good-to-Know .carbon-border{
    border-color: #fff !important;
}
#section-reasons p{
    font-size: 1.2rem
}
#Secured-unsecured .promo:last-child{
    padding-top: 0
}
#Secured-unsecured h4{
    font-size: 1.875rem
}
#good-to-know-borrowing .carbon-text-dark-gray{
    color: #414042 !important;
}
#Secured-unsecured .carbon-text{
    color: #fff !important;
    border-color: #fff !important;
}
#Secured-unsecured{
    margin-top: 1rem; 
}
#Secured-unsecured .promo:first-child{
    margin-bottom: 0;
    padding-bottom: 0.5rem
}
.protecting-background{
    background-color: #E6E7E8;
}

// .side-by-side {
//     background-color: red;
//     background-image: -webkit-linear-gradient(0deg, #545558 51%, #fff 51%, #00D1AF 51% );
//     min-height: 500px;
// }
