.gic-application{
    padding: 3rem 1.8rem;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    @include breakpoint(xlarge) {
        padding: 4rem;
    }
	h3 {
		margin: 0;
		line-height: 0.8;
		font-size: 2.5rem;
	}
	.hidden {
		display: none;
	}
	.error {
		margin-bottom: rem-calc(30);
		border: 1px solid;
		font-size: 0.8rem;
		@include breakpoint(large down) {
			margin-top: 2rem;
			margin-bottom: rem-calc(12);
			padding: 0.5rem;
		}
	}
    .title {
        position: relative;
        margin-bottom: 6rem;
        @include breakpoint(xlarge) {
            margin-bottom: 0;
			padding-top: 1rem;
			padding-bottom: 1rem;
        }
		&:after {
			content: '';
			border-bottom: 4px dashed color('white');
			width: 100%;
			display: block;
			position: absolute;
			top: auto;
			bottom: -3rem;
			right: 0;
			@include breakpoint(xlarge) {
				height: 100%;
				border-bottom: none;
				border-right: 4px dashed color('white');
				top: 0;
				bottom: auto;
				right: 18px;
			}
		}
        h3 {
			font-size: rem-calc(30);
			padding-right: 2.5rem;
			line-height: 1.2;
        }
    }
	.rate-selection {
		.grid-x {
			align-items: flex-end;
		}
		h6 {
			@include breakpoint(large down) {
				margin-bottom: 1rem;
			}
		}
		.select {
			&:after {
				right: 20px;
				bottom: 2px;
			}
			option {
				&:first-child {
					color: inherit;
				}
			}
		}
		.button {
			margin: 1rem 0 0;
			@include breakpoint(xlarge) {
				margin: 0;
			}
		}
		input {
			margin: 2rem 0 0;
			@include breakpoint(xlarge) {
				margin: 0;
			}
		}
	}
}