$testimonials-transition-duration: 175ms;
$testimonials-transition-ease: cubic-bezier(.35,.28,.54,.96);
$testimonials-transition: $testimonials-transition-duration $testimonials-transition-ease;

.testimonials .panel {
    padding: 5rem 1.8rem 3rem;
    @include breakpoint(xlarge) {
        padding: 5rem 4rem 4rem;
    }
	    h3{
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 2rem;
        @include breakpoint(medium) {
            font-size: 2.5rem;
        }
    }
	h4 {
		margin-bottom: 0.5rem;
	}
}

.testimonial {
  position: relative;
  padding: 0 3rem;
  
  @include breakpoint(medium down) {
    padding-right: 1.5rem; 
  }
  
  svg {
    position: absolute;
    width: 1.75em;
    height: 1.75em;
    top: 0;
    left: 0;
    fill: color('white');
    display: inline-block;
  }
}

.testimonial-carousel {
    margin-top: 3rem;
    overflow: visible;

    &.mint-arrow {
        .slick-next, .slick-prev {
            &::before {
                border-color: $primary-color;
            }
        }
    }

    .slick-slide {
        outline: 0;

        p {
            line-height: 1.8;

            &:first-of-type {
                margin-bottom: 1.25em;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .slick-next, .slick-prev {
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 15%;
        cursor: pointer;
        text-indent: -99999999px;
        color: transparent;
        outline: 0;

        @include breakpoint(medium down) {
            display: none !important;
        }

        &::before {
            position: absolute;
            width: 65%;
            height: 65%;
            top: 50%;
            left: 50%;
            border-width: 0;
            border-style: solid;
            border-color: color('white');
            content: "";
            transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(.8);
            transition: transform $testimonials-transition;
        }

        &:hover::before {
            transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1);
        }
    }

    .slick-next {
        left: 100%;
        margin-left: 1rem;

        &::before {
            border-right-width: 3px;
            border-top-width: 3px;
        }
    }

    .slick-prev {
        right: 100%;
        margin-right: 1rem;

        &::before {
            border-left-width: 3px;
            border-bottom-width: 3px;
        }
    }

    .slick-dots {
        width: 100%;
        margin: 2.5rem 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        /*
    a, button {
      width: .75rem;
      height: .75rem;
      margin: 0 .75rem;
      border-radius: 50%;
      text-indent: -999999px;
      color: transparent;
      background-color: color('white');
    }
    */
        a, button {
            position: relative;
            width: 2rem;
            height: rem-calc(3);
            margin: 0 .25rem;
            text-indent: -99999999px;
            cursor: pointer;
            outline: 0;
            transition: background-color $testimonials-transition;

            &:not(.accent-background) {
                background-color: color('light-gray');
            }

            &::after {
                position: absolute;
                width: 100%;
                height: 500%;
                top: -200%;
                display: block;
                content: "";
            }
        }
    }
}