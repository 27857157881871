	.hero-banner .panel:not(.short){
		padding-left: 4rem;
		padding-right: 4rem
	}
	.global-header .navigation{
    	max-width: 85.375rem;
	}
	.motus-bank-container{
		max-width: 85.375rem;
		margin: auto;
	}
	.motus-bank-row{
		text-align: left;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	#th-motusbank-mortgage-content{
		max-height: 44.625rem;
		min-height: 44.625rem;
	}
	.promo p{
		font-size: 1.25rem
	}
	#header-search{
		max-width: 13.625rem;
		width: 13.625rem;
	}

	.promo.comparison h3{
		font-size: 2.8125rem
	}
	.bg-hero-content{
		margin-right: 2.9rem
	}
	/* Small only */
	@media screen and (max-width: 39.9375em) {
		.bg-hero-content {
			margin-right: 0;
		}
		.hero-banner .panel:not(.short) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		.hero-banner h1{
			font-size: 2.8125rem
		}
		#th-motusbank-mortgage-content {
			max-height: 100%;
			min-height: 100%;
		}
		.grid-x.promo.with-label{
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 18rem
		}
		.promo p {
			line-height: 1.1;
			margin-bottom: 2rem;
		}
		.promo h3 {
			font-size: 1.875rem;
		}
		.promo h3 br{
			display: none
		}
		.small-12.xlarge-7.xxlarge-8.cell.promo-content.padding-bottom-good-to-know h4.heading{
			font-size: 1.875rem;
			padding-left: 2rem;
		}
		.promo .promo-content > :last-child{
			margin-left: 2rem;
		}
		#bg-good-to-know .grid-x.promo.with-label{
			padding-bottom: 1rem
		}
		.page-footer .pre-footer .small-12.large-9.xlarge-10.cell.motus-bank-row{
			padding-left: 0;
			padding-right: 0
		}
		.page-footer .pre-footer{
			padding-left: 0;
			padding-right: 0;
		}
		#rates-content .promo.no-bg{
			padding-left: 0;
			padding-right: 0;
		}
		#rates-content .promo .promo-content > :last-child{
			margin-left: 0rem;
			margin-right: 0;
		}
		#rates-content .grid-container {
			margin-left: 2rem;
			margin-right: 2rem;
			padding-left: 0;
			padding-right: 0;
		}
		#borrowing-content .bg-mobile-banner-borrowing{
			background-position: bottom;
			margin-top: 19rem;
			padding-top: 27rem;
		}
		#borrowing-content .panel{
			margin-bottom: 24rem;
			padding-top: 0 !important;
		}
		#borrowing-content h1 {
			font-size: 1.5625rem;
			padding-top: 2rem;
		}
		#reasons-content img.right.bottom.img-reasons{
			width: 377px;
			left: -8%;
		}
		#reasons-content .grid-x.promo.with-label{
			padding-top: 0rem
		}
		#reasons-content .small-12.medium-6.cell.promo-content.espace-text{
			margin-top: 13rem;
		}
		#reasons-content .promo .promo-content > :last-child{
			margin-right: 0;
		}
		#reasons-content{
			height: 42rem;
		}
		#borrowing-basics-content .grid-x.promo.with-label{
			padding-left: 2rem;
			padding-right: 2rem;
		}
		#borrowing-basics-content h3.title-borrowing-content{
			font-size: 1.875rem !important;
		}
		#borrowing-basics-content h3.title-borrowing-content br{
			display: none
		}
		#borrowing-basics-content .promo .promo-content > :last-child{
			margin-left: 0rem;
		}
		#borrowing-basics-content .grid-x.promo.with-label{
			padding-bottom: 0
		}
		#Secured-unsecured .promo .promo-content > :last-child{
			margin-left: 0 ;
		}
		#Secured-unsecured .title-Secured-unsecured-content{
			font-size: 1.875rem ;
			padding-left: 2rem;
		}
		#Secured-unsecured .promo:last-child{
			padding-bottom: 0rem
		}
		#Secured-unsecured .espace-text {
			height: auto;
		}
		#calculator-your-payments-content h3, #calculator-your-payments-content .promo p{
			padding-left: 2rem;
			padding-right: 2rem
		}
		#iframe-wpu4a77sp{
			margin-left: 0;
		}
		#calculator-your-payments-content .grid-x.promo.with-label{
			padding-bottom: 2rem;
		}
		#protecting-content h3{
			padding-left: 2rem;
			padding-right: 2rem;
			font-size: 1.875rem;
		}
		#protecting-content .promo p{
			padding-left: 2rem;
			padding-right: 2rem;
		}
		#protecting-content .promo p br{
			display: none;
		}
		#protecting-content .grid-x.promo.with-label{
			padding-bottom: 1rem;
		}
		#bg-good-to-know-borrowing-mobile{
			background-size: auto;
			background-position: bottom;
		}
		#good-to-know-borrowing .padding-bottom-good-to-know{
			padding-bottom: 1rem
		}
		#borrowing-reasons .espace-text p{
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			padding-left: 0 !important;
			padding-right: 0 !important;
		}  
	}

	/* Medium and up */
	@media screen and (min-width: 40em) {

	}

	/* Medium only */
	@media screen and (min-width: 40em) and (max-width: 63.9375em) {

	}

	/* Large and up */
	@media screen and (min-width: 64em) {

	}

	/* Large only */
	@media screen and (min-width: 64em) and (max-width: 74.9375em) {

	}
	#borrowing-basics-content h3,#Secured-unsecured h3{
		font-size: 2.8125rem
	}
	#Secured-unsecured {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	.br-calc{
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		min-width: 97%;
	}
	.bg-disktop-good-to-know-investing {
		left: 0 !important;
	}

	/*20190227*/
	/* Large only */
	@media screen and (min-width: 64em) and (max-width: 74.9375em) {
		#reasons-content{
			height: auto;
		}
	}
	#section-reasons .grid-x.promo.with-label{
		padding-bottom: 0;
		padding-top: 0;
	}
	#section-reasons p{
		padding: 2rem;
		padding-left: 3rem;
		padding-right: 2rem;
	}
	div#borrowing-reasons{
		padding-bottom: 0;
		padding-top: 0;
	}
	div#reasons-content{
		padding-bottom: 0rem
	}
	#borrowing-reasons .espace-text p{
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		padding-left: 3rem;
		padding-right: 3rem;
	}  

	div.legal-content{
		padding-left: 2rem;
		padding-right: 2rem
	}
	#tiyb54-accordion{
		padding-left: 0;
		padding-right: 0;
	}
	@media screen and (max-width: 39.9375em) {
		#first-section-contact-us .promo .promo-content > :last-child{
			margin-left: 0rem;
		}
		#how-to-reach-us-content .grid-x.promo.with-label{
			padding-left: 2rem;
			padding-right: 2rem;
		}
		#how-to-reach-us-content .grid-x.promo.with-label{
			padding-bottom: 2rem;
		}
		#question-module-content .grid-x.promo.with-label{
			padding-left: 2rem;
			padding-right: 2rem;
		}
		#question-module-content .grid-x.promo.with-label{
			padding-bottom: 2rem;
		}
		div.legal-content {
			padding-left: 1rem;
			padding-right: 1rem;
		}
		#section-tow-ourStory .promo-content.espace-text{
			padding-left: 2rem;
			padding-right: 2rem;
		}
		#section-tow-ourStory .promo .promo-content > :last-child {
			margin-left: 0rem;
		}
		#section-tow-ourStory .grid-x.promo.with-label{
			padding-bottom: 42rem
		}
		#products-and-rates .grid-x.promo.with-label{
			padding-left: 2rem;
			padding-right: 2rem
		}
		#products-and-rates .promo .promo-content > :last-child {
			margin-left: 0rem;
		}
		#in-conclusion-bg {
			padding-bottom: 0rem !important;
		}
		#in-conclusion-content .grid-x.promo.with-label{
			padding-bottom: 0rem;
			background-attachment: fixed;
		}
		#account-features-content .small-12.medium-7.cell.promo-content.espace-text{
			padding-left: 2rem;
			padding-right: 2rem;
		}
		#account-features-content .espace-text{
			height: auto !important;
		}
		#chequing-hero-content .motus-bank-row{
			padding-left: 0;
			padding-right: 0;
		}
		#chequing-hero-content .grid-x.white-background.panel.panel-hero{
			padding-left: 3.4rem;
			padding-right: 3.4rem;
		}
		#account-features-content .grid-x.promo.with-label{
			padding-bottom: 0
		}
		#motusbank-debit-card .promo .promo-content > :last-child{
			margin-left: 0;
		}
		#Our-ATM-network .promo .promo-content > :last-child{
			margin-left: 0;
		}
		#Good-to-Know-chequing .grid-x.promo.blog.with-label{
			padding-bottom: 0 ;
		}
	}

	#in-conclusion-bg{
		background-position: center top;
		background-repeat: no-repeat;
		background-size: inherit;
		padding-bottom: 11rem;
	}
	#bg-Conclusion h5{
		font-size: 1.5625rem;
	}
	#img-reasons-fafm{
		margin-left: -7rem;
    	padding-top: 0.5rem;
	}
	#account-features-content .grid-x.promo.with-label{
		padding-top: 0;
	}
	.account-features-content{
		padding-bottom: 3rem !important
	}
	.legal-chequing-content{
		padding-left: 3.5rem;
		padding-right: 3.5rem;
	}

/*** Starts Category Blog Pages ***/
@media screen and (min-width: 64em) {
	#category_save{
		padding: 0 5rem;
	}
	#acce_left{
	padding: 0px;
	}
}
.blog_description{
	padding: 4rem;
}
.blog_category{
	padding-left: 3rem;
	padding-right: 3rem;
}
/*** Ends Category Blog Pages ***/


/*** Starts Page 404 ***/
.mod_description h2,
.mod_description h5,
.mod_description h6,
.mod_description .cell{
    font-family: 'Segoe UI' !important;
    text-align: center;
    margin-bottom: 0;
}
.mod_description h2{
    color: #00d1af;
}
.mod_description .margin_buttons{
    margin-top: 2rem;
}

div#Modal1{
    margin-top: 10rem;
    min-height: auto;
	height: auto;
	border: 0.5rem solid;
}
.margin_buttons a{
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    width: 138px;
}
.hero a{
    font-family: 'Segoe UI';
    font-size: 1.1rem;
    color: #414042;
}
/*** Ends Page 404 ***/

/**28/02/2019**/
.hero-banner h1{
	font-size: 5rem;
}
#privacy-content .hero-banner .panel:not(.short){
	padding-top: 3rem;
    min-height: auto !important;
    padding-bottom: 4rem;
}
#privacy-content .hero-banner{
	margin-bottom: 0
}
#privacy-content h1{
	margin-top: 0.8rem;
}
#page-404-content a{
	padding-left: 3.2rem;
    padding-right: 3.2rem;
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
}
#Modal1 .close-button, #Modal1.close-button.medium {
    right: 0.5rem;
    top: 0rem;
    font-size: 2em;
    line-height: 1;
}
#Modal1 .close-button {
    position: absolute;
    color: #8a8a8a;
    cursor: pointer;
}
#Modal1 .close-button span{
	font-size: 2.2rem;
	font-weight: bold;
	color: #00D1AF;
}

/*** Starts CDIC Page ***/
#cdic_img{
	bottom: 12rem;
	left: -8rem;
}
@media screen and (max-width: 39.9375em) {
	#cdic_img_mob{
		bottom: 8rem;
		left: -4rem;
		top: 4rem;
	}
	
	.cdic_title{
		padding-top: 6rem;
		font-size: 3rem;
	}
	.desc_bottom{
		padding-bottom: 114px;
	}
}
.wtb_list ol{
	font-family: 'Segoe UI';
}
/*** Ends CDIC Page ***/

#iframe-tabs a.tab_title::after{
	position: absolute;
    width: 1em;
    height: 1em;
    top: 100%;
    left: 50%;
    margin-left: -.5em;
    margin-top: -.5em;
    opacity: 1;
    content: "";
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: inherit;
    transition: opacity 150ms ease;
}
#member-services{
	padding-left: 0;
	padding-right: 0;
}
#member-services .motus-bank-row.padding-right-none {
    padding-right: 0;
}

@media screen and (max-width: 39.9375em) {
	.blog_description {
		padding: 0.5rem;
	}
	
	#l_padding{
		margin-left: 25px;
	}
}

/*** Starts Page Corporate Governance ***/
@media screen and (min-width: 64em){
	.board_of_directors .accordion .accordion-item .accordion-title:before {
    right: 35.5rem;
	}
}
/*** Ends Page Corporate Governance ***/

#acce_bottom{
	margin-left: 0;
}
#acce_padding{
	padding-bottom: 0rem;
}

/*issus 01/03/2019*/
#savings-content .bg-hero-content{
	margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.hero-banner h1.size-60{
	font-size: 3.75rem;
}
#saving-rates .rate-carousel .rate .cell .heading{
	display: inline;
}
#saving-rates .rate-carousel .rate .cell:nth-child(2):after{
	margin-bottom: 2rem;
	border-bottom: 2px dashed #A8A8A8;
}
#saving-rates .rate-carousel .rate .cell a.button{
	width: auto !important;
}
#Savings-account-features .promo.comparison h3{
	font-size: 3.125rem !important;
}
#Savings-account-features .promo.comparison li{
	margin-bottom: 0.5rem;
	font-size: 1.25rem;
	letter-spacing: 0.4px;
}
#Savings-account-features .small-12.xlarge-8.xlarge-offset-4.cell.promo-content{
	padding-left: 3rem;
}
#Savings-account-features ul{
	margin-left: 0.8rem;
}
#Savings-account-features ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title)::before{
	margin-right: 4rem;
	color: #414042;
}
#registered-plans h3 {
    font-size: 2.5rem !important;
    padding-right: 12rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
#registered-plans h5{
	font-size: 1.875rem;
}
.motus-bank-row-footer{
	padding-left: 0.4rem;
	padding-right: 0.4rem;
}
h4.white-text.find-an-atm{
	padding-right: 0.5rem
}
#savings-content .mortgage-banner{
	padding-top: 2rem;
	padding-bottom: 2rem;
}
@media screen and (max-width: 39.9375em) {
	.motus-bank-row-footer {
		padding-left: 1.6rem;
		padding-right: 0rem;
	}
}