.with-btn {
    position: relative;
    padding-bottom: 3rem;
    &.comparison {
        padding-bottom: 240px;
        @include breakpoint(medium) {
            padding-bottom: 290px;
        }
        @include breakpoint(large) {
            padding-bottom: 340px;
        }
        @include breakpoint(xlarge) {
            padding-bottom: 4rem;
        }
    }
    .background-image {
        @include breakpoint(large down) {
            margin-bottom: 2rem;
        }
    }
    .long-btn{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        display: block;
        a {
            &.button {
                margin: 0;
                width: 100%;
                font-weight: normal;
                letter-spacing: 1px;
                &:hover {
                    padding: 1em 1.5em;
                }
            }
        }
    }
}