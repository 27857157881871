.iframe-tabs {
	nav {
		display: flex;
		flex-wrap:wrap;
		@include breakpoint(large) {
			flex-wrap: nowrap;
    	}
		margin-bottom: 3rem;
	}
	.tab-title {
		position: relative;
		max-width: 32%;
		margin-right: .35em;
		margin-bottom: .5em;
		padding: 0.66em 3em;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 1rem;
		text-align: center;
		text-decoration: none;
		transition: background-color 175ms ease;
		&:not(.carbon-background) {
			background-color: color('accent');
		}
		@include breakpoint(medium down) {
			width: 100%;
			max-width: none;
			padding: .33em;
		}
		&::after {
			position: absolute;
			width: 1em;
			height: 1em;
			top: 100%;
			left: 50%;
			margin-left: -.5em;
			margin-top: -.5em;
			opacity: 0;
			content: "";
			transform: rotate(45deg);
			background-color: inherit;
			transition: opacity 150ms ease;
			@include breakpoint(medium down) {
				display: none;
			}
		}
		&:hover,
		&:focus,
		&.js-active {
			&::after {
				opacity: 1;
			}
		}
	}
	.tab-content {
		height: 0;
		overflow: hidden;
		&.js-active {
			height: auto;
			overflow: visible;
		}
	}
}