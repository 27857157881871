.quad-container {
	padding: 3rem 1.8rem;
    @include breakpoint(xlarge) {
        padding: 4rem;
    }
	.quad-grid {
		.cell {
			margin-bottom: 2rem;
			p {
				margin: 2rem 0;
			}
		}
	}
}