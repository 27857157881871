body {
  background-color: color('lightest-gray'); 
}

#content {
  position: relative;
  outline: 0;
  padding-top: $header-height;
  
  @include breakpoint(large down) {
    padding-top: 0;
  }

    ul{
      // Not Accordion or Slick Slider
      &:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs){
        // Remove UL dots
        list-style: none;
        margin-top: 0.5rem;
      
        // Accent dots on LI (only CSS way to change the bullet colour)
        li{
          &:not(.tabs-title){
            &::before{
              content: "\2022";
              color: #00D1AF;
              font-weight: bold;
              display: inline-block;
              width: 0.8rem;
              margin-left: -0.5rem;
              vertical-align: 7%;
            }
          }
        }
      }
    }

    .accent-background{
      ul{
        
        &:not(.accordion){
          li{
            &::before{
              color: #414042!important;
            }
          }
        }
      }
    }
  }
