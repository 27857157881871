[data-module="lazyLoad"] {

  [data-module-role="loadMore"] {
    margin: -1rem auto 1rem;
    order: 2;
  }
  
  .js-hidden {
    display: none; 
  }
}