@mixin hamburger($color: 'white', $open-color: $color, $width: 30px, $height: 25px, $line-thickness: 5px) {
  $transition-duration: 145ms;
  $transition: #{$transition-duration} ease;
  
  $width: rem-calc($width);
  $height: rem-calc($height);
  $line-thickness: rem-calc($line-thickness);
  $border-width: ($height - $line-thickness) / 2;
  $bun-width: $width * 1.35;
  
  position: relative;
  display: inline-block;
  width: $width;
  height: $line-thickness;
  padding: 0 !important;
  border-left: ($bun-width - $width) solid transparent;
  border-top: $border-width solid transparent;
  border-bottom: $border-width solid transparent;
  outline: 0;
  background-clip: content-box;
  box-sizing: content-box;
  text-indent: -99999px;
  background-color: color($color);
  transition: background-color 150ms ease;
  
  &:before, &:after {
    position: absolute;
    width: $bun-width;
    height: $line-thickness;
    top: 0;
    right: 0;
    display: block;
    background-color: color($color);
    content: "";
    transition: margin-top $transition $transition-duration, transform $transition, background-color $transition $transition-duration;
  }
  
  &:before {
    margin-top: -$border-width;
  }
  
  &:after {
    margin-top: $border-width;
  }
  
  &.js-open, .js-navigation-open & {
    background-color: transparent;
    
    &:before, &:after {
      margin-top: 0;
      background-color: color($open-color);
      transition: margin-top $transition, transform $transition $transition-duration, background-color $transition;
    }
    
    &:before {
      transform: rotate(-45deg);
    }
    
    &:after {
      transform: rotate(45deg);
    }
  }
}

.hamburger {
  @include hamburger;
}

.js-navigation-open {
  @include breakpoint(medium down) {
    overflow: hidden;
  }
}
