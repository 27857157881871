.accordion {
	margin: 0;
	background: transparent;
	&.white-background {
		background: $white;
	}
	.accordion-item {
		width: 100%;
		padding: 0;
		margin: 0.5rem auto;
		&.is-active {
			.accordion-title {
				background-color: inherit;
				background-image: linear-gradient(0deg, rgba(0,0,0,.1), rgba(0,0,0,.1));
			}
		}
		.accordion-title {
			border: none;
			font-size: inherit;
			color: inherit;
			text-decoration: none;
			padding: 1.25rem 2.25rem 1.25rem 1rem;
			@include breakpoint(medium) {
				padding: 1.25rem 2rem;
			}
			&:hover, &:focus {
				background-color: inherit;
				background-image: linear-gradient(0deg, rgba(0,0,0,.1), rgba(0,0,0,.1));
			}
			&.white-background {
				&:hover, &:focus, &:visited, &:active {
					background: $white;
				}
			}
			&:before {
				margin-top: -1rem;
				font-size: 2rem;
				line-height: 0.75;
				color: #00D1AF;
				right: 1rem;
				@include breakpoint(xlarge) {
					right: 2rem;
				}
			}
		}
		.accordion-content {
			border: none;
			background-color: transparent;
			color: inherit;
			padding: 1rem;
			@include breakpoint(medium) {
				padding: 1.8rem;
			}
			h5 {
				margin-bottom: 2rem;
			}
			p {
				margin-bottom: 1.5rem;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		&.ellipsis {
			background: transparent;
			&.is-active {
				.accordion-title {
					background: transparent;
					text-decoration: underline;
				}
			}
			.accordion-title {
				padding: 1.25rem 0 1.25rem 1.8rem;
				display: flex;
				color: color('teal');
				&:hover, &:focus {
					background: transparent;
				}
				&:hover {
					text-decoration: underline;
				}
				&:before {
					right: auto;
					left: 0;
				}
				.dot {
					border-bottom: 1px dashed color('dark-gray');
					content: '';
					flex: 1;
					height: 0.6em;
					margin-left: 1rem;
				}
			}
		}
	}

	&.rate-accordion{
		.accordion-item{
			.accordion-title{
				padding-right: 3rem;
				padding-left: 0.5rem;
				font-size: 1.5rem;
				font-weight: bold;

				@include breakpoint(medium){
					font-size: 2rem;
					padding-left: 0.75rem;
				}

				&::before{
					font-size: 3rem;
					margin-top: -1.5rem;
					transition: all 0.2s ease;
				}
			}

			.accordion-content{
				margin-top: 1rem;
			}

			&.is-active{
				.accordion-title{
					background-image: none;

					&::before{
						content: '+';
						transform: rotate(45deg);
						right: 0.75rem;
						top: 53.3%;
					}

					&:hover{
						background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
					}
				}
			}
		}
	}
}