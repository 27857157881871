.article-social-container {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + #{$header-height});
    padding-top: $header-height;
    margin-top: -$header-height;
    
    @include breakpoint(small down) {
        position: relative;
        width: 100%;
        padding-top: 0;
        margin-top: 0;
    }
}

.article-social-box {
    position: absolute;
    width: rem-calc(70);
    top: $header-height;
    padding: 4rem 0 1rem;
    
    &.js-stuck {
        position: fixed;
    }
    
    &.js-bottom {
        top: auto;
        bottom: 0;
    }
	
	@include breakpoint(large) {
        margin-left: 2rem;
    }
	
    @include breakpoint(medium down) {
        margin-left: 1.75rem;
    }
    
    @include breakpoint(small down) {
        position: relative;
        width: 100%;
        top: 0;
        margin: 0;
    }
    
    .cell {
        margin-bottom: 0.5rem;
        max-height: 40px;
        @include breakpoint(medium) {
            margin-left: 0;
            margin-right: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
        
    }
    a{
        svg{
            max-width: 40px;
            max-height: 40px;
        }
        &:hover{
            svg{
                fill: color('dark-teal');
            }
        }
        &.heart{
            &:hover{
                svg{
                    fill: #ec4343;
                }
            }
            &.active{
                svg{
                    fill: #ec4343;
                }
            }
        }
    }
}
