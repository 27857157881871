// Icon
.motus-tooltip{
  margin-left: 0.25rem;
  margin-top: -3px;
  font-size: 0.85rem;
  line-height: 1;
  border-radius: 1rem;
  padding: 0 0.2rem;
  text-decoration: none;
  margin-bottom: 0;
}

// Pre-Launch Modal
.prelaunch-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 6px solid;
  color: #414042;
  text-align: center;
  
  &.reveal{
    overflow: unset;
  }

  h1 {
    font-size: rem-calc(50);
    margin-bottom: .5rem;
  }

  p {
    margin: 0;
  }

  &.errorModal {
    border: 6px solid #C71F25;
    background-color: white;

    .modal-button {
      background-color: #414042;
      color: white;
    }
  }

  .modal-button {
    margin-top: 1rem;
    padding: .75rem 4.5rem;
    background-color: white;
  }

  .close {
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: -0.6rem;
    right: -0.6rem;
    display: block;
    border: 3px solid color('white');
    border-radius: 50%;
    text-indent: -999999px;
    color: color('white');
    transform-origin: center center;
    transform: scale(.9);
    transition: transform 175ms ease-out;
    background-color: color('dark-gray');

    @include breakpoint(medium) {
      top: -1rem;
    right: -1rem;
    }
    
    &:hover, &:focus {
      transform: scale(1);
    }
    
    &::before, &::after {
      position: absolute;
      width: 60%;
      height: 3px;
      top: 50%;
      left: 50%;
      margin-top: -2px;
      border-radius: 5px;
      display: block;
      content: "";
      transform-origin: center center;
      background-color: color('white');
    }
    
    &::before {
      transform: translateX(-50%) rotate(45deg); 
    }
    
    &::after {
      transform: translateX(-50%) rotate(-45deg); 
    }
  }
}