input[type="text"], select {
	padding: 0;
	box-shadow: none;
	border-left: none;
	border-right: none;
	border-top: none;
	border-bottom-width: 2px;
	background-color: transparent;
	margin: 0;
	max-height: 32px;
}
label {
	font-size: 1rem;
}
select {
	color: color('medium-gray');
	padding-right: 2rem;
	background-image: none;
	option {
		color: color('carbon');
		padding-right: 2rem;
		overflow: hidden;
		&:first-child {
			color: color('medium-gray');
		}
	}
	&:focus {
		color: color('carbon');
	}
	/* IE11 hide native button */
	&::-ms-expand {
		display: none;
	}
}
.select {
		position: relative;
		@include breakpoint(medium down) {
			margin: 2rem auto 0;
		}
		&:after {
			content: '>';
			font: 24px "Consolas", monospace;
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			transform: rotate(90deg);
			right: 10px;
			bottom: 20px;
			position: absolute;
			pointer-events: none;
			color: color('teal');
		}
}
fieldset {
	.check-container{
		display: block;
		@include breakpoint(medium) {
			display: flex;
		}
	}
	.check-field {
		margin: 1rem 0;
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		@include breakpoint(xlarge) {
			margin: 0.8rem 0;
		}
		&:last-child {
			&:not(:nth-child(1)){
				margin: 0;
				@include breakpoint(large) {
					margin: 0.8rem 0;
				}
			}
		}
		/* Hide the browser's default checkbox */
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			margin: 0;
			width: 20px;
			height: 20px;
			z-index: 999;
			/* When the checkbox is checked, add a mint background */
			&:checked {
				&~ .checkmark, &~ .checkmark-radio {
					background-color: #00D1AF;
					/* Show the checkmark when checked */
					&:after {
						display: block;
					}
				}
			}
		}
		/* Create a custom checkbox */
		.checkmark {
			height: 20px;
			width: 20px;
			background-color: color('dark-gray');
			position: relative;
			/* Create the checkmark/indicator (hidden when not checked) */
			&:after {
				content: "";
				position: absolute;
				display: none;
				left: 7px;
				top: 3px;
				width: 6px;
				height: 11px;
				border: solid white;
				border-width: 0 3px 3px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
		.checkmark-radio {
			height: 20px;
			width: 20px;
			background-color: #eee;
			border-radius: 50%;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				display: none;
				top: 5px;
				left: 5px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: color('white');
			}
		}
		/* On mouse-over, add a grey background color */
		&:hover {
			input {
				&~ .checkmark, &~ .checkmark-radio {
					background-color: color('medium-gray');
				}
			}
		}
		label {
			cursor: pointer;
			padding-left: 15px;
			padding-right: 30px;
		}
	}
}