.abm-finder {
    padding: 3rem 1.8rem 0;
    @include breakpoint(xlarge) {
        padding: 4rem 4rem 0;
    }
    h3 {
        margin-bottom: 2rem;
    }
	h6 {
		margin-bottom: 0;
	}
	label {
		font-size: rem-calc(12);
	}
    .location-search {
        margin: 0 auto 3rem;
		position: relative;
		padding: 0 0 2rem;
		@include breakpoint(medium) {
			padding: 2rem 0 3rem;
        }
		&:after {
			content: '';
			border-bottom: 1px dashed color('dark-gray');
			width: 100%;
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
		}
        &> div {
            display: flex;
            align-items: center;
        }
        .button {
            margin: 1rem 0;
            padding: .75em;
            @include breakpoint(medium) {
                margin: 0;
            }
            @include breakpoint(xlarge) {
                padding: .75em 1.5em;
            }
        }
    }
    .language-selection {
        position: relative;
        @include breakpoint(large down) {
            margin: 2rem auto 0;
        }
        &:after {
            content: '>';
            font: 24px "Consolas", monospace;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            right: 10px;
            top: 0;
            position: absolute;
            pointer-events: none;
        }
    }
    #mapDiv {
        width: 100%;
        height: 400px;
        margin: 3rem auto;
        position: relative;
        -webkit-transition: visibility 1s, opacity 1s ease-in-out;
        -moz-transition: visibility 1s, opacity 1s ease-in-out;
        -ms-transition: visibility 1s, opacity 1s ease-in-out;
        -o-transition: visibility 1s, opacity 1s ease-in-out;
        opacity: 1;
        overflow: hidden;
        visibility: visible;
    }
	.features {
		position: relative;
		padding-bottom: 2rem;
		margin-bottom: 3rem;
		&:after {
			content: '';
			border-bottom: 1px dashed color('dark-gray');
			width: 100%;
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		.feature-sample {
			display: flex;
			align-items: center;
			margin: 0.8rem 0;
			@include breakpoint(xlarge) {
				margin: 1.5rem 0;
			}
			svg {
				max-width: 22px;
				max-height: 22px;
			}
			p {
				margin: 0 0 0 0.5rem;
				font-size: rem-calc(12);
			}
		}
	}
	.locations {
		.location {
			margin-bottom: 4rem;
			.location-type {
				display: flex;
				align-items: center;
				margin-bottom: 1rem;
				svg {
					max-width: 22px;
					max-height: 32px;
				}
				h6 {
					margin: 0 0 0 0.5rem;
				}
			}
			.address {
				margin-bottom: 2rem;
				max-width: 250px;
			}
			.location-features {
                .feature-icon {
                    display: inline-flex;
                    max-width: 30px;
				    svg {
					    max-width: 22px;
					    max-height: 22px;
					    margin: 1.5rem 0.25rem 0;
					    &:first-child {
						    margin-left: 0;
					    }
				    }
                }
			}
		}
	}
}
