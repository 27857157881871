$chevron-transition: 100ms ease;

@mixin chevron($color: 'gray') {
  margin: 0 0 0 .35em;
  display: inline-block;
  
  &::before {
    width: .6em;
    height: .6em;
    display: inline-block;
    border-style: solid;
    border-width: .15em .15em 0 0;
    color: color($color);
    transform: rotate(45deg);
    transition: border-color $chevron-transition;
    content: "";
  }
  
  @include chevron-color($color);
}

@mixin chevron-color($color: 'gray') {
  &::before {
    border-color: color($color);
  }
}

.chevron {
  @include chevron;
  
  @each $name, $color in $all-colors {
    &.#{$name} {
      @include chevron-color($name);
    }
  }
}