.content-combo {
    align-items: center;
    p, a {
        margin-bottom: 2rem;
    }
    p {
        @include breakpoint(small only) {
            margin-bottom: 1rem;
        }
    }
}