$header-mobile-transition-duration: 145ms;
$header-mobile-transition-ease: cubic-bezier(.55, .43, .41, .85);
$header-mobile-transition: $header-mobile-transition-duration $header-mobile-transition-ease;
$header-nav-transition-duration: 85ms;
$header-nav-transition-ease: cubic-bezier(.55, .43, .41, .85);
$header-nav-transition: $header-nav-transition-duration $header-nav-transition-ease;
$medium-gutter-width: rem-calc(map-get($grid-column-gutter, medium)) / 2;
$small-gutter-width: rem-calc(map-get($grid-column-gutter, small)) / 2;

.full-bleed-container {
	padding: 0;
	background-color: color('white');
	overflow: hidden;
	
	.bg-image { 
		position: absolute;
		left: 50%;
		margin-left: -50%;
		overflow: hidden;
		img { 
			height: 100%;
		}
	}

	&.header-correct {
		margin-top: 0;
		overflow: inherit;
		box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.2);
	}
	// box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.2);
}
.bleed-image-container {
	position: relative;
	>img {
		position: absolute;
		top: 50%;
		margin-top: -50%;
		left: 50%;
		margin-left: -50%;
		max-width: none;
		&.right {
			right: 0;
			left: auto;
			margin-left: 0;
		}
		&.bottom {
			margin-top:0;
			top: auto;
		}
		&.left {
			left: 0;
			margin-left: 0;
			right: auto;
		}
		&.top {
			top: -4rem;
			margin-top:0;
			bottom: auto;
		}

	}
}


.navigation {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: rem-calc(14);
	@include breakpoint(medium down) {
		font-size: rem-calc(22);
	}
	ul {
		list-style-type: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 0;
		width: 100%;
		@include breakpoint(medium down) {
			padding-bottom: 1rem;
		}
	}
	a {
		padding: 1em 0;
		// text-transform: uppercase;
		font-size: inherit;
		display: inline-block;
		text-decoration: none;
		width: 100%;
		line-height: 1.3;
		@include breakpoint(large down) {
			padding-left: .75em;
			padding-right: .75em;
		}
		@include breakpoint(medium down) {
			padding: 0;
			text-transform: none;
			font-size: 1rem;
		}
	}
}

.secondary.navigation {
	color: color('dark-teal');
	margin: 2rem auto;
	@include breakpoint(small only) {
		display: none;	
	}
	a {
		position: relative;
		transition: background-color $header-nav-transition, color $header-nav-transition;
	}
	.active-state {
		position: absolute;
		width: calc(100% - 0.25em);
		height: rem-calc(4);
		top: 50%;
		left: 0;
		margin-top: 1em;
		display: block;
		transform-origin: left center;
		transform: scaleX(1);
		transition: transform $header-mobile-transition;
		@include breakpoint(small only) {
			margin-top: 1.5em;
		}
	}
	.active.category .active-state,
	a:hover .active-state {
		transform: scaleX(0.25);
	}
	.category {
		position: relative;
		width: 20%;
		padding: 0;
	}

	ul{
		li{
			&::before{
				content: ""!important;
			}
		}
	}
}

.topics {
	margin-bottom: 1.5rem;
	ul {
		padding: 0;
		list-style: none;
		margin: 0;
		&.submenu {
			width: 100%;
			border: none;
			padding: 1rem 0;
			position: relative;
			top: 0 !important;
			box-shadow: 0 0 rem-calc(10) rgba(color('black'), .15);
		}
	}
	li {
		width: 100%;
		margin-bottom: 0.5rem;
		padding: 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
}