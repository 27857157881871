.rates-table {
    padding: 3rem 1.8rem;
    @include breakpoint(xlarge) {
        padding: 4rem;
    }
    h2 {
        margin-bottom: 2rem;
    }
    p {
        margin: 0;
        padding: 1rem 0;
        &.legal {
            font-size: rem-calc(12px);
            font-weight: normal;
        }
	}
	
	.legal-text{
		margin-top: 1.5rem;
		p{
			font-size: 0.75rem;
			padding-bottom: 0;
		}
	}

    .service {
        margin-bottom: 2rem;
        .service-title {
            margin-bottom: 1.5rem;
        }
        .service-item {
			padding: 0;
			margin: 0;
			.accordion-item {
				margin: 0;
			}
			.accordion-title {
				padding: 1rem;
				line-height: 1.6;
			}
			.accordion-content {
				padding:1rem;
				background-color: inherit;
				p {
					padding: 0.5rem 0;
					font-size: rem-calc(14px);
				}
			}
            &.multi {
                p {
                    font-size: rem-calc(14px);
                    @include breakpoint(medium) {
                        font-size: rem-calc(12px);
                    }
                    @include breakpoint(xlarge) {
                        font-size: rem-calc(14px);
                    }
                }
                &> .cell {
                    padding: 0;
                    &:first-child {
                        padding-left: 0.625rem;
                        @include breakpoint(xlarge) {
                            padding-left: 0.9375rem;
                        }     
                    }
                }
            }
        }
	}
	
	.rate-group{
		@include breakpoint(xlarge) {
			margin-top: 1rem;
		}

		.rate-column{
			margin-bottom: 1rem;

			&:last-child{
				margin-bottom: 0;
			}

			.rate-yearly-title{
				font-size: 1.33rem;
				margin-left: -0.8rem;

				@include breakpoint(xlarge) {
					margin-left: 0;
				}
			}
		}
		.rate-item{			
			.rate-item-info{
				@include breakpoint(xlarge) {
					background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168,168,168,0) 0%);
					background-position: bottom;
					background-size: 6px 2px;
					background-repeat: repeat-x;
					padding-bottom: 1rem;
					margin-bottom: 1rem;
				}

				&:not(&.rate-yearly-container){
					padding-left: 0;
					padding-right: 0;
				}
				
				h5{
					display: inline-block;
					float: left;
					margin-bottom: 0;
				}

				.rate-title{
					width: 100%;
					margin-right: 0;
					margin-bottom: 0;
					font-size: 1.33rem;

					@include breakpoint(xlarge) {
						width: 75%;
						margin-right: 5%;
					}
				}

				.rate-value{
					width: 20%;
    				text-align: right;
					margin-bottom: 1.5rem;
					margin-bottom: 0;

					@include breakpoint(small) {
						margin-bottom: 0;
						
					}
				}
			}

			.rate-item-cta{
				background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168,168,168,0) 0%);
				background-position: bottom;
				background-size: 6px 2px;
				background-repeat: repeat-x;
				padding-bottom: 1.5rem;
				margin-bottom: 1.5rem;

				@include breakpoint(xlarge) {
					padding: 0 2rem;
					background: unset;
				}

				.button{
					float: left;
					margin-bottom: 0;
					margin-top: 1.5rem;

					@include breakpoint(xlarge) {
						margin-top: 0;
					}
				}

				h5{
					display: inline-block;
					float: left;
					margin-bottom: 0;
					margin-top: 1.5rem;
					margin-right: 2rem;
					font-size: 2rem;
					line-height: 1;
				}
			}

			// Yearly
			&.rate-yearly-container{

				.rate-item-info{
					@include breakpoint(xlarge) {
						background: none;
					}

					.rate-title{
						width: 100%;
						margin-bottom: 2rem;
						font-size: 0.95rem;

						@include breakpoint(xlarge) {
							background: none;
							font-size: 1.33rem;
						}
					}

					.rate-yearly-years .rate-yearly{
						background-image: linear-gradient(to right, #A8A8A8 33%, rgba(168,168,168,0) 0%);
						background-position: top;
						background-size: 6px 2px;
						background-repeat: repeat-x;
						padding-top: 1rem;
						margin-top: 1rem;
						margin-right: 0;
						margin-bottom: 1rem;
						clear: both;

						@include breakpoint(xlarge) {
							margin-left: 6rem;
						}
	
						&:first-child{
							background: none;
							margin-top: 0;
							padding-top: 0;
						}

						.cell{
							&:first-child{
								padding-left: 0;
							}

							&:last-child{
								padding-right: 0;
							}
						}
		
						.rate-title{
							width: 100%;
							display: block;
							margin-bottom: 0;
						}
					}
	
					.rate-yield{
						background:none;
						padding-bottom: 0;
						padding-top: 0;
						margin-top: 0;
						margin-right: 0;
						margin-bottom: 0;
						clear: both;
						border-top: 5px solid;

						@include breakpoint(xlarge) {
							margin-left: 6rem;
							margin-top: 1.5rem;
						}
	
						.rate-item-info{
							padding-bottom: 0;
							margin-bottom: 0;
							background-image: none;
		
							.rate-title{
								width: 100%;
								display: block;
								margin-bottom: 0;
							}
						}
					}
				}

				
				.rate-item-cta{
					background: none;
					
					.button{
						float: left;
						margin-top: 0;
						margin-left: 1.5rem;

						@include breakpoint(medium) {
							margin-left: 2rem;
						}

						@include breakpoint(xlarge) {
							margin-left: 0;
						}
					}
				}
			}

			&.rate-item-full-width{
				.rate-value{
					padding-top: 0;
					padding-bottom: 0;
					line-height: 1.9;
					font-size: 0.75rem;
					display: inline-block;
					float: left;
					margin-right: 2rem;

					strong{
						font-size: 1.25rem;
					}
				}
			}
		}
	}
}


.rate-carousel {
    overflow: visible;
	margin-left: 0;
	margin-right: 0;
	@include breakpoint(large) {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}  
    &.mint-arrow {
        .slick-next, .slick-prev {
            &::before {
                border-color: $primary-color;
            }
        }
    }
	&.no-carousel {
		.rate {
			/*&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}*/
			/*.cell {
				a {
					&.button {
						width: auto;
						margin: 0.5rem 0;
						
						@include breakpoint(small only) {
							width: 100%;
						}
					}
				}
			}*/
		}
	}
	&.slick-slider {
		.rate {
			margin: 0;
		}
	}
	.rate {
		padding: 0;
		margin: 0 0 2rem;
		@include breakpoint(large) {
            padding: 0 1.5rem;
			margin: 0;
        }
		/*&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}*/
		&.no-intro {
			.cell {
				&:first-child {
					padding-bottom: 0;
					&:after {
						display: none;
					}
				}
				.heading {
					margin: 0;
				}
			}
		}
		.cell {
			position: relative;
			padding: 1rem 0 1.25rem;
			@include breakpoint(large) {
				padding: 1.5rem 0 1.75rem;
			}
			&.no-border {
				padding: 1rem 0 0;
				@include breakpoint(large) {
					padding: 1.5rem 0 0.5rem;
				}
				&:after {
					display: none;
				}
			}
			&:after {
				content: '';
				border-bottom: 1px dashed color('dark-gray');
				width: 100%;
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
			}
			&:last-child {
				padding-bottom: 0;
				&:after {
					border: none;
				}
			}
			p, h2, h3, span {
				margin: 0;
				padding: 0;
			}
			h2 {
				font-size: rem-calc(65);
				line-height: 1;
				@include breakpoint(medium) {
					font-size: rem-calc(40);
				}
				@include breakpoint(xlarge) {
					font-size: rem-calc(55);
				}
				@include breakpoint(xxlarge) {
					font-size: rem-calc(65);
				}
			}
			.heading {
				border-bottom: 6px solid #00D1AF;
				margin-bottom: 1rem;
				@include breakpoint(large) {
					margin-bottom: 0;
				}
			}
			a {
				&.button {
					width: 100%;
					margin: 0.5rem 0;
				}
			}
		}
	}
    .slick-slide {
        outline: 0;
    }
    .slick-next, .slick-prev {
        position: absolute;
        width: 2rem;
        height: 2rem;
        top: 15%;
        cursor: pointer;
        text-indent: -99999999px;
        color: transparent;
        outline: 0;
        &::before {
            position: absolute;
            width: 65%;
            height: 65%;
            top: 50%;
            left: 50%;
            border-width: 0;
            border-style: solid;
            border-color: color('white');
            content: "";
            transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(.8);
            transition: transform $testimonials-transition;
        }
        &:hover::before {
            transform: translateX(-50%) translateY(-50%) rotate(45deg) scale(1);
        }
    }
    .slick-next {
        left: 100%;
        &::before {
            border-right-width: 3px;
            border-top-width: 3px;
        }
    }
    .slick-prev {
        right: 100%;
        &::before {
            border-left-width: 3px;
            border-bottom-width: 3px;
        }
    }
    .slick-dots {
        width: 100%;
        margin: 2rem 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
		text-align: center;
		li {
			padding: 0.33rem;
		}
        a, button {
            position: relative;
            width: 2rem;
            height: rem-calc(3);
            margin: 0 .25rem;
            text-indent: -99999999px;
            cursor: pointer;
            outline: 0;
            transition: background-color $testimonials-transition;
            &:not(.accent-background) {
                background-color: color('light-gray');
            }
            &::after {
                position: absolute;
                width: 100%;
                height: 500%;
                top: -200%;
                display: block;
                content: "";
            }
        }
    }
}
.all-rates {
	margin-top: 2rem;
	padding-top: 2rem;
	border-top: 1px dashed color('dark-gray');
	display: flex;
	align-items: center;
	a {
		&.button {
			margin-bottom: 0;
			margin-left: 1rem;
		}
	}
}
