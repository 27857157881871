.panel {
  position: relative;
  padding: 2rem 4rem 4rem;
  
  > * {
    position: relative; 
  }
  
  @include breakpoint(medium down) {
    padding: 2rem; 
  }
  
  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;

    &.background-pos-left{
      background-position: left top;
    }

    &.background-pos-right{
      background-position: right top;
    }
  }
}