.board-of-directors {
	padding: 0;
    h3{
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 2rem;
        @include breakpoint(medium) {
            font-size: 2.5rem;
        }
    }
	.member {
		margin-top: 2rem;
		margin-bottom: 3rem;
		&:last-child {
			margin-bottom: 0;
		}
		h5 {
			margin-bottom: 0.5rem;
		}
		img {
			margin-bottom: 1rem;
		}
		p {
			margin: 0;
		}
	}

}