.promo-content.accessibility ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title)::before,
.promo-content.wtb_list ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title)::before{
    color:#545558 !important;
}
.promo-content.accessibility ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title),
.promo-content.wtb_list ul:not(.accordion):not(.slick-dots):not(.categories):not(.post-tabs) li:not(.tabs-title) {
    font-family: 'Segoe UI' !important;
}
.side-by-side {
    margin: 0;
	padding: 0;
	align-items: center;
    &> div {
        position: relative;
    }
    .promo {
        margin: 0;
    }
    img {
        width: 100%;
    }
    .background-image {
        position: relative;
        width: 100%;        
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        height: 100%;
        min-height: 250px;
        z-index: 0;
        @include breakpoint(large) {
            //position: absolute;
			min-height: 450px;
        }
    }
	.promo-image {
		align-self: stretch;
	}
}
#online-banking{
    left:-5rem;
}

.promo{
    padding: 3rem 1.8rem 240px;
    margin: 0;
    background-repeat: no-repeat;
    position: relative;
    @include breakpoint(medium) {
        padding: 3rem 1.8rem 290px;
    }
    @include breakpoint(large) {
        padding: 3rem 1.8rem 340px;
    }
    @include breakpoint(xlarge) {
        padding: 4rem;
    }
    h3{
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 2rem;
        @include breakpoint(medium) {
            font-size: 2.5rem;
        }
    }
	h5 {
		margin-bottom: 0.5rem;
	}
    p{
        line-height: 1.8;
        margin-bottom: 2rem;
    }
    &.no-bg {
        background-color: transparent;
        @include breakpoint(large down) {
            padding: 3rem 1.8rem;
        }
    }
    &.no-bg-image {
        background-image: none;
        @include breakpoint(large down) {
            padding: 3rem 1.8rem;
        }
    }
    .background-image {
        position: absolute;
        width: 100%;        
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        height: 220px;
        z-index: 0;
        @include breakpoint(medium) {
            height: 270px;
        }
        @include breakpoint(large) {
            height: 320px;
        }
        @include breakpoint(xlarge) {
            height: 100%;
        }
    }
    .promo-content {
        z-index:1;
        position: relative;
        &> :last-child {
            margin-bottom: 1rem;
        }
        &~ .promo-content {
            margin-top: 2rem;
        }
        &.accessibility{
            h2{
                font-size: 2.21rem;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                font-weight: bold;
                padding-top: 1rem;
            }
            h4{
                font-size: 1.5rem;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                font-weight: bold;
                margin-top: 2rem;
            }
            p{
                font-size: 0.96rem;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                margin-top: -2rem;
                margin-bottom: 2rem;
            }
            &.li{
                color: #414042 !important;
                font-size: 0.96rem;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

            }
            h6{
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            }
            p.complaint-step2 {
                width: 20rem;
            }
        }
    }
    &.blog{
        h4{
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 2rem;
            @include breakpoint(medium) {
                font-size: 2rem;
            }
        }
    }
    &.comparison {
        li {
            padding: 0;
        }
        h3 {
            font-size: 2.25rem;
            @include breakpoint(large down) {
                font-size: 1.5rem;
            }
        }
        h5 {
            margin-bottom: 1rem;
        }
        h6 {
            margin-bottom: 1rem;
        }
    }
    &.mobile-cover {
        padding: 3rem 1.8rem;
		@include breakpoint(xlarge) {
			padding: 4rem;
		}
        .background-image {
            height: 100%;    
        }
    }
}
.hide-for-small-only.medium-1.reach_us{
    border-left: 1px dashed white;
    height: 10rem;
    margin: 0 auto;
    width: 1px;
}
h3.wtb_title{
    font-size: 2.25rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
}
p.wtb_description{
    font-size: 0.96rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
span.white-text.wtb_title_section,
span.carbon-text.wtb_title_section{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold !important;
}
a#p_lt_ctl03_pageplaceholder_p_lt_ctl00_Container3_Container3_Layout1_Layout1_Left_Button3_link{
    background-color: aliceblue !important;
    color: black !important;
}
a#Code-of-ethics{
    background-color: #414042 !important;
    color: floralwhite !important;
}
h4.carbon-text.wtb_h4{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media screen and (max-width: 39.9375em) {
    .promo.mobile-cover.wtb_bottom {
        padding: 4rem 1.8rem 18rem;
    }
    .hero-banner h1.hero-wtb-title{
        font-size: 3rem !important;
    }
    #Ways-to-bank.panel{
        padding-top: 115vw;
    }
}

@media screen and (max-width: 39.9375em) {
    .promo-content.espace-txt{
        left: 0rem;
    }
}
@media screen and (min-width: 40em) {
    
    .promo-content.espace-txt{
        left: 11rem;
    }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

    .promo-content.espace-txt{
        left: 0rem;
    }
}
@media screen and (min-width: 64em) {

	.advice-desk {
    margin-left: 25rem;
		}
}
@media screen and (min-width: 64em) {

	.Advice-desk-2 {
    margin-left: -25rem;
        }
}
@media screen and (max-width: 39.9375em) {

    div.advice-mobile {
        height: 370px !important;
            }
}

@media screen and (max-width: 39.9375em){
    .grid-x.panel.fa-an-fa {
      margin-bottom: 31rem;
      margin-top: -10rem;
  }
  
    .hide-for-xlarge.background-image.background-pos-right.fa-an-fam {
      margin-top: 24rem;
      padding-top: 29rem;
    }
  }
  