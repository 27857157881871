.fifty-grid {
	&.with-borders {
		padding-top: 2rem;
		.separator {
			padding: 0 1.8rem;
			@include breakpoint(xlarge) {
				padding: 0 4rem;
			}
			
			hr {
				margin: 0;
			}
		}
		.fifty-grid-item {
			margin-bottom: 0;
			position: relative;
			&:nth-child(3n - 1) {
				&:before {
					content: '';
					border-bottom: 2px dashed;
					border-color: #A8A8A8;
					width: auto;
					height: 2px;
					display: block;
					margin: -3rem auto 3rem;

					@include breakpoint(large) {
						border-bottom: none;
						border-left: 2px dashed;
						border-color: color('dark-gray');
						height: 70%;
						width: 2px;
						top: 50%;
						transform:translateY(-50%);
						bottom: auto;
						left: -1.05rem;
						margin: 0;
						position: absolute;
					}
				}
			}
		}
	}
    .fifty-grid-item {
        padding: 3rem 1.8rem;
        margin-bottom: rem-calc(30);
		position: relative;
        @include breakpoint(xlarge) {
            padding: 4rem;
        }
		&.with-title {
			padding: 0;
			.with-label {
				padding: 5rem 1.8rem 3rem;
				@include breakpoint(xlarge) {
					padding: 5rem 4rem 4rem;
				}
				.section-label {
					left: 1.8rem;
					@include breakpoint(xlarge) {
						left: 4rem;
					}
				}
			}	
		}
        *{
            &:last-child {
                margin-bottom: 0;
            }
        }
        h4, p {
            margin-bottom: 2rem;
        }
    }
}
