 /*Checkboxsss*/
 .checkbox.inline:last-child,
 .radio.inline:last-child {
   margin-right: 0; }
 .checkbox label,
 .radio label {
   display: block;
   position: relative;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   cursor: pointer;
   font-size: 0.875rem;
   line-height: 1;}
 .checkbox input[type="checkbox"],
 .radio input[type="radio"] {
   /*position: absolute;*/
   top: 0;
   left: 0;
   visibility: hidden;}
 .checkbox .custom-checkbox,
 .radio .custom-radio {
   display: inline-block;
   vertical-align: top;
   margin: 0 6px 0 0;
   width: 18px;
   height: 18px;
   border: 1px solid #1583cc;
   position: relative;}
 .checkbox .custom-checkbox {
   background: #1583cc;
   box-shadow: inset 0 0 0 12px #fff;
   top: -2px; }
 
 .checkbox .icon-check {
   width: 20px;
   height: 20px;
   background-size: cover;
   position: absolute;
   top: 0px;
   left: 0px;
   transform: scale(0);
   transition: all 180ms ease; }
 
 .icon-check:before {
   position: absolute;
   content: '\2713';
   width: 12px;
   font-size: 12.66667px;
   text-align: center;
   line-height: 16px;
   color: #fff;
   font-weight: bold; }
 .checkbox input[type="checkbox"]:checked + .custom-checkbox {
   border-color: #2095F0;
   box-shadow: none; }
 .checkbox input[type="checkbox"]:checked + .custom-checkbox .icon-check {
   transform: scale(1); }
 
 
 .checkbox .success,
 .radio .success{
   border: 3px solid #00D1AF; 
   margin-top: 0.5rem;
}
 
 .checkbox input[type="checkbox"]:checked + .success,
 .radio input[type="radio"]:checked + .success {
   background: #00D1AF;
    border:2px solid #00D1AF; }
    
    input#checkbox3 {
 
       margin-right: -0.9rem;
       margin-bottom: 2rem;
       margin-top: 0.5rem;
   }
   .checkbox.bg-check {
       height: 35px;
   }

   .small-12.medium-4.large-4.xlarge-4.cell.hero-banner.very-light-bachground.btn-not-robot {
       margin-top: -4.6rem;
       margin-left: 4.5rem;
   }
  
@media screen and (max-width: 39.9375em) {
    .promo-content.espace-txt{
        left: 0rem;
    }
}
@media screen and (min-width: 40em) {
    
    .promo-content.espace-txt{
        left: 11rem;
    }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {

    .promo-content.espace-txt{
        left: 0rem;
    }
}
@media screen and (min-width: 64em) {

	.advice-desk {
    margin-left: 25rem;
		}
}
@media screen and (min-width: 64em) {

	.Advice-desk-2 {
    margin-left: -19rem !important;
        }
}
@media screen and (max-width: 39.9375em) {

    div.advice-mobile {
        height: 370px !important;
            }
}

@media screen and (max-width: 39.9375em){
    .grid-x.panel.fa-an-fa {
      margin-bottom: 31rem;
      margin-top: -10rem;
  }
  
    .hide-for-xlarge.background-image.background-pos-right.fa-an-fam {
      margin-top: 24rem;
      padding-top: 29rem;
    }
  }
  
  .concerns.separateur
  {
      padding-top: 0rem;
      margin-top: -3rem;
      
  }
  .separateur
  {
      padding-top: 0rem;
      margin-top: -1rem;
  }
  .bg-check{
      background-color: #f3f3f4;
  }
 .btn-apply {
    margin-top: 1rem;
}