.chart-wrapper {
	padding-top: 4rem;
}
#barChart-static {
  display: flex;
  height: 12rem;
  margin-bottom: 2rem;
  justify-content: space-around;
  align-items: flex-end;
  border-bottom: 2px solid #dadada;
  @media screen and (max-width: 680px) {
    flex-direction: column;
    border-left: 2px solid #dadada;
    border-bottom: none;
    justify-content: space-evenly;
    align-items: flex-start;
    height: auto;
    margin: 0 1rem 0 20%;
  }
  @media screen and (max-width: 550px) {
    margin: 0 1rem 0 30%;
    transition: margin 2s;
  }
  .bar {
    position: relative;
    background-color: #dadada;
    text-align: center;
    margin: 0 0.5%;
    animation: barsUp 0.75s ease-in 1;
    transform-origin: center bottom;
    @media screen and (min-width: 680px) {
      width: 9% !important;
      min-height: 35px;
    }
    @media screen and (max-width: 680px) {
      height: 4rem !important;
      text-align: left;
      margin: 1rem 0;
      animation: barsRight 0.75s ease-in 1;
      transform-origin: center left;
      min-width: 43px;
    }
    &:first-child {
      background-color: #00d1af;
      margin: 0 auto;
      color: #00d1af;
      @media screen and (max-width: 680px) {
        margin: 1rem 0;
      }
    }
    &:last-child {
      margin-right: auto;
    }
    .rate-value {
      color: black;
      position: absolute;
      bottom: 0;
      margin-bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      transition: font-size 1s;
      animation: fadeIn 2s .75s forwards 1;
      opacity: 0;
      @media screen and (max-width: 720px) {
        font-size: 2vw;
      }
      @media screen and (max-width: 680px) {
        left: auto;
        margin: 0;
        transform: none;
        bottom: 30%;
        margin-left: 1vw;
        font-size: 16px;
      }
      @media screen and (max-width: 420px) {
        font-size: 13px;
      }
    }
    .rate-provider {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-top: 1rem;
      text-align: center;
      transform: translateX(-50%);
      animation: fadeIn 2s .75s forwards 1;
      opacity: 0;
      @media screen and (max-width: 840px) {
        margin-top: 1.5rem;
        font-size: 14px;
        transform: translateX(-50%) rotate(-40deg);
        line-height: 1rem;
      }
      @media screen and (max-width: 680px) {
        top: 0%;
        left: auto;
        right: 100%;
        margin-right: 1rem;
        text-align: right;
        transform: none;
        animation: fadeIn 2s 1s forwards 1;
        .break-line {
          display: block;
        }
      }
    }
  }
}
@keyframes barsUp {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes barsRight {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
