.search-result-field {
    margin-top: 40px;
}

.search-result-box {
    display: block;
    margin: 45px 0;

    h3 {
        margin-bottom: 10px;
    }

    span {
        text-transform: uppercase;
        font-size: 14px;
        display: block;
        font-weight: bold;
        margin-bottom: 10px;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

.button.search-goback {
    margin: 0 auto;
    display: block;
    max-width: 200px;
}
