.sorting {
  $radio-button-size: 1.2em;
  border-bottom: 1px solid color('dark-gray');
  margin: 0 0 2rem;
  
  .tabs-title {
    border-right: 1px solid color('dark-gray');
    
    &:last-of-type {
      border-right: 0;
    }
    
    &.is-active a::before {
      border: ($radio-button-size / 3) solid color('teal');
    }
  }
  
  a {
    position: relative;
    padding: 0 3em .5em 0;
    color: color('black');
    display: inline-block;
    outline: 0;
    
    &::before {
      position: absolute;
      top: 50%;
      width: $radio-button-size;
      height: $radio-button-size;
      right: 1em;
      margin-top: -.25em;
      display: block;
      border: ($radio-button-size / 2) solid color('light-gray');
      border-radius: 50%;
      box-shadow: 0 0 1px color('light-gray');
      transform: translateY(-50%);
      transition: border 200ms ease;
      content: '';
    }
  }
}