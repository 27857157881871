.management {
	padding: 0;
    h3{
        font-weight: bold;
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 2rem;
        @include breakpoint(medium) {
            font-size: 2.5rem;
        }
    }
	.members {
		margin-top: 2rem;
		.member {
			margin-bottom: 3rem;
			img {
				margin-bottom: 1rem;
				width: 100%;
				@include breakpoint(large) {
					max-width: 200px;
				}
			}
			p {
				margin: 0;
			}
		}
	}

}