.trainstops {
  $trainstop-transition-duration: 450ms;
  $trainstop-transition-ease: ease-out;
  $trainstop-transition: $trainstop-transition-duration $trainstop-transition-ease;
  $trainstop-y-offset: 17.5rem;
  $navigation-transition-duration: 200ms;
  $navigation-transition-ease: ease-out;
  $navigation-transition: $navigation-transition-duration $navigation-transition-ease;
  position: relative;
  overflow: hidden;
  
  .trainstop {
    opacity: 0;
    transition: opacity $trainstop-transition;
    
    .trainstop-content {
      transform-origin: left center;
      transition: transform $trainstop-transition;
    }
    
    ~ .trainstop .trainstop-content {
      margin-top: -$trainstop-y-offset;
    }
    
    &.js-active {
      opacity: 1;
    }
    
    &.js-previous .trainstop-content {
      transform: translateY(-$trainstop-y-offset) rotate(-25deg); 
    }
    
    &.js-upcoming .trainstop-content {
      transform: translateY($trainstop-y-offset) rotate(25deg);
    }
  }
  
  .trainstop-navigation {
    position: absolute;
    width: rem-calc(3);
    bottom: 100%;
    right: 50%;
    margin-right: calc(-#{$global-width / 2} + 3rem);
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    transform: translateY(100%);
    
    @include breakpoint($global-width down) {
      right: 3rem;
      margin-right: 0;
    }
    
    &.js-fixed {
      position: fixed;
    }
    
    &.js-bottom, &.js-fixed {
      bottom: 0;
      transform: translateY(0); 
    }
    
    a {
      position: relative;
      width: 100%;
      height: 2rem;
      margin: .25rem 0;
      text-indent: -99999999px;
      outline: 0;
      
      &:not(.accent-background) {
        background-color: color('gray');
      }
      
      &::after {
        position: absolute;
        width: 500%;
        height: 100%;
        left: -200%;
        content: "";
      }
    }
  }
}