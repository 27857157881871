$button-transition-duration: 125ms;
$button-transition-ease: ease-out;
$button-transition: $button-transition-duration $button-transition-ease;

.button {
  position: relative;
  padding: .75em 1.5em;
  font-size: rem-calc(12);
  font-weight: bold;
  letter-spacing: .025em;
  transition: all $button-transition;
  text-decoration: none;
  line-height: 1.4;

  @each $name, $color in $all-colors {
    &.#{$name} {
      background-color: $color;
    }
  }
    &+.button {
        margin-left: 0;
        @include breakpoint(medium) {
            margin-left: 1rem;
        }
    }

  
  &.hollow {
    border-width: rem-calc(4);
    font-weight: bold;
    
    &, &:hover, &:focus {
      background-color: transparent;
    }
    
    @each $name, $color in $all-colors {
      &.#{$name} {
        border-color: $color;
        color: $color;
      }
    }
  }
}
