@include breakpoint(small down) {
  .full-bleed-container {
    .grid-container {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.hero-banner {
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 0rem;
  overflow: hidden;
    @include breakpoint(xlarge) {
      margin-top: 1.5rem;
    }

  .panel {
    position: relative;
    padding: 4rem;
    align-items: center;

    @include breakpoint(xlarge) {
      &.short {
        min-height: 10rem;
      }
      &:not(.short) {
        min-height: 30rem;
      }
    }

    @include breakpoint(large down) {
      padding-top: 45vw;
        padding-left: 1.8rem;
        padding-right: 1.8rem;
    }

    .background-image {
      @include breakpoint(xlarge) {
        background-size: cover;
      }
    }

    .cell{
      *{
        &:first-child(){
          margin-top: 0;
        }

        &:last-child(){
          margin-bottom: 0;
        }
      }

      .button{
        margin-bottom: 1rem;
      }
    }
  }
  
  h1 {
    font-weight: 900;
    font-size: rem-calc(80);
    line-height: 1.1;
    letter-spacing: -0.02em;

    &.accessibility{
      font-size: 4.38rem;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
    }
    
    @include breakpoint(large down) {
      font-size: rem-calc(70); 
    }
    
    @include breakpoint(small down) {
      font-size: rem-calc(50);
    }
    @media screen and (max-width: 374px) {
        font-size: rem-calc(40);
    }
    &.hero-wtb-title{
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
    }
  }
  
  p {
    width: 100%;
    margin: 1.5em 0;
    font-size: rem-calc(25);
    line-height: 1.4;
    
    @include breakpoint(large down) {
      font-size: rem-calc(22);
    }
    
    @include breakpoint(small down) {
      font-size: rem-calc(20); 
    }
    &.hero-wtb-description{
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-size: 1.58rem;
    }
  }

  a.button{
      margin-right:0.5rem;
  }
}








