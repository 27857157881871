.content-switcher {
    padding: 3rem 1.8rem;
    align-items: center;

    @include breakpoint(xlarge) {
        padding: 4rem;
    }

    .switches {
        position: relative;
        margin-bottom: 4rem;

        @include breakpoint(large) {
            margin-bottom: 0;
        }
		&:after {
			content: '';
			border-bottom: 4px dashed;
			width: 100%;
			height: 4px;
			display: block;
			position: absolute;
			top: auto;
			bottom: -2rem;
			right: 0;
			border-color: inherit;

			@include breakpoint(large) {
				border-bottom: none;
				border-right: 4px dashed;
				height: 100%;
				width: 4px;
				top: 0;
				bottom: auto;
				right: 0;
				border-color: inherit;
			}
		}
    }

    ul {
        list-style: none;
        margin: 0;

        li {
            font-size: rem-calc(18);
            margin-bottom: 0.5rem;
            font-weight: bold;
            padding: 0;
			
			@include breakpoint(large) {
                margin-bottom: 1rem;
				padding: 0 1rem 0 0;
				font-size: 1.25rem;
            }
			
			@include breakpoint(xlarge) {
				font-size: 1.5rem;
            }

            &::before{
                content: ""!important;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .tab-title {
        &:focus {
            text-decoration: underline;
        }

        &.js-active {
            text-decoration: underline;

            &:hover {
                color: color('primary');
                text-decoration: underline;
            }
        }
    }

    .tab-content {
        display: none;

        &.js-active {
            display: block;
        }
    }
}
