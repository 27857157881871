.jump-link-accordion {
	padding: 3rem 1.8rem;
    @include breakpoint(xlarge) {
        padding: 4rem;
    }
	.jump-links {
		position: relative;
		padding-bottom: 2rem;
		margin-bottom: 2rem;
		@include breakpoint(medium) {
			margin-bottom: 4rem;
		}
		&:after {
			content: '';
			border-bottom: 2px dashed color('very-dark-gray');
			width: 100%;
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		h4 {
			margin-bottom: 0.5rem;
		}
		.jump-link {
			a {
				margin: 1rem 0;
				display: block;
				font-size: rem-calc(20);
			}
		}
	}
	.jumped-link-section {
		margin-bottom: 4rem;
		&:last-child {
			margin-bottom: 0;
		}
		.accordion {
			padding: 0;
			margin: 0;
		}
		h4 {
			margin-bottom: 2rem;
		}
	}
}